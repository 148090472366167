import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const SecurityConfigUsers = ({ rowData }) => {
    const columns = [
        {
            field: 'accname',
            headerName: 'Account Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'ruleId',
            headerName: 'Rule Number',
            flex: 1,
            editable: false,
            filterable: false,
        },
        {
            field: 'accnum',
            headerName: 'Account Number',
            flex: 1,
            editable: false,
        },
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rowData.map((row, index) => ({
                    id: index, // DataGrid requires a unique id field
                    ...row
                }))}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 25]}
                disableRowSelectionOnClick
                sx={{
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#a9a9a9',
                        color: 'black',
                        fontWeight: 'bold',
                    },
                    '& .MuiDataGrid-cell': {
                        padding: '0.3em',
                    },
                    backgroundColor: 'white',
                }}
            />
        </div>
    );
};

export default SecurityConfigUsers;