import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useUsers } from "../../../components/Common/HelperComponents/GetDataFromStore";

const QuestionAssignee = ({
  questionId,
  onSave,
  disabled = false,
  stakeholders,
  isLoading,
  error,
  saveMessage,
  saveError,
}) => {
  const { usersLoading, users } = useUsers();
  const [selectedUser, setSelectedUser] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Set initial value from stakeholder
  useEffect(() => {

    if (stakeholders?.stakeHolder) {
      // Check if the stakeholder exists in the users list
      const userExists = users?.some(
        (user) => user?.username === stakeholders.stakeHolder
      );
     
      // If the user doesn't exist in the list, add them temporarily
      if (!userExists && users?.length > 0) {
        users.push({ username: stakeholders.stakeHolder });
      }

      setSelectedUser(stakeholders.stakeHolder);
    }
  }, [stakeholders, users]);

  useEffect(() => {
    if (saveMessage) {
      setSnackbar({ open: true, message: saveMessage, severity: "success" });
      setShowActions(false);
    }
  }, [saveMessage]);

  useEffect(() => {
    if (saveError) {
      setSnackbar({ open: true, message: saveError, severity: "error" });
    }
  }, [saveError]);

  useEffect(() => {
    if (error) {
      if (error.includes("No stakeHolder found for the given questionId") || error.includes("StakeHolder.json file not found.")) {
        console.log("Error:", error);
      } else {
        setSnackbar({ open: true, message: error, severity: "error" });
      }
    }
  }, [error]);

  const handleUserChange = (event) => {
    console.log("User changed to:", event.target.value);
    setSelectedUser(event.target.value);
    setShowActions(true);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave(questionId, selectedUser);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    console.log(
      "Cancelling, resetting to stakeholder:",
      stakeholders?.stakeHolder
    );
    setSelectedUser(stakeholders?.stakeHolder || "");
    setShowActions(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Ensure the stakeholder is in the list of options
  const allUsers = React.useMemo(() => {
    if (!users) return [];
    const usersList = [...users];
    if (
      stakeholders?.stakeHolder &&
      !usersList.some((u) => u?.username === stakeholders.stakeHolder)
    ) {
      usersList.push({ username: stakeholders.stakeHolder });
    }
    return usersList;
  }, [users, stakeholders]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          ml: "auto",
          mr: 2,
          backgroundColor: "#f5f5f5",
          padding: "4px 8px",
          borderRadius: "4px",
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography
          sx={{
            fontSize: "0.8rem",
            color: "text.secondary",
            fontWeight: 500,
          }}
        >
          Assigned To:
        </Typography>
        {isLoading ? (
          <CircularProgress size={16} />
        ) : (
          <Select
            size="small"
            value={selectedUser || ""}
            onChange={handleUserChange}
            disabled={disabled || isSaving}
            sx={{
              minWidth: 150,
              fontSize: "0.8rem",
              backgroundColor: "white",
              "& .MuiSelect-select": {
                py: 0.5,
              },
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {usersLoading ? (
              <MenuItem disabled>
                <CircularProgress size={16} />
              </MenuItem>
            ) : (
              allUsers.map((user) => {
                return (
                  <MenuItem key={user?.username} value={user?.username}>
                    {user?.username}
                  </MenuItem>
                );
              })
            )}
          </Select>
        )}
        {showActions && (
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              ml: 0.5,
            }}
          >
            <Tooltip title="Save">
              <IconButton
                size="small"
                onClick={handleSave}
                disabled={disabled || isSaving}
                color="primary"
                sx={{
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                {isSaving ? (
                  <CircularProgress size={20} />
                ) : (
                  <SaveIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                size="small"
                onClick={handleCancel}
                disabled={disabled || isSaving}
                color="error"
                sx={{
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default QuestionAssignee;
