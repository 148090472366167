import React, { useState } from "react";
import { Tabs, Tab, Button, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";
import { useParams, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const CISWAFDashboard = () => {
  const [activeTab, setActiveTab] = useState("waf");
  const { accountNumber } = useParams();
  const history = useHistory();
  const location = useLocation();
  const lastRunDate = location.state?.lastRunDate;
  console.log("🚀 ~ CISWAFDashboard ~ lastRunDate:", lastRunDate)

  console.log("🚀 ~ CISWAFDashboard ~ accountNumber:", accountNumber);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "waf":
        return (
          <GetKibanaDashboard 
            dashboardKey="cis_waf" 
            params={{ 
              accountNumber,
              lastRunDate 
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ marginTop: "5rem" }}>
      {/* Tabs and Back Button in a Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="CIS" value="waf" />
        </Tabs>

        {/* Back Button at Flex End */}
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.goBack()}
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            px: 2,
            py: 1,
            mr: 2,
            borderColor: "#1976d2",
            color: "#1976d2",
            "&:hover": {
              borderColor: "#1565c0",
              backgroundColor: "#e3f2fd",
            },
          }}
        >
          Back
        </Button>
      </Box>

      {/* Dashboard Content */}
      {renderTabContent()}
    </Box>
  );
};

export default CISWAFDashboard;
