import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import WorkloadAssessment from "../components/WorkloadAssessment";
import { getMilestoneDetails } from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import { useQuestionnaireFiltering } from '../hooks/useQuestionnaireFiltering';

const MilestoneDetailPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { 
    workloadId, 
    orgCode, 
    accCode, 
    accNumber, 
    assessmentName,
    frameworkName,
    ownerName,
    approverName,
    milestoneName,
    milestoneNumber 
  } = location.state || {};
    console.log("🚀 ~ MilestoneDetailPage ~ milestoneName:", milestoneName)
    console.log("🚀 ~ MilestoneDetailPage ~ accCode:", accCode)
    console.log("🚀 ~ MilestoneDetailPage ~ workloadId:", workloadId)

  const {
    milestoneDetails,
    isGettingMilestoneDetails,
    getMilestoneDetailsMessage,
    getMilestoneDetailsError,
  } = useSelector((state) => state.wafrQuestionnaire);
    console.log("🚀 ~ MilestoneDetailPage ~ milestoneDetails:", milestoneDetails)

  const { filteredData, handleFilterChange } = useQuestionnaireFiltering(milestoneDetails);

  useEffect(() => {
    if (workloadId && accCode && milestoneName) {
      dispatch(getMilestoneDetails({ 
        workloadId, 
        accCode,
        milestoneName 
      }));
    }
  }, [dispatch, workloadId, accCode, milestoneName]);

  if (isGettingMilestoneDetails) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (getMilestoneDetailsError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "50vh",
          backgroundColor: "#fef8f8",
          border: "1px solid #f44336",
          borderRadius: "8px",
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
        <Typography
          variant="h6"
          fontWeight="bold"
          color="#f44336"
          sx={{ mb: 1 }}
        >
          Something Went Wrong
        </Typography>
        <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
          {getMilestoneDetailsError}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={() =>
            dispatch(getMilestoneDetails({ 
              workloadId, 
              accCode,
              milestoneName 
            }))
          }
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            borderColor: "#1976d2",
            color: "#1976d2",
            "&:hover": {
              borderColor: "#1565c0",
              backgroundColor: "#e3f2fd",
            },
          }}
        >
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: "1.5rem 2rem",
        mt: "5rem",
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
      }}
    >
      <WorkloadAssessment
        wlaData={filteredData}
        handleFilterChange={handleFilterChange}
        orgCode={orgCode}
        accCode={accCode}
        accNumber={accNumber}
        assessmentName={`${assessmentName} - Milestone: ${milestoneName}`}
        frameworkName={frameworkName}
        ownerName={ownerName}
        approverName={approverName}
        isReadOnly={true}
        initialData={milestoneDetails}
      />
    </Box>
  );
};

export default MilestoneDetailPage; 