import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

// Format the numbers to include currency and percentage symbols with null checks
const formatCurrency = (value) => {
  return value != null ? `$${Number(value).toLocaleString()}` : '-';
};

const formatPercentage = (value) => {
  return value != null ? `${Number(value).toFixed(1)}%` : '-';
};

const formatDate = (dateString) => {
  if (!dateString) return '-';
  // Remove time part if it exists
  const date = dateString.split(' ')[0];
  return new Date(date).toLocaleDateString();
};

const BillingDataGrid = ({ data = [] }) => {
  const columns = [
    {
      field: "dateRange",
      headerName: "Billing Period",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const from = new Date(params.row.dateFrom).toLocaleDateString();
        const to = new Date(params.row.dateTo).toLocaleDateString();
        return `${from} - ${to}`;
      }
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contractAmount",
      headerName: "Total Contract Amount",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => formatCurrency(params.row.contractAmount)
    },
    {
      field: "additionalAmount",
      headerName: "Compliance Charge",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => formatCurrency(params.row.additionalAmount)
    },
    {
      field: "thresholdAmount",
      headerName: "Monthly Contract Amount",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => formatCurrency(params.row.thresholdAmount)
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => formatDate(params.row.createdDate)
    },
    {
      field: "usagePercentage",
      headerName: "Overage Percentage",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => formatPercentage(params.row.usagePercentage)
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => formatCurrency(params.row.totalAmount)
    },
  ];

  // Ensure each row has a unique id
  const rows = data.map((row) => ({
    id: row.billingId,
    ...row
  }));

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: "hidden",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        loading={false}
        rowHeight={40}
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cell": {
            py: 0.5,
            fontSize: "14px",
            fontFamily: "Arial, sans-serif",
            textAlign: "center",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F5F5F5",
            fontWeight: "bold",
            fontSize: "14px",
            color: "#333",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: '1px solid #e0e0e0',
            backgroundColor: "#F5F5F5",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "white",
          },
          "& .MuiDataGrid-row": {
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
            "&.Mui-selected": {
              backgroundColor: "#e3f2fd",
              "&:hover": {
                backgroundColor: "#e3f2fd",
              },
            },
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
        }}
      />
    </Box>
  );
};

export default BillingDataGrid;
