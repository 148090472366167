import React from "react";
import { Box, Paper } from "@mui/material";
import SectionQuestionsPanel from "./SectionQuestionsPanel";
import SectionQuestionsDetailsPanel from "./SectionQuestionsDetailsPanel";

const SectionDetails = ({
  assessmentName,
  sectionQuestionLabel,
  sectionQuestionList,
  sectionQuestionDetailList,
  selectedQuestion,
  setSelectedQuestion,
  orgCode,
  accCode,
  accNumber,
  isReadOnly = false,
}) => {
  // Filter out hidden questions for the list display
  const visibleQuestions = sectionQuestionList?.filter((q) => !q.hidden) || [];
  const visibleQuestionDetails =
    sectionQuestionDetailList?.filter((q) => !q.hidden) || [];

  // Reset selected question if it's out of bounds after filtering
  React.useEffect(() => {
    if (selectedQuestion >= visibleQuestions.length) {
      setSelectedQuestion(0);
    }
  }, [visibleQuestions.length, selectedQuestion, setSelectedQuestion]);

  return (
    <Box
      display="flex"
      alignItems="stretch"
      gap="0.5rem"
      pt={1}
      pb={1}
      sx={{ height: "100%", overflow: "hidden" }}
    >
      {/* Questions Panel */}
      <Paper
        elevation={3}
        sx={{
          flex: "0 0 20%",
          padding: "0.25rem",
          backgroundColor: "white",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SectionQuestionsPanel
          sectionQuestionLabel={sectionQuestionLabel}
          sectionQuestionList={visibleQuestions}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
        />
      </Paper>

      {/* Question Detail Panel */}
      <Paper
        elevation={3}
        sx={{
          flex: "1",
          padding: "1rem",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {visibleQuestionDetails[selectedQuestion] && (
          <SectionQuestionsDetailsPanel
            assessmentName={assessmentName}
            sectionQuestionDetailList={visibleQuestionDetails}
            selectedQuestion={selectedQuestion}
            sectionQuestionLabel={sectionQuestionLabel}
            key={visibleQuestionDetails[selectedQuestion].questionTitle}
            orgCode={orgCode}
            accCode={accCode}
            accNumber={accNumber}
            isReadOnly={isReadOnly}
          />
        )}
      </Paper>
    </Box>
  );
};

export default SectionDetails;
