import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  NavItem,
  NavLink,
  Progress,
  Label,
  FormGroup,
  TabContent,
  TabPane,
  Input,
  Alert,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import {
  registerOrg,
  registerOrgFailed,
  resendEmail,
  apiError,
} from "../../store/actions";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { countryList, getRegionCode } from "../../utilities/countries";
import classnames from "classnames";
import logodark from "../../assets/images/logo-main.jpg";
import TermsOfUse from "../../components/Common/TermsOfUse";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl as MuiFormControl,
} from "@mui/material";

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { validateAddressWithAPI } from "../../helpers/addressValidation";
import { Typography } from "@mui/material";

class Register extends Component {
  constructor(props) {
    super(props);
    //console.log('Register props',props)
    this.state = {
      onehour: "",
      sixhours: "",
      twelvehours: "",
      twentyfourhours: "",
      numAcc: props.formData
        ? props.formData.maxacounts
          ? props.formData.maxacounts
          : ""
        : "",
      numUsers: props.formData
        ? props.formData.maxusers
          ? props.formData.maxusers
          : ""
        : "",
      aws: "",
      cis: "",
      nist: "",
      hipaa: "",
      fisma: "",
      fedramp: "",
      pci: "",
      gdpr: "",
      ccpa: "",
      soc2: "",
      iso: "",
      finra: "",
      fips140: "",
      gov: "",
      isUserVerified: false,
      captchaError: false,
      activeTabProgress: 1,
      progressValue: 25,
      orgName: props.formData
        ? props.formData.company
          ? props.formData.company
          : ""
        : "",
      orgCode: Math.random().toString(36).substring(2, 12),
      orgAddress: props.formData
        ? props.formData.workaddress
          ? props.formData.workaddress
          : ""
        : "",
      orgCity: props.formData
        ? props.formData.city
          ? props.formData.city
          : ""
        : "",
      orgState: props.formData
        ? props.formData.province
          ? props.formData.province
          : ""
        : "",
      orgZipCode: props.formData
        ? props.formData.zipcode
          ? props.formData.zipcode
          : ""
        : "",
      orgCountry: props.formData
        ? props.formData.country
          ? props.formData.country
          : ""
        : "",
      orgPhone: props.formData
        ? props.formData.phonenumber
          ? props.formData.phonenumber
          : ""
        : "",
      orgPhoneError: null,
      orgPrimaryContact: "",
      orgEmail: props.formData
        ? props.formData.email
          ? props.formData.email
          : ""
        : "",
      copybilling: false,
      orgBillingName: props.formData
        ? props.formData.company
          ? props.formData.company
          : ""
        : "",
      orgBillingEmail: props.formData
        ? props.formData.email
          ? props.formData.email
          : ""
        : "",
      orgBillingAddress: props.formData
        ? props.formData.workaddress
          ? props.formData.workaddress
          : ""
        : "",
      orgBillingState: props.formData
        ? props.formData.province
          ? props.formData.province
          : ""
        : "",
      orgBillingCity: props.formData
        ? props.formData.city
          ? props.formData.city
          : ""
        : "",
      orgBillingZipCode: props.formData
        ? props.formData.zipcode
          ? props.formData.zipcode
          : ""
        : "",
      orgBillingCountry: props.formData
        ? props.formData.country
          ? props.formData.country
          : ""
        : "",
      userFirstName: props.formData
        ? props.formData.firstname
          ? props.formData.firstname
          : ""
        : "",
      userLastName: props.formData
        ? props.formData.lastname
          ? props.formData.lastname
          : ""
        : "",
      userName: "",
      userPassword: "",
      confirmPassword: "",
      userEmail: "",
      step1Error: false,
      step2Error: false,
      step4Error: false,
      selltoken: props.formData
        ? props.formData.token
          ? props.formData.token
          : ""
        : "",
      checkbox: false,
      showPassword: false,
      showConfirmPassword: false,
      orgAddressValidationStatus: null,
      orgAddressValidationMessage: "",
      orgBillingAddressValidationStatus: null,
      orgBillingAddressValidationMessage: "",
    };
  }

  toggleTabProgress = (tab) => {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({ activeTabProgress: tab });
        if (tab === 1) {
          this.setState({ progressValue: 25 });
        }
        if (tab === 2) {
          this.setState({ progressValue: 50 });
        }
        if (tab === 3) {
          this.setState({ progressValue: 75 });
        }
        if (tab === 4) {
          this.setState({ progressValue: 100 });
        }
      }
    }
  };

  componentDidMount() {
    this.handlePhoneBlur();
    this.props.registerOrgFailed("");
    this.props.apiError("");
    console.log("tok" + this.props.formData.token);
    console.log("tokee" + this.props.token);
    this.setState({ selltoken: this.props.formData.token });
    document.body.classList.add("auth-body-bg");

    // Validate address on page load if orgAddress and orgCountry are populated
    if (this.state.orgAddress && this.state.orgCountry) {
      this.validateOrgAddress();
    }

    if (this.state.orgBillingAddress && this.state.orgBillingCountry) {
      this.validateBillingAddress();
    }
  }

  verifyCallback = (response) => {
    if (response) {
      this.setState({ isUserVerified: true });
    }
  };
  handleChange = () => {
    if (this.state.checkbox) {
      this.setState({ checkbox: false });
    } else {
      this.setState({ checkbox: true });
    }
  };

  handlePhoneChange = (value) => {
    let isInvalidResult;
    if (value === null || value === "" || value === undefined) {
      isInvalidResult = true;
      value = "";
    } else {
      isInvalidResult = !isPossiblePhoneNumber(value);
    }
    if (isInvalidResult) {
      this.setState({
        orgPhone: value,
        orgPhoneError: "Please enter a valid phone number",
      });
    } else {
      this.setState({ orgPhone: value, orgPhoneError: "" });
    }
  };

  handlePhoneBlur = () => {
    let isInvalidResult;
    if (!this.state.orgPhone) {
      isInvalidResult = true;
    } else {
      isInvalidResult = !isPossiblePhoneNumber(this.state.orgPhone);
    }
    if (isInvalidResult) {
      this.setState({ orgPhoneError: "Please enter a valid phone number" });
    } else {
      this.setState({ orgPhoneError: "" });
    }
  };

  handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "copybilling") {
      if (checked) {
        this.setState(
          {
            [name]: true,
            orgBillingName: this.state.orgName,
            orgBillingEmail: this.state.orgEmail,
            orgBillingAddress: this.state.orgAddress,
            orgBillingCity: this.state.orgCity,
            orgBillingState: this.state.orgState,
            orgBillingZipCode: this.state.orgZipCode,
            orgBillingCountry: this.state.orgCountry,
          },
          () => {
            // Trigger billing address validation after state is updated
            if (this.state.orgBillingAddress && this.state.orgBillingCountry) {
              this.validateBillingAddress();
            }
          }
        );
      } else {
        this.setState({
          [name]: false,
          orgBillingName: "",
          orgBillingEmail: "",
          orgBillingAddress: "",
          orgBillingState: "",
          orgBillingCity: "",
          orgBillingZipCode: "",
          orgBillingCountry: "",
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (typeof this.props.organization === "string") {
      this.toggleTabProgress(this.state.activeTabProgress + 1);
    }
    //Redirect to login after 10 seconds on success
    if (this.props.message) {
      this.timer = setTimeout(() => {
        this.props.history.push("/login");
      }, 10000);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleSubmit = () => {
    if (true) {
      // console.log({ ...this.state, userName: this.state.userEmail });
      this.props.registerOrg({ ...this.state, userName: this.state.userEmail });
    } else {
      this.setState({ captchaError: true });
    }
  };

  handleResendEmail = () => {
    const email = this.state.userEmail;
    this.props.resendEmail(email);
  };

  isStep1Valid = () => {
    const {
      orgName,
      orgCode,
      orgAddress,
      orgCountry,
      orgPhone,
      orgPrimaryContact,
      orgEmail,
      orgAddressValidationStatus,
    } = this.state;
    if (
      orgName !== "" &&
      orgCode !== "" &&
      orgAddress !== "" &&
      this.isValidCountry(orgCountry) &&
      this.isValidPhone(orgPhone) &&
      orgPrimaryContact !== "" &&
      this.isValidEmail(orgEmail) &&
      orgAddressValidationStatus === "valid"
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleStep1 = () => {
    if (this.isStep1Valid()) {
      this.setState({ step1Error: false });
      return true;
    } else {
      this.setState({ step1Error: true });
      // return false;
    }
  };

  isStep2Valid = () => {
    const {
      orgBillingName,
      orgBillingEmail,
      orgBillingAddress,
      orgBillingCountry,
      orgBillingAddressValidationStatus,
    } = this.state;
    if (
      orgBillingName !== "" &&
      this.isValidEmail(orgBillingEmail) &&
      orgBillingAddress !== "" &&
      this.isValidCountry(orgBillingCountry) &&
      orgBillingAddressValidationStatus === "valid"
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleStep2 = () => {
    if (this.isStep2Valid()) {
      this.setState({ step2Error: false });
      return true;
    } else {
      this.setState({ step2Error: true });
      return false;
    }
  };

  isStep4Valid = () => {
    const {
      userFirstName,
      userLastName,
      userPassword,
      confirmPassword,
      userEmail,
      checkbox,
    } = this.state;
    if (
      userFirstName !== "" &&
      userLastName !== "" &&
      // userName !== "" &&
      this.isValidPassword(userPassword) &&
      this.isValidEmail(userEmail) &&
      this.isValidConfirmPassword(confirmPassword, userPassword) &&
      checkbox
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleStep4 = () => {
    if (this.isStep4Valid()) {
      this.setState({ step4Error: false });
      return true;
    } else {
      this.setState({ step4Error: true });
      return false;
    }
  };

  isCurrentStepValid = () => {
    if (this.state.activeTabProgress === 1) {
      return this.isStep1Valid();
    } else if (this.state.activeTabProgress === 2) {
      return this.isStep2Valid();
    } else if (this.state.activeTabProgress === 3) {
      return this.isStep4Valid();
    } else {
      return false;
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  isValidPhone = (phone) => {
    let isValidPhone;
    if (phone === null) {
      isValidPhone = false;
    } else {
      isValidPhone = isPossiblePhoneNumber(this.state.orgPhone);
    }
    return isValidPhone;
  };

  isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  isMatchingDomain = (userEmail, orgEmail) => {
    if (!userEmail || !orgEmail) return false;
    const userDomain = userEmail.split("@")[1];
    const orgDomain = orgEmail.split("@")[1];
    return userDomain === orgDomain;
  };

  invalidEmailErrorMessage = (email, fieldType = "regular") => {
    if (email.length === 0) {
      return "Email is required";
    }
    if (!this.isValidEmail(email)) {
      return "Please enter a valid email address";
    }
    if (
      fieldType === "user" &&
      !this.isMatchingDomain(email, this.state.orgEmail)
    ) {
      return `Email domain must match organization email domain (${
        this.state.orgEmail.split("@")[1]
      })`;
    }
    return "";
  };

  isValidCity = (city) => {
    const cityRegex = /^[A-Za-z ]+$/;
    return cityRegex.test(city);
  };

  invalidCityErrorMessage = (city) => {
    if (city.length === 0) {
      return "City is required";
    } else if (!this.isValidCity(city)) {
      return "Enter correct city";
    } else {
      return "";
    }
  };

  isValidState = (state) => {
    const stateRegex = /^[A-Za-z ]+$/;
    return stateRegex.test(state);
  };

  invalidStateErrorMessage = (state) => {
    if (state.length === 0) {
      return "State is required";
    } else if (!this.isValidState(state)) {
      return "Enter correct state";
    } else {
      return "";
    }
  };

  isValidZip = (zip) => {
    // const zipRegex = /\b\d{5,}\b/;
    // return zipRegex.test(zip);
    return true;
  };

  invalidZipErrorMessage = (zip) => {
    if (zip.length === 0) {
      return "Zip code is required";
    } else if (!this.isValidZip(zip)) {
      return "Enter correct zip code";
    } else {
      return "";
    }
  };

  isValidCountry = (country) => {
    return !(country.length === 0) && !(country === "Select Country");
  };

  invalidCountryErrorMessage = (country) => {
    // console.log("Selected country is ."+country+".");
    if (country.length === 0) {
      return "Country is required";
    } else if (country === "Select Country") {
      return "Select correct country";
    } else {
      return "";
    }
  };

  isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return passwordRegex.test(password);
  };

  invalidPasswordErrorMessage = (password) => {
    if (password.length === 0) {
      return "Password is required";
    } else if (password.length < 8) {
      return "Password must be at least 8 characters";
    } else if (!this.isValidPassword(password)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (allowed: !@#$%^&*()_+)";
    } else {
      return "";
    }
  };

  isValidConfirmPassword = (confirmPassword, password) => {
    return (
      this.isValidPassword(confirmPassword) && confirmPassword === password
    );
  };

  invalidConfirmPasswordErrorMessage = (confirmPassword, password) => {
    if (confirmPassword.length === 0) {
      return "Password is required";
    } else if (confirmPassword.length < 8) {
      return "Password must be at least 8 characters";
    } else if (!this.isValidPassword(confirmPassword)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (allowed: !@#$%^&*()_+)";
    } else if (confirmPassword !== password) {
      return "Passwords do not match";
    } else {
      return "";
    }
  };

  validateOrgAddress = async () => {
    if (!this.state.orgAddress.trim() || !this.state.orgCountry) return;

    this.setState({
      orgAddressValidationStatus: null,
      orgAddressValidationMessage: "Validating address...",
    });

    const regionCode = getRegionCode(this.state.orgCountry);
    if (!regionCode) {
      this.setState({
        orgAddressValidationStatus: "invalid",
        orgAddressValidationMessage: "Please select a valid country first",
      });
      return;
    }

    try {
      const result = await validateAddressWithAPI(
        this.state.orgAddress,
        regionCode,
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      );

      this.setState({
        orgAddressValidationStatus: result.isValid ? "valid" : "invalid",
        orgAddressValidationMessage: result.message,
        orgAddress: result.formattedAddress || this.state.orgAddress,
        orgCity: result.city,
        orgState: result.state,
        orgZipCode: result.zip,
      });
    } catch (error) {
      this.setState({
        orgAddressValidationStatus: "invalid",
        orgAddressValidationMessage:
          "Failed to validate address. Please try again.",
      });
    }
  };

  validateBillingAddress = async () => {
    if (!this.state.orgBillingAddress.trim() || !this.state.orgBillingCountry)
      return;

    this.setState({
      orgBillingAddressValidationStatus: null,
      orgBillingAddressValidationMessage: "Validating address...",
    });

    const regionCode = getRegionCode(this.state.orgBillingCountry);
    if (!regionCode) {
      this.setState({
        orgBillingAddressValidationStatus: "invalid",
        orgBillingAddressValidationMessage:
          "Please select a valid country first",
      });
      return;
    }

    try {
      const result = await validateAddressWithAPI(
        this.state.orgBillingAddress,
        regionCode,
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      );

      this.setState({
        orgBillingAddressValidationStatus: result.isValid ? "valid" : "invalid",
        orgBillingAddressValidationMessage: result.message,
        orgBillingAddress:
          result.formattedAddress || this.state.orgBillingAddress,
        orgBillingCity: result.city,
        orgBillingState: result.state,
        orgBillingZipCode: result.zip,
      });
    } catch (error) {
      this.setState({
        orgBillingAddressValidationStatus: "invalid",
        orgBillingAddressValidationMessage:
          "Failed to validate address. Please try again.",
      });
    }
  };

  render() {
    const defaultValues = {
      compliance: ["aws", "cis"],
    };

    return (
      <React.Fragment>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col lg={6}>
              <div className="authentication-page-content auth-org-reg p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img src={logodark} height="60" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-5">
                            Register account
                          </h4>
                        </div>

                        <div id="progrss-wizard" className="twitter-bs-wizard">
                          <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTabProgress === 1,
                                })}
                              >
                                <span className="step-number">01</span>
                                <span className="step-title">
                                  Organization Information
                                </span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTabProgress === 2,
                                })}
                              >
                                <span className="step-number">02</span>
                                <span className="step-title">
                                  <strong>Billing Information</strong>
                                </span>
                              </NavLink>
                            </NavItem>

                            {/* <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTabProgress === 3,
                                })}
                              >
                                <span className="step-number">03</span>
                                <span className="step-title">
                                  Set up Licensing
                                </span>
                              </NavLink>
                            </NavItem> */}

                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTabProgress === 3,
                                })}
                              >
                                <span className="step-number">03</span>
                                <span className="step-title">
                                  Create Admin User Account
                                </span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTabProgress === 4,
                                })}
                              >
                                <span className="step-number">04</span>
                                <span className="step-title">
                                  Account Created
                                </span>
                              </NavLink>
                            </NavItem>
                          </ul>

                          <div id="bar" className="mt-2">
                            <Progress
                              color="success"
                              striped
                              animated
                              value={this.state.progressValue}
                            />
                          </div>

                          {(this.state.step1Error ||
                            this.state.step2Error ||
                            this.state.step4Error) && (
                            <Alert className="mt-3 mb-0" color="danger">
                              Please fill all required fields
                            </Alert>
                          )}

                          {/* {this.props.organizationError &&
                          typeof this.props.organizationError.data ===
                            "string" ? (
                            <Alert className="mt-3 mb-0" color="danger">
                              {this.props.organizationError.data}
                            </Alert>
                          ) : null} */}
                          {
                            this.props.organizationError && (
                              // typeof this.props.organizationError.data ===
                              //   "string" ?
                              <Alert className="mt-3 mb-0" color="danger">
                                {typeof this.props.organizationError ===
                                "string"
                                  ? this.props.organizationError
                                  : JSON.stringify(
                                      this.props.organizationError
                                    )}
                                {/* {JSON.stringify(this.props.organizationError)} */}
                                {/* {JSON.stringify(this.props.organizationError?.message)} */}
                              </Alert>
                            )
                            // : null
                          }

                          {this.props.message && (
                            <Alert className="mt-3 mb-0" color="success">
                              You will be redirected to Login page in 10
                              Seconds.
                            </Alert>
                          )}

                          <TabContent
                            activeTab={this.state.activeTabProgress}
                            className="twitter-bs-wizard-tab-content"
                          >
                            <TabPane tabId={1}>
                              <AvForm className="form-horizontal">
                                <Row>
                                  <Col lg="12">
                                    <FormGroup>
                                      <TextField
                                        name="orgName"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Organization Name *"
                                        value={this.state.orgName}
                                        placeholder="Organization Name"
                                        type="text"
                                        id="basicpill-orgName-input"
                                        className={`form-control ${
                                          this.state.step1Error &&
                                          this.state.orgName === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        error={
                                          this.state.orgName.length === 0
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.orgName.length === 0
                                            ? "Organization Name is required"
                                            : ""
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row
                                  className="address-row"
                                  style={{
                                    marginTop: "10px",
                                    display: "flex",
                                    alignItems: "center", // Align items vertically in the center
                                  }}
                                >
                                  <Col
                                    lg="6"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FormGroup
                                      style={{ marginBottom: 0, width: "100%" }}
                                    >
                                      <MuiFormControl fullWidth>
                                        <InputLabel id="org-country-select-label">
                                          Country *
                                        </InputLabel>
                                        <Select
                                          name="orgCountry"
                                          label="Country"
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          id="basicpill-orgCountry-input"
                                          labelId="org-country-select-label"
                                          value={this.state.orgCountry || ""}
                                          onChange={(e) => {
                                            this.handleInputChange(e);
                                            this.setState({
                                              orgAddress: "",
                                              orgAddressValidationStatus: null,
                                              orgAddressValidationMessage: "",
                                            });
                                          }}
                                          error={
                                            !this.isValidCountry(
                                              this.state.orgCountry
                                            )
                                          }
                                          style={{ height: "40px" }} // Set a consistent height
                                        >
                                          {countryList.map((country) => (
                                            <MenuItem
                                              key={country.code}
                                              value={country.name}
                                            >
                                              {country.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </MuiFormControl>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="6"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FormGroup
                                      style={{ marginBottom: 0, width: "100%" }}
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          width: "100%",
                                        }}
                                      >
                                        <TextField
                                          fullWidth
                                          label="Address *"
                                          name="orgAddress"
                                          value={this.state.orgAddress}
                                          onChange={(e) => {
                                            this.setState({
                                              orgAddress: e.target.value,
                                              orgAddressValidationStatus: null,
                                              orgAddressValidationMessage: "",
                                            });
                                          }}
                                          onBlur={this.validateOrgAddress}
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          required
                                          disabled={!this.state.orgCountry}
                                          placeholder={
                                            !this.state.orgCountry
                                              ? "Please select a country first"
                                              : "Enter complete address"
                                          }
                                          error={
                                            this.state
                                              .orgAddressValidationStatus ===
                                            "invalid"
                                          }
                                          style={{ height: "40px" }} // Set a consistent height
                                          InputProps={{
                                            endAdornment: this.state
                                              .orgAddressValidationStatus ===
                                              "valid" && (
                                              <i
                                                className="mdi mdi-check-circle"
                                                style={{
                                                  position: "absolute",
                                                  right: "10px",
                                                  color: "green",
                                                }}
                                              />
                                            ),
                                          }}
                                        />
                                        {this.state
                                          .orgAddressValidationMessage && (
                                          <Typography
                                            variant="caption"
                                            color={
                                              this.state
                                                .orgAddressValidationStatus ===
                                              "valid"
                                                ? "success"
                                                : "error"
                                            }
                                            sx={{ mt: 0.5, display: "block" }}
                                          >
                                            {
                                              this.state
                                                .orgAddressValidationMessage
                                            }
                                          </Typography>
                                        )}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup>
                                      <TextField
                                        name="orgPrimaryContact"
                                        value={this.state.orgPrimaryContact}
                                        placeholder="Primary Contact Name"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Primary Contact Name *"
                                        id="basicpill-orgPrimaryContact-input"
                                        className={`form-control ${
                                          this.state.step1Error &&
                                          this.state.orgPrimaryContact === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        error={
                                          this.state.orgPrimaryContact
                                            .length === 0
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.orgPrimaryContact
                                            .length === 0
                                            ? "Primary Contact Name is required"
                                            : ""
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <TextField
                                        name="orgEmail"
                                        value={this.state.orgEmail}
                                        placeholder="Email"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Email *"
                                        id="basicpill-orgEmail-input"
                                        className={`form-control ${
                                          this.state.step1Error &&
                                          this.state.orgEmail === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        inputProps={{
                                          type: "email",
                                        }}
                                        error={
                                          this.state.orgEmail.length === 0 ||
                                          !this.isValidEmail(
                                            this.state.orgEmail
                                          )
                                        }
                                        helperText={this.invalidEmailErrorMessage(
                                          this.state.orgEmail
                                        )}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="custom-control custom-checkbox mb-0">
                                      <Input
                                        type="checkbox"
                                        id="termsAndConditionsCheckbox2"
                                        className="custom-control-input"
                                        onChange={() => false}
                                        checked={this.state.customchk}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </TabPane>
                            <TabPane tabId={2}>
                              <AvForm className="form-horizontal">
                                <Row>
                                  <Col lg="12">
                                    <div className="custom-control custom-checkbox mb-3">
                                      <Input
                                        type="checkbox"
                                        id="billingCheckbox"
                                        className="custom-control-input"
                                        name="copybilling"
                                        checked={this.state.copybilling}
                                        onChange={this.handleInputChange}
                                      />
                                      <Label
                                        htmlFor="billingCheckbox"
                                        className="custom-control-label"
                                      >
                                        Same as organization information
                                      </Label>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup>
                                      <TextField
                                        name="orgBillingName"
                                        value={this.state.orgBillingName}
                                        placeholder="Organization Name"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Organization Name *"
                                        id="basicpill-orgBillingName-input"
                                        className={`form-control ${
                                          this.state.step2Error &&
                                          this.state.orgBillingName === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        error={
                                          this.state.orgBillingName.length === 0
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.orgBillingName.length === 0
                                            ? "Organization name is required"
                                            : ""
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <TextField
                                        name="orgBillingEmail"
                                        value={this.state.orgBillingEmail}
                                        placeholder="Email"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Email *"
                                        id="basicpill-orgBillingEmail-input"
                                        className={`form-control ${
                                          this.state.step2Error &&
                                          this.state.orgBillingEmail === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        inputProps={{
                                          type: "email",
                                        }}
                                        error={
                                          this.state.orgBillingEmail.length ===
                                            0 ||
                                          !this.isValidEmail(
                                            this.state.orgBillingEmail
                                          )
                                        }
                                        helperText={this.invalidEmailErrorMessage(
                                          this.state.orgBillingEmail
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row
                                  className="address-row"
                                  style={{
                                    marginTop: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col
                                    lg="6"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FormGroup
                                      style={{ marginBottom: 0, width: "100%" }}
                                    >
                                      <MuiFormControl fullWidth>
                                        <InputLabel id="billing-country-select-label">
                                          Country *
                                        </InputLabel>
                                        <Select
                                          name="orgBillingCountry"
                                          label="Country"
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          id="basicpill-orgBillingCountry-input"
                                          labelId="billing-country-select-label"
                                          value={
                                            this.state.orgBillingCountry || ""
                                          }
                                          onChange={this.handleInputChange}
                                          error={
                                            !this.isValidCountry(
                                              this.state.orgBillingCountry
                                            )
                                          }
                                          style={{ height: "40px" }}
                                        >
                                          {countryList.map((country) => (
                                            <MenuItem
                                              key={country.code}
                                              value={country.name}
                                            >
                                              {country.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </MuiFormControl>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="6"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        width: "100%",
                                      }}
                                    >
                                      <TextField
                                        fullWidth
                                        label="Address *"
                                        name="orgBillingAddress"
                                        value={this.state.orgBillingAddress}
                                        onChange={this.handleInputChange}
                                        onBlur={this.validateBillingAddress}
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        required
                                        disabled={!this.state.orgBillingCountry}
                                        placeholder={
                                          !this.state.orgBillingCountry
                                            ? "Please select a country first"
                                            : "Enter complete address"
                                        }
                                        error={
                                          this.state
                                            .orgBillingAddressValidationStatus ===
                                          "invalid"
                                        }
                                        style={{ height: "40px" }}
                                        InputProps={{
                                          endAdornment: this.state
                                            .orgBillingAddressValidationStatus ===
                                            "valid" && (
                                            <i
                                              className="mdi mdi-check-circle"
                                              style={{
                                                position: "absolute",
                                                right: "10px",
                                                color: "green",
                                              }}
                                            />
                                          ),
                                        }}
                                      />
                                      {this.state
                                        .orgBillingAddressValidationMessage && (
                                        <Typography
                                          variant="caption"
                                          color={
                                            this.state
                                              .orgBillingAddressValidationStatus ===
                                            "valid"
                                              ? "success"
                                              : "error"
                                          }
                                          sx={{ mt: 0.5, display: "block" }}
                                        >
                                          {
                                            this.state
                                              .orgBillingAddressValidationMessage
                                          }
                                        </Typography>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </TabPane>

                            {/* <TabPane tabId={3}>
                              <h5 className="mb-4">Set up Licensing</h5>
                              <div>
                                <AvForm className="form-horizontal" model={defaultValues}>
                                  <Row>
                                    <Col md="7">
                                      <FormGroup>
                                        <Label htmlFor="basicpill-trial-input">
                                          Environment
                                        </Label>
                                        <AvRadioGroup name="env" label="" required errorMessage="Pick one!" value={this.state.env}>
                                          <AvRadio label="Trial" value="Yes" />
                                          <AvRadio label="Production" value="No" />
                                        </AvRadioGroup>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="7">
                                      <FormGroup>
                                        <Label htmlFor="basicpill-userFirstName-input">
                                          Scan Freq
                                        </Label>
                                        <AvRadioGroup name="scanFreq" label="" required errorMessage="Pick one!" value={this.state.scanFreq}>
                                          <AvRadio label="onehour" value={this.state.onehour} />
                                          <AvRadio label="sixhours" value={this.state.sixhours} />
                                          <AvRadio label="twelvehours" value={this.state.twelvehours} />
                                          <AvRadio label="twentyfourhours" value={this.state.twentyfourhours} />
                                        </AvRadioGroup>
                                      </FormGroup>
                                    </Col>
                                    <Col md="7">
                                      <FormGroup>
                                        <Label htmlFor="basicpill-numAcc-input">
                                          Number of Accounts
                                        </Label>
                                        <AvField
                                          name="numAcc"
                                          value={this.state.numAcc}
                                          placeholder="Number of Accounts"
                                          type="text"
                                          id="basicpill-numAcc-input"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="7">
                                      <FormGroup>
                                        <Label htmlFor="basicpill-numUsers-input">
                                          Number of Users
                                        </Label>
                                        <AvField
                                          name="numUsers"
                                          value={this.state.numUsers}
                                          placeholder="Number of Users"
                                          type="text"
                                          id="basicpill-numUsers-input"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Col md="7">
                                    <FormGroup>
                                      <Label htmlFor="basicpill-compliance-input">
                                        Compliance
                                      </Label>
                                      <AvCheckboxGroup inline name="compliance" value={this.state.compliance} required>
                                        <AvCheckbox label="aws" value={this.state.aws} disabled />
                                        <AvCheckbox label="cis" value={this.state.cis} disabled />
                                        <AvCheckbox label="nist" value={this.state.nist} />
                                        <AvCheckbox label="hipaa" value={this.state.hipaa} />
                                        <AvCheckbox label="fisma" value={this.state.fisma} />
                                        <AvCheckbox label="fedramp" value={this.state.fedramp} />
                                        <AvCheckbox label="pci" value={this.state.pci} />
                                        <AvCheckbox label="gdpr" value={this.state.gdpr} />
                                        <AvCheckbox label="ccpa" value={this.state.ccpa} />
                                        <AvCheckbox label="soc2" value={this.state.soc2} />
                                        <AvCheckbox label="iso" value={this.state.iso} />
                                        <AvCheckbox label="finra" value={this.state.finra} />
                                        <AvCheckbox label="fips140" value={this.state.fips140} />
                                        <AvCheckbox label="gov" value={this.state.gov} />
                                      </AvCheckboxGroup>
                                    </FormGroup>
                                  </Col>
                                  <Row>

                                  </Row>
                                </AvForm>
                              </div>
                            </TabPane> */}

                            <TabPane tabId={3}>
                              <div>
                                <AvForm className="form-horizontal">
                                  <Row>
                                    <Col md="6">
                                      <FormGroup>
                                        <TextField
                                          name="userFirstName"
                                          value={this.state.userFirstName}
                                          placeholder="First name"
                                          type="text"
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          label="First Name *"
                                          id="basicpill-userFirstName-input"
                                          className={`form-control ${
                                            this.state.step4Error &&
                                            this.state.userFirstName === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          error={
                                            this.state.userFirstName.length ===
                                            0
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            this.state.userFirstName.length ===
                                            0
                                              ? "First Name is required"
                                              : ""
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup>
                                        <TextField
                                          name="userLastName"
                                          value={this.state.userLastName}
                                          placeholder="Last name"
                                          type="text"
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          label="Last Name *"
                                          id="basicpill-userLastName-input"
                                          className={`form-control ${
                                            this.state.step4Error &&
                                            this.state.userLastName === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          error={
                                            this.state.userLastName.length === 0
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            this.state.userLastName.length === 0
                                              ? "Last Name is required"
                                              : ""
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup>
                                        <TextField
                                          name="userEmail"
                                          value={this.state.userEmail}
                                          placeholder="Email"
                                          type="text"
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          label="Email *"
                                          id="basicpill-userEmail-input"
                                          className={`form-control ${
                                            this.state.step4Error &&
                                            this.state.userEmail === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          inputProps={{
                                            type: "email",
                                          }}
                                          error={
                                            this.state.userEmail.length === 0 ||
                                            !this.isValidEmail(
                                              this.state.userEmail
                                            ) ||
                                            !this.isMatchingDomain(
                                              this.state.userEmail,
                                              this.state.orgEmail
                                            )
                                          }
                                          helperText={this.invalidEmailErrorMessage(
                                            this.state.userEmail,
                                            "user"
                                          )}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <TextField
                                          label="Password *"
                                          variant="outlined"
                                          name="userPassword"
                                          margin="dense"
                                          size="small"
                                          value={this.state.userPassword}
                                          type={
                                            this.state.showPassword
                                              ? "text"
                                              : "password"
                                          }
                                          id="basicpill-userPassword-input"
                                          placeholder="Enter password"
                                          fullWidth
                                          onChange={(e) =>
                                            this.setState({
                                              userPassword: e.target.value,
                                            })
                                          }
                                          error={
                                            this.state.userPassword.length ===
                                              0 ||
                                            !this.isValidPassword(
                                              this.state.userPassword
                                            )
                                          }
                                          helperText={this.invalidPasswordErrorMessage(
                                            this.state.userPassword
                                          )}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={
                                                    this.handleClickShowPassword
                                                  }
                                                  onMouseDown={
                                                    this.handleMouseDownPassword
                                                  }
                                                >
                                                  {this.state.showPassword ? (
                                                    <Visibility />
                                                  ) : (
                                                    <VisibilityOff />
                                                  )}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup>
                                        <TextField
                                          label="Confirm Password *"
                                          variant="outlined"
                                          name="confirmPassword"
                                          margin="dense"
                                          size="small"
                                          value={this.state.confirmPassword}
                                          type={
                                            this.state.showConfirmPassword
                                              ? "text"
                                              : "password"
                                          }
                                          id="basicpill-userPassword-input"
                                          placeholder="Enter password"
                                          fullWidth
                                          onChange={(e) =>
                                            this.setState({
                                              confirmPassword: e.target.value,
                                            })
                                          }
                                          error={
                                            this.state.confirmPassword
                                              .length === 0 ||
                                            !this.isValidConfirmPassword(
                                              this.state.confirmPassword,
                                              this.state.userPassword
                                            )
                                          }
                                          helperText={this.invalidConfirmPasswordErrorMessage(
                                            this.state.confirmPassword,
                                            this.state.userPassword
                                          )}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={
                                                    this
                                                      .handleClickShowConfirmPassword
                                                  }
                                                  onMouseDown={
                                                    this.handleMouseDownPassword
                                                  }
                                                >
                                                  {this.state
                                                    .showConfirmPassword ? (
                                                    <Visibility />
                                                  ) : (
                                                    <VisibilityOff />
                                                  )}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="12">
                                      <div className="custom-control custom-checkbox mb-0">
                                        <Input
                                          type="checkbox"
                                          id="termsAndConditionsCheckbox"
                                          className="custom-control-input"
                                          onChange={this.handleChange}
                                          checked={this.state.checkbox}
                                        />
                                        <TermsOfUse />
                                      </div>
                                    </Col>
                                  </Row>
                                  {/*    <Row>
                                    <Col md="12">
                                      <Recaptcha
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        render="explicit"
                                        onLoad={() => { }}
                                        ref={e => this.recaptchaInstance = e}
                                        verifyCallback={this.verifyCallback}
                                      />
                                    </Col>
                                  </Row> */}
                                </AvForm>
                              </div>
                            </TabPane>

                            <TabPane tabId={4}>
                              <div className="row justify-content-center">
                                <Col lg="11">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                    </div>
                                    <div>
                                      <h5>
                                        {"Organization Created Successfully"}
                                      </h5>
                                      <p className="text-muted">
                                        An email has been sent to{" "}
                                        {this.state.userEmail}. Please check for
                                        an email from CloudCatcher and click on
                                        the included link to activate your
                                        account.
                                      </p>
                                      {this.props.email && (
                                        <Alert color="success">
                                          Verification Email Sent
                                        </Alert>
                                      )}
                                    </div>
                                    <div className="p-2 mt-0">
                                      <AvForm className="form-horizontal">
                                        <div className="mt-0 text-center">
                                          <Button
                                            color="primary"
                                            className="w-md waves-effect waves-light"
                                            type="submit"
                                            onClick={this.handleResendEmail}
                                          >
                                            {this.props.loading
                                              ? "Loading ..."
                                              : "Resend Email"}
                                          </Button>
                                        </div>
                                      </AvForm>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>
                          {this.state.activeTabProgress !== 4 ? (
                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                              <li
                                className={
                                  this.state.activeTabProgress === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                {this.state.activeTabProgress !== 1 ? (
                                  <Button
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="button"
                                    onClick={() => {
                                      this.toggleTabProgress(
                                        this.state.activeTabProgress - 1
                                      );
                                      this.setState({
                                        orgCode: Math.random()
                                          .toString(36)
                                          .substring(2, 12),
                                      });
                                    }}
                                    disabled={
                                      this.state.activeTabProgress === 4
                                    }
                                  >
                                    Previous
                                  </Button>
                                ) : null}
                              </li>
                              <li
                                className={
                                  this.state.activeTabProgress === 4
                                    ? "next disabled"
                                    : "next"
                                }
                              >
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="button"
                                  onClick={() => {
                                    if (
                                      this.state.activeTabProgress === 3 &&
                                      this.handleStep4()
                                    ) {
                                      this.handleSubmit();
                                    } else {
                                      if (
                                        (this.state.activeTabProgress === 1 &&
                                          this.handleStep1()) ||
                                        (this.state.activeTabProgress === 2 &&
                                          this.handleStep2())
                                      ) {
                                        this.toggleTabProgress(
                                          this.state.activeTabProgress + 1
                                        );
                                      }
                                    }
                                  }}
                                  // disabled={this.isInvalid() || this.state.activeTabProgress===4}
                                  disabled={!this.isCurrentStepValid()}
                                >
                                  {this.state.activeTabProgress !== 3
                                    ? "Next"
                                    : `${
                                        this.props.loading
                                          ? "Submitting ..."
                                          : "Submit"
                                      }`}
                                </Button>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                        <div className="mt-5 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="font-weight-medium text-primary"
                            >
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="authentication-bg auth-org-reg"></div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    organization,
    organizationError,
    email,
    emailError,
    loading,
    message,
  } = state.account;
  return {
    organization,
    organizationError,
    email,
    emailError,
    loading,
    message,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    registerOrg,
    registerOrgFailed,
    resendEmail,
    apiError,
  })(Register)
);
