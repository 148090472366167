// questionService.js
import wafrData from './WAFR.json';

/**
 * Retrieves the full formatted question for a given question title.
 * @param {string} questionTitle - The title of the question.
 * @returns {string|null} - The formatted question (e.g., 'OPS 1. How do you determine what your priorities are?'), or null if not found.
 */
export function getFormattedQuestion(questionTitle) {
  // console.log("🚀 ~ getFormattedQuestion ~ questionTitle:", questionTitle);

  // Normalize input: trim spaces and convert to lowercase
  const normalizedTitle = questionTitle.trim().toLowerCase();

  // Normalize all keys in wafrData for case-insensitive lookup
  const questionKeys = Object.keys(wafrData).map(q => q.trim().toLowerCase());

  // Find the exact match index
  const foundIndex = questionKeys.indexOf(normalizedTitle);

  // If found, get the original key and identifier
  if (foundIndex !== -1) {
    const originalKey = Object.keys(wafrData)[foundIndex];
    const questionData = wafrData[originalKey];
    return `${questionData.questionNumber}. ${originalKey}`;
  }

  // console.warn(`⚠️ No exact match found for "${questionTitle}"`);
  return null;
}

/**
 * Retrieves the improvement plan URL for a given best practice title.
 * @param {string} bpTitle - The title of the best practice.
 * @returns {string|null} - The URL of the improvement plan, or null if not found.
 */
export function getImprovementPlanUrl(bpTitle) {
  // Normalize input
  const normalizedBpTitle = bpTitle.trim();

  // Search through all questions and their best practices
  for (const question of Object.values(wafrData)) {
    const matchingBP = question.bestPractices.find(
      bp => bp.title.trim() === normalizedBpTitle
    );

    if (matchingBP) {
      return matchingBP.url;
    }
  }

  // console.warn(`⚠️ No improvement plan URL found for best practice: "${bpTitle}"`);
  return null;
}
