import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Modal,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
  Tooltip,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { countryList, getRegionCode } from "../../utilities/countries";
import { addOrganization } from "../../store/actions";
import { validateAddressWithAPI } from '../../helpers/addressValidation';

const AddOrganizationModal = ({ open, onClose, onSuccess, mspOrgCode }) => {
  const dispatch = useDispatch();
  const { isAddingOrganization, addOrganizationMessage, addOrganizationError } =
    useSelector((state) => state.orgAccount);
  console.log(
    "🚀 ~ AddOrganizationModal ~ isAddingOrganization:",
    isAddingOrganization
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Form State
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [phonenumberError, setPhonenumberError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userProvince, setUserProvince] = useState("");
  const [userZipcode, setUserZipcode] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userComments, setUserComments] = useState("");
  const [noOfAccounts, setNoOfAccounts] = useState("");
  const [noOfUsers, setNoOfUsers] = useState("");
  const [addressValidationStatus, setAddressValidationStatus] = useState(null);
  const [addressValidationMessage, setAddressValidationMessage] = useState('');
  // const [registrationType, setRegistrationType] = useState("");

  // Clear form state
  const clearFormState = () => {
    setUserFirstName("");
    setUserLastName("");
    setUserPhone("");
    setPhonenumberError("");
    setUserEmail("");
    setCompanyName("");
    setUserAddress("");
    setUserCity("");
    setUserProvince("");
    setUserZipcode("");
    setUserCountry("");
    setUserComments("");
    setNoOfAccounts("");
    setNoOfUsers("");
    // setRegistrationType("");
  };

  // Validation function
  const isFormInvalid = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      !userFirstName ||
      !userLastName ||
      !userEmail ||
      !emailRegex.test(userEmail) ||
      !userAddress ||
      !userCountry ||
      addressValidationStatus === 'invalid' ||
      !noOfAccounts ||
      !noOfUsers ||
      (userPhone && !isPossiblePhoneNumber(userPhone))
      // !registrationType
    );
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!isFormInvalid()) {
      const newOrganization = {
        firstName: userFirstName,
        lastName: userLastName,
        phoneNumber: userPhone,
        email: userEmail,
        company: companyName,
        workAddress: userAddress,
        city: userCity,
        province: userProvince,
        zipcode: userZipcode,
        country: userCountry,
        comments: userComments,
        maxusers: noOfUsers,
        maxacounts: noOfAccounts,
        registrationtype: "SUBORG",
        mspOrgCode: mspOrgCode,
      };
      console.log("🚀 ~ handleFormSubmit ~ newOrganization:", newOrganization);
      dispatch(addOrganization(newOrganization));
    }
  };

  // Monitor success and error from Redux state
  useEffect(() => {
    if (addOrganizationMessage) {
      setSnackbarMessage(addOrganizationMessage);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        onClose();
        clearFormState();
        onSuccess();
      }, 5000); // Notify parent and close modal after 2 seconds
    }

    if (addOrganizationError) {
      setSnackbarMessage(
        addOrganizationError.message || "Failed to add organization."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, [addOrganizationMessage, addOrganizationError, onClose, onSuccess]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle phone number validation
  const handlePhoneBlur = () => {
    if (!userPhone || (userPhone && !isPossiblePhoneNumber(userPhone))) {
      setPhonenumberError("Please enter a valid phone number.");
    } else {
      setPhonenumberError("");
    }
  };

  const validateAddress = async () => {
    if (!userAddress.trim() || !userCountry) return;

    setAddressValidationStatus(null);
    setAddressValidationMessage('Validating address...');

    const regionCode = getRegionCode(userCountry);
    if (!regionCode) {
      setAddressValidationStatus('invalid');
      setAddressValidationMessage('Please select a valid country first');
      return;
    }

    try {
      const result = await validateAddressWithAPI(
        userAddress, 
        regionCode, 
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      );

      setAddressValidationStatus(result.isValid ? 'valid' : 'invalid');
      setAddressValidationMessage(result.message);
      
      // Update the address fields with extracted data
      setUserAddress(result.formattedAddress || userAddress);
      setUserCity(result.city);
      setUserProvince(result.state);
      setUserZipcode(result.zip);
    } catch (error) {
      setAddressValidationStatus('invalid');
      setAddressValidationMessage('Failed to validate address. Please try again.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-organization-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="userFirstName"
                value={userFirstName}
                onChange={(e) => setUserFirstName(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="userLastName"
                value={userLastName}
                onChange={(e) => setUserLastName(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PhoneInput
                international
                defaultCountry="US"
                value={userPhone}
                onChange={setUserPhone}
                onBlur={handlePhoneBlur}
                placeholder="Phone Number"
                required
              />
              {phonenumberError && (
                <Typography color="error" variant="caption">
                  {phonenumberError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Company Name"
                name="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Work Email"
                name="userEmail"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                options={countryList}
                getOptionLabel={(option) => option?.name || ''}
                value={countryList.find(country => country.name === userCountry) || null}
                onChange={(event, newValue) => {
                  setUserCountry(newValue?.name || '');
                  setUserAddress('');
                  setAddressValidationStatus(null);
                  setAddressValidationMessage('');
                }}
                isOptionEqualToValue={(option, value) => option.name === value?.name}
                renderInput={(params) => (
                  <TextField {...params} label="Country" required />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.code}>
                    {option.name}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  label="Address"
                  value={userAddress}
                  onChange={(e) => {
                    setUserAddress(e.target.value);
                    setAddressValidationStatus(null);
                    setAddressValidationMessage('');
                  }}
                  onBlur={validateAddress}
                  variant="outlined"
                  required
                  disabled={!userCountry}
                  placeholder={!userCountry ? "Please select a country first" : "Enter complete address"}
                  error={addressValidationStatus === 'invalid'}
                  InputProps={{
                    endAdornment: addressValidationStatus === 'valid' && (
                      <i 
                        className="mdi mdi-check-circle"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          color: 'green'
                        }}
                      />
                    )
                  }}
                />
                {addressValidationMessage && (
                  <Typography 
                    variant="caption" 
                    color={addressValidationStatus === 'valid' ? 'success' : 'error'}
                    sx={{ mt: 0.5, display: 'block' }}
                  >
                    {addressValidationMessage}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of AWS Accounts"
                name="noOfAccounts"
                type="number"
                value={noOfAccounts}
                onChange={(e) => setNoOfAccounts(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of Users"
                name="noOfUsers"
                type="number"
                value={noOfUsers}
                onChange={(e) => setNoOfUsers(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Comments (Optional)"
                name="userComments"
                value={userComments}
                onChange={(e) => setUserComments(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isAddingOrganization || isFormInvalid()}
            >
              {isAddingOrganization ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

AddOrganizationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddOrganizationModal;
