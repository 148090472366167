import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { sidebarMenuOptions } from "./SidebarMenuOptions";
import { getProfileRoleInfo } from "../../store/actions";
import DirtyRedirectCheckHoc from "./../../routes/dirtyRedirectCheckHoc";
import { Box, Typography } from "@mui/material";

const SidebarContent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [sideMenuOptions, setSideMenuOptions] = useState([]);
  const { profile } = useSelector((state) => state);
  const depth = 0;
  const depthStep = 10;

  const makeExpandAll = (menus) => {
    menus.forEach((subMenu) => {
      if (!subMenu.hasOwnProperty("expand")) {
        return;
      }
      if (subMenu.hasOwnProperty("expand")) {
        subMenu.expand = true;
      }
      if (subMenu.subMenu) {
        makeExpandAll(subMenu.subMenu);
      }
    });
    return menus;
  };

  const handleRedirectLink = (e, item = {}) => {
    const isDirty = sessionStorage.getItem("isDirty", "true");
    const targetLink = item.link;
    if (targetLink && !isDirty) {
      e.preventDefault();
      e.stopPropagation();
      history.push(targetLink, { id: item.id, params: item.params });
    }
  };

  const toggleExpandFlag = (menus, item) => {
    menus.forEach((subMenu) => {
      if (subMenu.link === item.link && subMenu.hasOwnProperty("expand")) {
        subMenu.expand = !subMenu.expand;
        //                console.log("Flag flipped")
        return subMenu;
      } else if (subMenu.subMenu) {
        //                console.log("Going in submenu")
        return toggleExpandFlag(subMenu.subMenu, item);
      } else {
        //                console.log("returning as it is")
        return subMenu;
      }
    });
    return menus;
  };

  const toggleSubmenuExpand = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    // console.log("Inside toggelSubmentuExpand")
    // console.log(item)
    if (item.hasOwnProperty("subMenu")) {
      // console.log("Inside modifying flag")
      const menus = [...sideMenuOptions];
      // console.log("Menu before toggle")
      // console.log(menus)
      const modifiedMenu = toggleExpandFlag(menus, item);
      // console.log("Menu after toggle")
      // console.log(modifiedMenu)
      setSideMenuOptions(modifiedMenu);
    }
  };

  const nodeToRootPath = (node, item) => {
    if (node.link === item.link) {
      // console.log("Match found menuChild.link = "+ node.link+ " and item.link = "+item.link)
      const list = [];
      list.push(node);
      return list;
    } else if (node.subMenu) {
      for (const menuItem of node.subMenu) {
        // console.log("Going in submenu "+menuItem.displayName)
        let pathTillClickedMenuItem = nodeToRootPath(menuItem, item);
        if (!pathTillClickedMenuItem) return [];
        else if (pathTillClickedMenuItem.length > 0) {
          pathTillClickedMenuItem.push(node);
          return pathTillClickedMenuItem;
        }
      }
    }
    return [];
  };

  const searchPath = (menus, item) => {
    const pathResult = [];
    let matchPath;
    for (const menuItem of menus) {
      pathResult.push(nodeToRootPath(menuItem, item));
    }
    for (const path of pathResult) {
      if (path.length > 0) {
        matchPath = path;
        break;
      }
    }
    if (!matchPath) {
      item.link = item.link.split("/").slice(0, -1).join("/") + "/";
      const pathResult = [];
      for (const menuItem of menus) {
        pathResult.push(nodeToRootPath(menuItem, item));
      }
      for (const path of pathResult) {
        if (path.length > 0) {
          matchPath = path;
          break;
        }
      }
    }
    return matchPath;
  };

  const makeLinkActive = (e, item) => {
    const menus = sidebarMenuOptions(props.t, handleRedirectLink, profile);
    console.log("==== Menu before making active");
    console.log("menus = ", menus);
    console.log("item = ", item);
    const matchPath = searchPath(menus, item);
    console.log("matchPath : ", matchPath);
    if (matchPath) {
      for (const pathItem of matchPath) {
        if (pathItem.subMenu) {
          pathItem.expand = true;
        } else {
          pathItem.isActive = true;
        }
      }
    }
    // console.log("==== Menu after making active")
    // console.log(menus)
    setSideMenuOptions(menus);

    // To handle rerender and whole page load
    sessionStorage.setItem("menus", JSON.stringify(menus));
  };

  useEffect(() => {
    if (!profile?.roleInfo && !profile.loading) {
      console.log("Calling getProfileRoleInfo() from SidebarContent#useEffect");
      dispatch(getProfileRoleInfo());
    }
  }, []);

  useEffect(() => {
    // console.log(">============ inside useEffect")
    const menusAsString = sessionStorage.getItem("menus");
    // console.log('$$$$$$$$$$$$$$$$$$$ Menus from storage', menusAsString)
    if (menusAsString != null) {
      const menus = JSON.parse(menusAsString);
      if (!props.menuType) {
        // console.log("~~~~~ setting menu for expand true");
        const menus = sidebarMenuOptions(props.t, handleRedirectLink, profile);
        const menuOptionsExpand = makeExpandAll(menus);
        setSideMenuOptions(menuOptionsExpand);
      } else {
        setSideMenuOptions(menus);
      }
    } else {
      if (!props.menuType) {
        // console.log("~~~~~ setting menu for expand true");
        const menus = sidebarMenuOptions(props.t, handleRedirectLink, profile);
        const menuOptionsExpand = makeExpandAll(menus);
        setSideMenuOptions(menuOptionsExpand);
      } else {
        const menuOptions = sidebarMenuOptions(
          props.t,
          handleRedirectLink,
          profile
        );
        setSideMenuOptions(menuOptions);
      }
    }
    // console.log('####### location.pathname = ', location.pathname);
    if (props.menuType) {
      const bareLinkLinkObj = {
        link: location.pathname,
      };
      // console.log("####### bare link object = ", bareLinkLinkObj);
      makeLinkActive(undefined, bareLinkLinkObj);
    }
  }, [profile]); //location removed

  function SidebarItem({ item, depthStep = 10, depth = 0, ...rest }) {
    if (!item.isVisible) return null;

    return (
      <li
        className={item.isActive ? "mm-active" : ""}
        key={new Date().getTime() + item.id}
      >
        <Link
          link={item.link}
          to="#"
          onClick={
            item.subMenu
              ? (e) => toggleSubmenuExpand(e, item)
              : (e) => {
                  makeLinkActive(e, item);
                  e.target.link = item.link;
                  handleRedirectLink(e, item);
                }
          }
          group={item.group}
          className={`${item.classNames} ${item.isActive ? "active" : ""}`}
        >
          <Box className="d-flex flex-row justify-content-between">
            <Box>
              <Typography variant="body2">
                {item.customIcon ? (
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 80 80"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <linearGradient x1="0%" y1="0%" x2="0%" y2="0%" id="a">
                        <stop stop-color="#fff" offset="0%" />
                        <stop stop-color="#fff" offset="0%" />
                      </linearGradient>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                      <path
                        d="M46.594 30h12.813l4.91 9h-3.75l-2.709-4.515A1 1 0 0 0 57 34h-8a1 1 0 0 0-.857.485L45.434 39h-3.749zM39.14 40.51c.181.304.508.49.86.49h6a1 1 0 0 0 .858-.485L49.568 36h6.866l2.71 4.515c.18.301.505.485.856.485h6a1 1 0 0 0 .878-1.479l-6-11A1 1 0 0 0 60 28H46a1 1 0 0 0-.878.521l-6 11a1 1 0 0 0 .018.989M59.407 54H46.594l-4.909-9h3.75l2.708 4.515c.18.301.506.485.857.485h8a1 1 0 0 0 .858-.485L60.568 45h3.748zM66 43h-6a1 1 0 0 0-.857.485L56.434 48h-6.867l-2.709-4.515A1 1 0 0 0 46 43h-6a1.002 1.002 0 0 0-.878 1.479l6 11A1 1 0 0 0 46 56h14a1 1 0 0 0 .878-.521l6-11a1 1 0 0 0-.017-.989 1 1 0 0 0-.86-.49M34.403 68H21.598l-6.45-11.98L21.573 45h12.852l6.428 11.02zm1.462-24.504A1 1 0 0 0 35 43H21c-.355 0-.684.189-.864.496l-7 12a1 1 0 0 0-.017.978l7 13c.175.324.513.526.881.526h14c.368 0 .706-.202.881-.526l7-13a1 1 0 0 0-.016-.978zM31.382 63h-6.764l-3.484-6.969L24.58 50h6.84l3.446 6.031zM32 48h-8a1 1 0 0 0-.868.504l-4 7a1 1 0 0 0-.026.943l4 8A1 1 0 0 0 24 65h8a1 1 0 0 0 .895-.553l4-8a1 1 0 0 0-.027-.943l-4-7A1 1 0 0 0 32 48M19.426 29l5.833 10h-3.685l-5.833-10zm11.956 6 2 4h-5.808l-2.333-4zm5.153-6h3.724l-5.19 8.899-1.914-3.827zM20.136 40.504c.18.307.51.496.864.496h14c.355 0 .685-.188.865-.496l7-12A1.002 1.002 0 0 0 42 27h-6c-.334 0-.646.167-.832.445L31.465 33h-7.39l-3.21-5.504A1 1 0 0 0 20 27h-6a1 1 0 0 0-.864 1.504zM21.574 13h12.852l5.833 10h-3.724l-3.703-5.555A1 1 0 0 0 32 17h-8c-.334 0-.646.167-.832.445L19.465 23h-3.724zM14 25h6a1 1 0 0 0 .832-.445L24.535 19h6.93l3.703 5.555c.186.278.498.445.832.445h6a1.002 1.002 0 0 0 .865-1.504l-7-12A1 1 0 0 0 35 11H21c-.355 0-.684.189-.864.496l-7 12A1 1 0 0 0 14 25"
                        fill="#193A6F"
                      />
                    </g>
                  </svg>
                ) : (
                  <i className={item.iconClassName}></i>
                )}

                <span className="ml-1">{item.displayName}</span>
              </Typography>
            </Box>
            {item.subMenu && (
              <i
                className={
                  item.expand ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                }
              ></i>
            )}
          </Box>
        </Link>

        {item.expand && item.subMenu && (
          <ul
            className="metismenu list-unstyled pl-2"
            id="side-menu"
            style={{ backgroundColor: "#e6e6e6" }}
          >
            {item.subMenu.map((entity, index) => (
              <SidebarItem
                key={`${entity.displayName}${index}`}
                depthStep={depthStep}
                depth={depth}
                item={entity}
              />
            ))}
          </ul>
        )}
      </li>
    );
  }

  return (
    <React.Fragment>
      <DirtyRedirectCheckHoc isParentElement={true}>
        <Box id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sideMenuOptions &&
              sideMenuOptions.map((item, index) => (
                <SidebarItem
                  key={`${item.displayName}${index}`}
                  depthStep={depthStep}
                  depth={depth}
                  item={item}
                />
              ))}
          </ul>
        </Box>
      </DirtyRedirectCheckHoc>
    </React.Fragment>
  );
};

export default withNamespaces()(SidebarContent);
