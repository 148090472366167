import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Tooltip,
  IconButton,
  Popover,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  getCisPosture,
  postCisScan,
  clearAllCisMessages,
  postCisPosture,
} from "../../store/cisWafr/cisWafrSlice";
import {
  useAccounts,
  useOrganization,
} from "../../components/Common/HelperComponents/GetDataFromStore";
import { GetApp, Visibility } from "@material-ui/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import jwt_decode from "jwt-decode";

const CisWAFR = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState({});

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Function to handle opening the menu for a specific row
  const handleMenuOpen = (event, rowId) => {
    event.stopPropagation(); // Prevent row click event
    setMenuAnchorEl((prev) => ({
      ...prev,
      [rowId]: event.currentTarget,
    }));
  };

  // Function to handle closing the menu for a specific row
  const handleMenuClose = (rowId) => {
    setMenuAnchorEl((prev) => ({
      ...prev,
      [rowId]: null,
    }));
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const organization = useOrganization();
  const orgCode = organization?.orgcode;
  const accounts = useAccounts();

  const [isAccountConfigured, setIsAccountConfigured] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);

  // Get API response state from Redux
  const {
    cisPosture,
    isGettingCisPosture,
    getCisPostureError,
    postCisScanMessage,
    postCisScanError,
    isPostingCisScan,
    postCisPostureMessage,
    postCisPostureError,
  } = useSelector((state) => state.cisWafr);
  console.log("🚀 ~ CisWAFR ~ postCisScanError:", postCisScanError);
  console.log("🚀 ~ CisWAFR ~ postCisScanMessage:", postCisScanMessage);

  // Add state to track which account is being processed
  const [processingAccount, setProcessingAccount] = useState(null);

  // Add state for column visibility
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const saved = localStorage.getItem("cisWafrGridColumns");
    return saved
      ? JSON.parse(saved)
      : {
          cisStandard: true,
          accountNumber: true,
          controlsIds: true,
          controlStatus: true,
          status: true,
          lastRunDate: true,
          actions: true,
        };
  });

  // Save column visibility to localStorage when it changes
  useEffect(() => {
    if (columnVisibility) {
      localStorage.setItem(
        "cisWafrGridColumns",
        JSON.stringify(columnVisibility)
      );
    }
  }, [columnVisibility]);

  const handleDownloadReport = (reportType, row) => {
    console.log("In DOwnload report :  ", row);
    if (!row || row["reportStatus"] !== "COMPLETE") return;

    const payload = {
      orgCode,
      accountCode: row["accountCode"],
      lastRunDate: new Date(
        Date.parse(row["lastRunDate"].replace(" ", "T") + "Z")
      ),
      reportType,
    };
    console.log("🚀 ~ handleDownloadReport ~ payload:", payload);

    dispatch(postCisPosture(payload));
    handleMenuClose(row.id);
  };

  useEffect(() => {
    if (orgCode) {
      dispatch(getCisPosture({ orgCode }));
    }
  }, [dispatch, orgCode]);

  useEffect(() => {
    let accCodes;
    let accountNumbers = [];
    let userInfo;
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      userInfo = jwt_decode(obj.idToken).email;
    }

    if (accounts) {
      accCodes = accounts
        .filter(
          (account) =>
            account.status === "ACTIVE" &&
            account.listusr?.some((user) => user.userName === userInfo)
        )
        .map((account) => account.accountCode);
      accounts.map((account) => accountNumbers.push(account.accountNumber));
      // console.log("accCodes: ", accCodes);
      if (accCodes.length > 0) {
        if (isAccountConfigured === null) {
          setIsAccountConfigured(true);
          setAccountStatus("ACTIVE");
        }
      } else if (isAccountConfigured === null) {
        setIsAccountConfigured(false);
        const inactiveAccounts = accounts.filter(
          (account) =>
            account.status === "INACTIVE" && account.number !== "INIT00000001"
        );
        if (inactiveAccounts.length > 0) {
          setAccountStatus("INACTIVE");
        } else {
          setAccountStatus("NOACCOUNT");
        }
      }
    }
  }, [accounts, isAccountConfigured]);

  const handleRetry = () => {
    dispatch(getCisPosture({ orgCode }));
  };

  useEffect(() => {
    // Show notification first
    if (postCisScanMessage) {
      setSnackbar({
        open: true,
        message: postCisScanMessage,
        severity: "success",
        autoHideDuration: 10000,
      });

      // Refresh data after successful scan
      if (orgCode) {
        dispatch(getCisPosture({ orgCode }));
      }
    }

    if (postCisScanError) {
      setSnackbar({
        open: true,
        message: postCisScanError,
        severity: "error",
        autoHideDuration: 10000,
      });
    }

    if (postCisPostureMessage) {
      setSnackbar({
        open: true,
        message: "Report downloaded successfully.",
        severity: "success",
        autoHideDuration: 10000,
      });

      // Automatically trigger file download
      const downloadLink = document.createElement("a");
      downloadLink.href = postCisPostureMessage; // AWS pre-signed URL
      downloadLink.target = "_blank"; // Opens in a new tab (optional)
      downloadLink.download = ""; // Let the browser decide the file name
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    if (postCisPostureError) {
      setSnackbar({
        open: true,
        message: postCisPostureError,
        severity: "error",
        autoHideDuration: 10000,
      });
    }

    dispatch(clearAllCisMessages());
  }, [
    postCisScanMessage,
    postCisScanError,
    postCisPostureMessage,
    postCisPostureError,
    dispatch,
    orgCode,
  ]);

  const handleRunClick = (accountNumber) => {
    setProcessingAccount(accountNumber); // Set the processing account
    dispatch(postCisScan({ orgCode, accountNumber }));
  };

  // Add effect to clear processing state when scan completes
  useEffect(() => {
    if (!isPostingCisScan) {
      setProcessingAccount(null);
    }
  }, [isPostingCisScan]);

  // Transform cisPosture object into DataGrid-compatible rows
  const rows = React.useMemo(() => {
    if (!cisPosture) return [];

    return Object.keys(cisPosture).map((key) => ({
      id: key,
      ...cisPosture[key],
    }));
  }, [cisPosture]);

  const columns = [
    {
      field: "cisStandard",
      headerName: "CIS Standard",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography
          component="span"
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/CISWAF/${params.row.accountNumber}`, {
              lastRunDate: params.row.lastRunDate,
            });
          }}
          sx={{
            fontWeight: "bold", // Make text bold
            textDecoration: "underline", // Underline by default
            color: "#1976d2",
            cursor: "pointer",
            "&:hover": {
              color: "#1565c0", // Change color slightly on hover
              textDecoration: "underline", // Keep underlining on hover
            },
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "controlsIds",
      headerName: "Assessed Controls",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        if (!params) return "N/A";
        return params.value === null ? "N/A" : params;
      },
    },
    {
      field: "controlStatus",
      headerName: "Control Status",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params || params.value === null) {
          return <Typography>N/A</Typography>;
        }

        const controlStatus = params.value;
        const {
          successes = 0,
          successPercent = 0,
          violations = 0,
          violationPercent = 0,
          errors = 0,
          errorPercent = 0,
        } = controlStatus || {};

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {/* Progress Bars */}
            <Box
              sx={{
                position: "relative",
                width: "150px",
                height: "16px",
                borderRadius: "8px",
                backgroundColor: "#e0e0e0",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Success */}
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: `${successPercent || 0}%`,
                  backgroundColor: "green",
                  zIndex: 1,
                }}
              />
              {successPercent > 0 && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: `${successPercent / 2}%`,
                    color: "white",
                    fontSize: "10px",
                    zIndex: 2,
                    fontWeight: "bold",
                  }}
                >
                  {successPercent}%
                </Typography>
              )}
              {/* Violations */}
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: `${violationPercent || 0}%`,
                  left: `${successPercent || 0}%`,
                  backgroundColor: "orange",
                  zIndex: 1,
                }}
              />
              {violationPercent > 0 && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: `${successPercent + violationPercent / 2}%`,
                    color: "white",
                    fontSize: "10px",
                    zIndex: 2,
                    fontWeight: "bold",
                  }}
                >
                  {violationPercent}%
                </Typography>
              )}
              {/* Errors */}
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: `${errorPercent || 0}%`,
                  left: `${(successPercent || 0) + (violationPercent || 0)}%`,
                  backgroundColor: "red",
                  zIndex: 1,
                }}
              />
              {errorPercent > 0 && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: `${
                      successPercent + violationPercent + errorPercent / 2
                    }%`,
                    color: "white",
                    fontSize: "10px",
                    zIndex: 2,
                    fontWeight: "bold",
                  }}
                >
                  {errorPercent}%
                </Typography>
              )}
            </Box>

            {/* Tooltip */}
            <InfoIcon
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
                setTooltipData({
                  successes,
                  successPercent,
                  violations,
                  violationPercent,
                  errors,
                  errorPercent,
                });
              }}
              onMouseLeave={() => {
                setAnchorEl(null);
                setTooltipData(null);
              }}
              sx={{
                color: "#1976d2",
                fontSize: "20px",
                cursor: "pointer",
                marginLeft: "8px",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const status = params.value;

        let chipProps;

        switch (status?.toUpperCase()) {
          case "COMPLETE":
            chipProps = {
              label: "Completed",
              sx: {
                backgroundColor: "#e8f5e9",
                color: "#2e7d32",
                border: "1px solid #a5d6a7",
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  px: 1,
                },
              },
            };
            break;
          case "INPROGRESS":
          case "IN_PROGRESS":
            chipProps = {
              label: "In Progress",
              sx: {
                backgroundColor: "#fff3e0",
                color: "#ed6c02",
                border: "1px solid #ffb74d",
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  px: 1,
                },
              },
            };
            break;
          default:
            chipProps = {
              label: "N/A",
              sx: {
                backgroundColor: "#f5f5f5",
                color: "#757575",
                border: "1px solid #bdbdbd",
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  px: 1,
                },
              },
            };
        }

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Chip size="small" {...chipProps} />
          </Box>
        );
      },
    },
    {
      field: "lastRunDate",
      headerName: "Last Run Date [UTC]",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const dateStr = typeof params === "string" ? params : params || "";
        if (!dateStr) return "N/A";
        return dateStr.toLocaleString();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box display="flex" gap="8px" justifyContent="center">
          <Tooltip title="Start Assessment">
            <span>
              <IconButton
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRunClick(params.row.accountNumber);
                }}
                disabled={processingAccount === params.row.accountNumber}
              >
                {processingAccount === params.row.accountNumber ? (
                  <CircularProgress size={20} />
                ) : (
                  <PlayArrowIcon sx={{ color: "#1976d2", fontSize: "24px" }} />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="View Details">
            <IconButton
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/CISWAF/${params.row.accountNumber}`, {
                  lastRunDate: params.row.lastRunDate,
                });
              }}
            >
              <Visibility sx={{ color: "#1976d2", fontSize: "24px" }} />
            </IconButton>
          </Tooltip>
          <Box>
            <IconButton
              onClick={(event) => handleMenuOpen(event, params.row.id)}
              sx={{
                padding: 0,
                color: "#1976d2",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl[params.row.id]}
              open={Boolean(menuAnchorEl[params.row.id])}
              onClose={() => handleMenuClose(params.row.id)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  minWidth: "200px",
                },
                "& .MuiMenuItem-root": {
                  padding: "10px 16px",
                  gap: "12px",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                },
              }}
            >
              <Tooltip
                title={
                  params.row?.reportStatus === "IN_PROGRESS"
                    ? "Report is not available yet. Please wait until processing completes."
                    : ""
                }
                arrow
              >
                <span>
                  <MenuItem
                    onClick={() => {
                      console.log("Row in MenuItem onClick:", params.row);
                      handleDownloadReport("Summary", params.row);
                    }}
                    sx={{
                      color: "#1976d2",
                      fontSize: "14px",
                    }}
                    disabled={params.row?.reportStatus === "IN_PROGRESS"}
                  >
                    {console.log(
                      "🚀 ~ WorkloadListPage ~ row:",
                      params.row?.reportStatus
                    )}
                    <ListItemIcon sx={{ color: "#1976d2" }}>
                      <GetApp sx={{ color: "#1976d2" }} />
                    </ListItemIcon>
                    Download Summary Report
                  </MenuItem>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  params.row?.reportStatus === "IN_PROGRESS"
                    ? "Report is not available yet. Please wait until processing completes."
                    : ""
                }
                arrow
              >
                <span>
                  <MenuItem
                    onClick={() => {
                      console.log("Row in MenuItem onClick:", params.row);
                      handleDownloadReport("Details", params.row);
                    }}
                    sx={{
                      color: "#1976d2",
                      fontSize: "14px",
                    }}
                    disabled={params.row?.reportStatus === "IN_PROGRESS"}
                  >
                    {console.log(
                      "🚀 ~ WorkloadListPage ~ row:",
                      params.row?.reportStatus
                    )}
                    <ListItemIcon sx={{ color: "#1976d2" }}>
                      <GetApp sx={{ color: "#1976d2" }} />
                    </ListItemIcon>
                    Download Detailed Report
                  </MenuItem>
                </span>
              </Tooltip>
            </Menu>
          </Box>
        </Box>
      ),
    },
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        p: 2,
        borderBottom: "1px solid #e0e0e0",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {/* Left Side - Search */}
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput.split(",").map((value) => value.trim())
        }
        placeholder="Search..."
        sx={{
          width: 250,
          "& .MuiInputBase-root": {
            borderRadius: "8px",
            backgroundColor: "#F1F1F1",
            height: 36,
          },
        }}
        debounceMs={300}
      />

      {/* Right Side - Manage Columns Button */}
      <Box>
        <GridToolbarColumnsButton
          sx={{
            textTransform: "none",
            color: "#1976d2",
            border: "1px solid #1976d2",
            borderRadius: "4px",
            height: 36,
            "&:hover": {
              backgroundColor: "#e3f2fd",
              borderColor: "#1565c0",
            },
            "& .MuiSvgIcon-root": {
              marginRight: 1,
            },
          }}
        />
      </Box>
    </GridToolbarContainer>
  );

  // Render account status message
  const renderAccountMessage = () => {
    if (isAccountConfigured === null || isAccountConfigured) return null;

    const messageContent =
      accountStatus === "INACTIVE" ? (
        <>
          All the AWS accounts in this organization are inactive. Please add a
          new AWS account or activate the inactive accounts through the{" "}
          <Typography
            component="span"
            onClick={() => history.push("/configuration/accounts")}
            sx={{
              color: "primary.main",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            configuration
          </Typography>{" "}
          menu on the left.
        </>
      ) : (
        <>
          This organization does not have any AWS account on-boarded. Please add
          an AWS account through{" "}
          <Typography
            component="span"
            onClick={() => history.push("/configuration/accounts")}
            sx={{
              color: "primary.main",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            configuration
          </Typography>{" "}
          menu on the left.
        </>
      );

    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "grey.100",
          p: 3,
        }}
      >
        <Box
          sx={{
            maxWidth: "600px",
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 1,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "text.primary",
              mb: 2,
              fontWeight: 500,
            }}
          >
            Account Configuration Required
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
              lineHeight: 1.6,
            }}
          >
            {messageContent}
          </Typography>
        </Box>
      </Box>
    );
  };

  // Early return if account is not configured
  if (!isAccountConfigured) {
    return renderAccountMessage();
  }

  return (
    <div className="page-conent">
      <Box
        sx={{
          padding: "1.5rem 2rem",
          mt: "5rem",
          backgroundColor: "#F9F9F9",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" fontWeight="bold" color="#333">
            CIS WAFR Assessment
          </Typography>
        </Box>

        {isGettingCisPosture ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : getCisPostureError ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: "50vh",
              backgroundColor: "#fef8f8",
              border: "1px solid #f44336",
              borderRadius: "8px",
              padding: "2rem",
              textAlign: "center",
            }}
          >
            <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
            <Typography
              variant="h6"
              fontWeight="bold"
              color="#f44336"
              sx={{ mb: 1 }}
            >
              Something Went Wrong
            </Typography>
            <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
              {getCisPostureError}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={handleRetry}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderColor: "#1976d2",
                color: "#1976d2",
                "&:hover": {
                  borderColor: "#1565c0",
                  backgroundColor: "#e3f2fd",
                },
              }}
            >
              Retry
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              overflow: "hidden",
            }}
          >
            <DataGrid
              rows={rows || []}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              slots={{
                toolbar: CustomToolbar,
              }}
              lotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              loading={isGettingCisPosture}
              error={getCisPostureError}
              rowHeight={40}
              initialState={{
                columns: {
                  columnVisibilityModel: columnVisibility,
                },
              }}
              onColumnVisibilityModelChange={(newModel) => {
                setColumnVisibility(newModel);
              }}
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold", // Make individual header title bold
                },
                "& .MuiDataGrid-cell": {
                  py: 0.5,
                  fontSize: "14px",
                  fontFamily: "Arial, sans-serif",
                  textAlign: "center",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F5F5F5",
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#333",
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            />
          </Box>
        )}

        {/* Tooltip Popover */}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            pointerEvents: "none",
            "& .MuiPopover-paper": {
              padding: "8px",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          {tooltipData && (
            <Box>
              <Typography variant="body2" color="green" fontWeight="bold">
                Successes: {tooltipData.successes} (
                {tooltipData.successPercent || 0}%)
              </Typography>
              <Typography variant="body2" color="orange" fontWeight="bold">
                Violations: {tooltipData.violations} (
                {tooltipData.violationPercent || 0}%)
              </Typography>
            </Box>
          )}
        </Popover>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default CisWAFR;
