import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  LinearProgress,
  IconButton,
  Chip,
  Tooltip,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import {
  clearAllWafrQuestionnaireMessages,
  getAssessmentList,
  postAutomatedChecks,
  postCompleteAssessment,
  deleteAssessment,
} from "../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import CreateAssessmentModal from "./components/CreateAssessmentModal";
import {
  useAccounts,
  useOrganization,
  useUsers,
} from "../../components/Common/HelperComponents/GetDataFromStore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TimelineIcon from "@mui/icons-material/Timeline";
import { isAuditor } from "../../helpers/jwt-token-access/isAuditor";
import CompleteAssessmentDialog from "./components/CompleteAssessmentDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ProcessGuidePanel from "./components/ProcessGuidePanel";
import { formatUtcDate } from "../../helpers/formatUtcDate";
import jwt_decode from "jwt-decode";
import DeleteIcon from "@mui/icons-material/Delete";

const WorkloadListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const accounts = useAccounts();
  const organization = useOrganization();

  const [isAccountConfigured, setIsAccountConfigured] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  console.log("~~~organization : ", organization);

  // Selectors to access data from the Redux store
  const { assessmentList, isGettingAssessmentList, getAssessmentListError } =
    useSelector((state) => state.wafrQuestionnaire);
  const { postWafrAssessmentMessage, postWafrAssessmentError } = useSelector(
    (state) => state.wafrQuestionnaire
  );

  const { postAutomatedChecksMessage, postAutomatedChecksError } = useSelector(
    (state) => state.wafrQuestionnaire
  );

  const { deleteAssessmentMessage, deleteAssessmentError } = useSelector(
    (state) => state.wafrQuestionnaire
  );

  const {
    postCompleteAssessmentMessage,
    postCompleteAssessmentError,
    isPostingCompleteAssessment,
  } = useSelector((state) => state.wafrQuestionnaire);

  const { isDeletingAssessment } = useSelector((state) => state.wafrQuestionnaire);

  console.log(
    "🚀 ~ WorkloadListPage ~ postCompleteAssessmentMessage:",
    postCompleteAssessmentMessage
  );
  console.log(
    "🚀 ~ WorkloadListPage ~ postCompleteAssessmentError:",
    postCompleteAssessmentError
  );

  const { usersLoading, users } = useUsers();

  const [menuAnchorEl, setMenuAnchorEl] = useState({});
  const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
  const [selectedWorkloadToComplete, setSelectedWorkloadToComplete] =
    useState(null);

  const [guideOpen, setGuideOpen] = useState(false);

  const [selectedWorkloadToDelete, setSelectedWorkloadToDelete] =
    useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Add state for column visibility
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const saved = localStorage.getItem("workloadAssessmentGridColumns");
    return saved
      ? JSON.parse(saved)
      : {
          "Account Number": true,
          "Assessment Name": true,
          "Progress percentage": true,
          "Assessment Approver": true,
          "Assessment Owner": true,
          Status: true,
          "Framework(s)": false,
          "Workload ID": false,
          lastModifiedDate: false,
          lastModifiedBy: false,
          createdDate: false,
          createdBy: false,
          LastAutomatedCheckDate: false,
          LastAutomatedCheckStatus: true,
          WorkloadArn: false,
          PrimaryRegion: false,
          Regions: false,
        };
  });

  // Save column visibility to localStorage when it changes
  useEffect(() => {
    if (columnVisibility) {
      localStorage.setItem(
        "workloadAssessmentGridColumns",
        JSON.stringify(columnVisibility)
      );
    }
  }, [columnVisibility]);

  // Function to handle opening the menu for a specific row
  const handleMenuOpen = (event, rowId) => {
    event.stopPropagation(); // Prevent row click event
    setMenuAnchorEl((prev) => ({
      ...prev,
      [rowId]: event.currentTarget,
    }));
  };

  // Function to handle closing the menu for a specific row
  const handleMenuClose = (rowId) => {
    setMenuAnchorEl((prev) => ({
      ...prev,
      [rowId]: null,
    }));
  };

  // Function to handle the "Run Automated Checks" action
  const handleRunAutomatedChecks = (row) => {
    console.log("Run Automated Checks for row:", row);

    // Prepare payload from row data
    const payload = {
      accountNumber: row["Account Number"], // Adjust field names as per your row data
      workloadId: row["Workload ID"], // Adjust field names as per your row data
    };

    console.log("Running Automated Checks with payload:", payload);

    dispatch(postAutomatedChecks(payload));

    handleMenuClose(row.id);
  };

  // Function to handle the "Complete Assessment" action
  const handleCompleteAssessment = (row) => {
    setSelectedWorkloadToComplete({
      accountNumber: row["Account Number"],
      workLoadId: row["Workload ID"],
      assessmentName: row["Assessment Name"],
    });
    setCompleteDialogOpen(true);
    handleMenuClose(row.id);
  };

  // Add confirmation handler
  const handleConfirmComplete = () => {
    if (selectedWorkloadToComplete) {
      dispatch(
        postCompleteAssessment({
          accountNumber: selectedWorkloadToComplete.accountNumber,
          workLoadId: selectedWorkloadToComplete.workLoadId,
        })
      );
    }
  };

  // Function to handle the "Milestones" action
  const handleMilestonesClick = (row) => {
    console.log("🚀 ~ handleMilestonesClick ~ row:", row.Status);
    history.push(`/workload-assessment/milestones`, {
      workloadId: row["Workload ID"],
      orgCode: row["OrgCode"],
      accCode: row["Account Code"],
      accNumber: row["Account Number"],
      assessmentName: row["Assessment Name"],
      frameworkName: row["Framework(s)"],
      ownerName: row["Assessment Owner"],
      approverName: row["Assessment Approver"],
      status: row["Status"],
    });
  };

  // Add handler for delete action
  const handleDeleteAssessment = (row) => {
    setSelectedWorkloadToDelete({
      workloadId: row["Workload ID"],
      assessmentName: row["Assessment Name"],
    });
    setDeleteDialogOpen(true);
    handleMenuClose(row.id);
  };

  // Add confirmation handler for delete
  const handleConfirmDelete = () => {
    if (selectedWorkloadToDelete) {
      dispatch(
        deleteAssessment({
          workloadId: selectedWorkloadToDelete.workloadId,
        })
      );
    }
  };

  useEffect(() => {
    let accCodes;
    let accountNumbers = [];
    let userInfo;
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      userInfo = jwt_decode(obj.idToken).email;
    }

    if (accounts) {
      accCodes = accounts
        .filter(
          (account) =>
            account.status === "ACTIVE" &&
            account.listusr?.some((user) => user.userName === userInfo)
        )
        .map((account) => account.accountCode);
      accounts.map((account) => accountNumbers.push(account.accountNumber));
      // console.log("accCodes: ", accCodes);
      if (accCodes.length > 0) {
        if (isAccountConfigured === null) {
          setIsAccountConfigured(true);
          setAccountStatus("ACTIVE");
        }
      } else if (isAccountConfigured === null) {
        setIsAccountConfigured(false);
        const inactiveAccounts = accounts.filter(
          (account) =>
            account.status === "INACTIVE" && account.number !== "INIT00000001"
        );
        if (inactiveAccounts.length > 0) {
          setAccountStatus("INACTIVE");
        } else {
          setAccountStatus("NOACCOUNT");
        }
      }
    }
  }, [accounts, isAccountConfigured]);

  // Fetch the assessment list when the component mounts
  useEffect(() => {
    // Only fetch if account is configured
    if (isAccountConfigured) {
      dispatch(getAssessmentList());
    }
  }, [dispatch, isAccountConfigured]);

  useEffect(() => {
    if (postWafrAssessmentMessage) {
      // Show success notification
      setSnackbar({
        open: true,
        message: postWafrAssessmentMessage,
        severity: "success",
        autoHideDuration: 10000,
      });

      // Close the modal
      setIsModalOpen(false);

      // Refresh the DataGrid by re-dispatching getAssessmentList
      dispatch(getAssessmentList());
      console.log("postWafrAssessmentMessage = ", postWafrAssessmentMessage);
    }
    if (postWafrAssessmentError) {
      setSnackbar({
        open: true,
        message: postWafrAssessmentError,
        severity: "error",
        autoHideDuration: 10000,
      });
      console.log("postWafrAssessmentError = ", postWafrAssessmentError);
    }

    if (postAutomatedChecksMessage) {
      // Show success notification
      console.log("postAutomatedChecksMessage = ", postAutomatedChecksMessage);
      setSnackbar({
        open: true,
        message: postAutomatedChecksMessage,
        severity: "success",
        autoHideDuration: 10000,
      });
      dispatch(getAssessmentList());
    }

    if (postAutomatedChecksError) {
      console.log("postAutomatedChecksError = ", postAutomatedChecksError);
      setSnackbar({
        open: true,
        message: postAutomatedChecksError,
        severity: "error",
        autoHideDuration: 10000,
      });
    }

    if (postCompleteAssessmentMessage) {
      // Show success notification
      console.log(
        "postCompleteAssessmentMessage = ",
        postCompleteAssessmentMessage
      );
      setSnackbar({
        open: true,
        message: postCompleteAssessmentMessage,
        severity: "success",
        autoHideDuration: 10000,
      });
      setCompleteDialogOpen(false);
      dispatch(getAssessmentList());
    }

    if (postCompleteAssessmentError) {
      console.log(
        "postCompleteAssessmentError = ",
        postCompleteAssessmentError
      );
      setSnackbar({
        open: true,
        message: postCompleteAssessmentError,
        severity: "error",
        autoHideDuration: 10000,
      });
    }

    if (deleteAssessmentMessage) {
      console.log(
        "🚀 ~ useEffect ~ deleteAssessmentMessage:",
        deleteAssessmentMessage
      );
      setSnackbar({
        open: true,
        message: deleteAssessmentMessage,
        severity: "success",
        autoHideDuration: 10000,
      });
      setDeleteDialogOpen(false);
      dispatch(getAssessmentList());
    }

    if (deleteAssessmentError) {
      console.log(
        "🚀 ~ useEffect ~ deleteAssessmentError:",
        deleteAssessmentError
      );
      setSnackbar({
        open: true,
        message: deleteAssessmentError,
        severity: "error",
        autoHideDuration: 10000,
      });
    }

    if (
      postWafrAssessmentMessage ||
      postWafrAssessmentError ||
      postAutomatedChecksMessage ||
      postAutomatedChecksError ||
      postCompleteAssessmentMessage ||
      postCompleteAssessmentError ||
      assessmentList ||
      getAssessmentListError ||
      deleteAssessmentMessage ||
      deleteAssessmentError
    ) {
      dispatch(clearAllWafrQuestionnaireMessages());
    }
  }, [
    postWafrAssessmentMessage,
    postWafrAssessmentError,
    postAutomatedChecksMessage,
    postAutomatedChecksError,
    postCompleteAssessmentMessage,
    postCompleteAssessmentError,
    assessmentList,
    getAssessmentListError,
    deleteAssessmentMessage,
    deleteAssessmentError,
    dispatch,
  ]);

  // Define DataGrid Columns
  const columns = [
    {
      field: "Assessment Name",
      headerName: "Assessment Name",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Account Number",
      headerName: "Account Number",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Progress percentage",
      headerName: "Progress",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          sx={{ py: 1 }}
        >
          <Box sx={{ flex: 1, mr: 1, maxWidth: "80%" }}>
            <LinearProgress
              variant="determinate"
              value={params.value}
              sx={{
                width: "100%",
                height: 8,
                borderRadius: 4,
                backgroundColor: "#e0e0e0",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#1976d2",
                },
              }}
            />
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "500",
              color: "#555",
              minWidth: "35px",
            }}
          >
            {params.value}%
          </Typography>
        </Box>
      ),
    },
    {
      field: "Assessment Approver",
      headerName: "Assessment Approver",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Assessment Owner",
      headerName: "Assessment Owner",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Framework(s)",
      headerName: "Framework(s)",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 200,
      valueGetter: (params) => formatUtcDate(params),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lastModifiedDate",
      headerName: "Last Modified Date",
      width: 200,
      valueGetter: (params) => formatUtcDate(params),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lastModifiedBy",
      headerName: "Last Modified By",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Workload ID",
      headerName: "Workload ID",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "WorkloadArn",
      headerName: "Workload Arn",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "PrimaryRegion",
      headerName: "Primary Region",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.value ? (
          <Chip
            label={params.value}
            size="small"
            sx={{
              bgcolor: "#E3F2FD",
              borderColor: "#1976D2",
              "& .MuiChip-label": {
                color: "#1976D2",
                fontWeight: 500,
              },
            }}
          />
        ) : (
          "-"
        ),
    },
    {
      field: "Regions",
      headerName: "Regions",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.value) return "-";

        const regions = params.value.split(",").filter(Boolean);
        const isCollapsed = params.colDef.width < 250; // Adjust this threshold as needed

        return (
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              py: 0.5,
            }}
          >
            {isCollapsed ? (
              <Tooltip
                title={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      maxWidth: 300,
                      maxHeight: 200,
                      overflowY: "auto",
                      p: 2,
                      backdropFilter: "blur(8px)",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    {regions.map((region, index) => (
                      <Chip
                        key={index}
                        label={region.trim()}
                        size="small"
                        sx={{
                          backgroundColor: "#e3f2fd",
                          color: "#1976d2",
                          fontSize: "0.75rem",
                          height: "24px",
                          maxWidth: "100%",
                          ".MuiChip-label": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                    ))}
                  </Box>
                }
                arrow
                placement="top"
              >
                <Chip
                  label={`${regions.length} region${
                    regions.length !== 1 ? "s" : ""
                  }`}
                  size="small"
                  sx={{
                    backgroundColor: "#e3f2fd",
                    color: "#1976d2",
                    fontSize: "0.75rem",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            ) : (
              regions.map((region, index) => (
                <Chip
                  key={index}
                  label={region.trim()}
                  size="small"
                  sx={{
                    backgroundColor: "#e3f2fd",
                    color: "#1976d2",
                    fontSize: "0.75rem",
                    height: "24px",
                    maxWidth: "95%",
                    ".MuiChip-label": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              ))
            )}
          </Box>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const row = params.row;
        const rowId = params.row.id;

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            {/* View Button */}
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                history.push(
                  `/workload-assessment/${params.row["Workload ID"]}`,
                  {
                    workloadId: params.row["Workload ID"],
                    orgCode: params.row["OrgCode"],
                    accCode: params.row["Account Code"],
                    accNumber: params.row["Account Number"],
                    assessmentName: params.row["Assessment Name"],
                    frameworkName: params.row["Framework(s)"],
                    ownerName: params.row["Assessment Owner"],
                    approverName: params.row["Assessment Approver"],
                    status: params.row["Status"],
                    isReadOnly: params.row["Status"] === "COMPLETED",
                  }
                )
              }
              sx={{
                textTransform: "none",
                fontWeight: 500,
                borderRadius: "20px",
                padding: "2px 12px",
                borderColor: "#1976d2",
                color: "#1976d2",
                "&:hover": {
                  backgroundColor: "#E3F2FD",
                  borderColor: "#1565c0",
                },
              }}
            >
              View
            </Button>

            {/* MoreVert Icon with Menu */}
            <Box>
              <IconButton
                onClick={(event) => handleMenuOpen(event, rowId)}
                sx={{
                  padding: 0,
                  color: "#1976d2",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl[rowId]}
                open={Boolean(menuAnchorEl[rowId])}
                onClose={() => handleMenuClose(rowId)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    minWidth: "200px",
                  },
                  "& .MuiMenuItem-root": {
                    padding: "10px 16px",
                    gap: "12px",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  },
                }}
              >
                <Tooltip
                  title={
                    !isAuditor()
                      ? "Only Auditors have access to this feature"
                      : ""
                  }
                  arrow
                >
                  <span>
                    <MenuItem
                      onClick={() => handleRunAutomatedChecks(row)}
                      sx={{
                        color: "#1976d2",
                        fontSize: "14px",
                      }}
                      disabled={!isAuditor() || row.Status === "COMPLETED"}
                    >
                      {console.log("🚀 ~ WorkloadListPage ~ row:", row.Status)}
                      <ListItemIcon>
                        <PlayArrowIcon sx={{ color: "#1976d2" }} />
                      </ListItemIcon>
                      Run Automated Checks
                    </MenuItem>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    !isAuditor()
                      ? "Only Auditors have access to this feature"
                      : ""
                  }
                  arrow
                >
                  <span>
                    <MenuItem
                      onClick={() => handleCompleteAssessment(row)}
                      sx={{
                        color: "#2e7d32",
                        fontSize: "14px",
                      }}
                      disabled={!isAuditor() || row.Status === "COMPLETED"}
                    >
                      <ListItemIcon>
                        <DoneAllIcon sx={{ color: "#2e7d32" }} />
                      </ListItemIcon>
                      Complete Assessment
                    </MenuItem>
                  </span>
                </Tooltip>
                <MenuItem
                  onClick={() => {
                    handleMenuClose(rowId);
                    handleMilestonesClick(row);
                  }}
                  sx={{
                    color: "#0288d1",
                    fontSize: "14px",
                  }}
                >
                  <ListItemIcon>
                    <TimelineIcon sx={{ color: "#0288d1" }} />
                  </ListItemIcon>
                  Milestones
                </MenuItem>
                <MenuItem
                  onClick={() => handleDeleteAssessment(row)}
                  sx={{
                    color: "error.main",
                    fontSize: "14px",
                  }}
                  disabled={!isAuditor()}
                >
                  <ListItemIcon>
                    <DeleteIcon sx={{ color: "error.main" }} />
                  </ListItemIcon>
                  Delete Assessment
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          {params.value === "COMPLETED" ? (
            <Chip
              label="Completed"
              size="small"
              sx={{
                backgroundColor: "#e8f5e9",
                color: "#2e7d32",
                border: "1px solid #a5d6a7",
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  px: 1,
                },
              }}
            />
          ) : (
            <Chip
              label="In Progress"
              size="small"
              sx={{
                backgroundColor: "#fff3e0",
                color: "#ed6c02",
                border: "1px solid #ffb74d",
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  px: 1,
                },
              }}
            />
          )}
        </Box>
      ),
    },
    {
      field: "LastAutomatedCheckStatus",
      headerName: "Automated Check Status",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const status = params.value;
        switch (status?.toUpperCase()) {
          case "COMPLETED":
            return (
              <Chip
                label="Completed"
                size="small"
                sx={{
                  backgroundColor: "#e8f5e9",
                  color: "#2e7d32",
                  border: "1px solid #a5d6a7",
                  fontSize: "0.75rem",
                  "& .MuiChip-label": {
                    px: 1,
                  },
                }}
              />
            );
          case "IN PROGRESS":
            return (
              <Chip
                label="In Progress"
                size="small"
                sx={{
                  backgroundColor: "#fff3e0",
                  color: "#ed6c02",
                  border: "1px solid #ffb74d",
                  fontSize: "0.75rem",
                  "& .MuiChip-label": {
                    px: 1,
                  },
                }}
              />
            );
          case "FAILED":
            return (
              <Chip
                label="Failed"
                size="small"
                sx={{
                  backgroundColor: "#fef2f2",
                  color: "#d32f2f",
                  border: "1px solid #ef9a9a",
                  fontSize: "0.75rem",
                  "& .MuiChip-label": {
                    px: 1,
                  },
                }}
              />
            );
          default:
            return (
              <Chip
                label="N/A"
                size="small"
                sx={{
                  backgroundColor: "#f5f5f5",
                  color: "#78909c",
                  border: "1px solid #cfd8dc",
                  fontSize: "0.75rem",
                  "& .MuiChip-label": {
                    px: 1,
                  },
                }}
              />
            );
        }
      },
    },
    {
      field: "LastAutomatedCheckDate",
      headerName: "Last Automated Check Date",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => formatUtcDate(params),
    },
  ];

  // Custom Toolbar with Integrated Search
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          p: 2,
          borderBottom: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Left side - Search */}
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput.split(",").map((value) => value.trim())
          }
          placeholder="Search Assessments"
          sx={{
            width: 250,
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              backgroundColor: "#F1F1F1",
              height: 36,
            },
          }}
          debounceMs={300}
        />

        {/* Right side - Built-in Columns Button */}
        <Box>
          <GridToolbarColumnsButton
            sx={{
              textTransform: "none",
              color: "#1976d2",
              border: "1px solid #1976d2",
              borderRadius: "4px",
              height: 36,
              "&:hover": {
                backgroundColor: "#e3f2fd",
                borderColor: "#1565c0",
              },
              "& .MuiSvgIcon-root": {
                marginRight: 1,
              },
            }}
          />
        </Box>
      </GridToolbarContainer>
    );
  }

  const {
    workloadId, // Make sure this is being passed from the previous page
    orgCode,
    accCode,
    accNumber,
    assessmentName,
    frameworkName,
    ownerName,
    approverName,
  } = location.state || {};

  // Render account status message
  const renderAccountMessage = () => {
    if (isAccountConfigured === null || isAccountConfigured) return null;

    const messageContent =
      accountStatus === "INACTIVE" ? (
        <>
          All the AWS accounts in this organization are inactive. Please add a
          new AWS account or activate the inactive accounts through the{" "}
          <Typography
            component="span"
            onClick={() => history.push("/configuration/accounts")}
            sx={{
              color: "primary.main",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            configuration
          </Typography>{" "}
          menu on the left.
        </>
      ) : (
        <>
          This organization does not have any AWS account on-boarded. Please add
          an AWS account through{" "}
          <Typography
            component="span"
            onClick={() => history.push("/configuration/accounts")}
            sx={{
              color: "primary.main",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            configuration
          </Typography>{" "}
          menu on the left.
        </>
      );

    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "grey.100",
          p: 3,
        }}
      >
        <Box
          sx={{
            maxWidth: "600px",
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 1,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "text.primary",
              mb: 2,
              fontWeight: 500,
            }}
          >
            Account Configuration Required
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
              lineHeight: 1.6,
            }}
          >
            {messageContent}
          </Typography>
        </Box>
      </Box>
    );
  };

  // Early return if account is not configured
  if (!isAccountConfigured) {
    return renderAccountMessage();
  }

  return (
    <Box
      sx={{
        padding: "1.5rem 2rem",
        mt: "5rem",
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h5" fontWeight="bold" color="#333">
          Workload Assessments
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Tooltip title="View Assessment Process Guide">
            <IconButton
              onClick={() => setGuideOpen(true)}
              sx={{
                bgcolor: "#e3f2fd",
                "&:hover": { bgcolor: "#bbdefb" },
              }}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsModalOpen(true)}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              backgroundColor: "#1976d2",
              padding: "6px 16px",
              borderRadius: "20px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#1565c0",
                boxShadow: "none",
              },
            }}
            disabled={usersLoading || !isAuditor()}
          >
            Create Assessment
          </Button>
        </Box>
      </Box>

      {/* DataGrid */}
      {isGettingAssessmentList ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : getAssessmentListError ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "50vh",
            backgroundColor: "#fef8f8",
            border: "1px solid #f44336",
            borderRadius: "8px",
            padding: "2rem",
            textAlign: "center",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#f44336"
            sx={{ mb: 1 }}
          >
            Something Went Wrong
          </Typography>
          <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
            {getAssessmentListError}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={() => dispatch(getAssessmentList())}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#1976d2",
              color: "#1976d2",
              "&:hover": {
                borderColor: "#1565c0",
                backgroundColor: "#e3f2fd",
              },
            }}
          >
            Retry
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <DataGrid
            rows={
              assessmentList?.map((row, index) => ({ id: index, ...row })) || []
            }
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: columnVisibility,
              },
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            onColumnVisibilityModelChange={(newModel) => {
              setColumnVisibility(newModel);
            }}
            loading={isGettingAssessmentList}
            rowHeight={40}
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
              "& .MuiDataGrid-cell": {
                py: 0.5,
                fontSize: "14px",
                fontFamily: "Arial, sans-serif",
                textAlign: "center",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#F5F5F5",
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                position: "relative",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "1px solid #e0e0e0",
                backgroundColor: "#F5F5F5",
              },
            }}
          />
        </Box>
      )}

      {/* Modal */}
      {isModalOpen && (
        <CreateAssessmentModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          accounts={accounts?.filter(
            (account) => account.accountNumber !== "INIT00000001"
          )}
          users={users}
          orgCode={organization?.orgcode}
          workloadId={workloadId}
        />
      )}

      {/* Add Delete Dialog */}
      <CompleteAssessmentDialog
        open={deleteDialogOpen}
        onClose={() => {
          if (!isDeletingAssessment) {
            setDeleteDialogOpen(false);
          }
        }}
        onConfirm={handleConfirmDelete}
        isLoading={isDeletingAssessment}
        error={deleteAssessmentError}
        type="delete"
        assessmentName={selectedWorkloadToDelete?.assessmentName}
      />

      {/* Existing Complete Dialog */}
      <CompleteAssessmentDialog
        open={completeDialogOpen}
        onClose={() => {
          if (!isPostingCompleteAssessment) {
            setCompleteDialogOpen(false);
          }
        }}
        onConfirm={handleConfirmComplete}
        isLoading={isPostingCompleteAssessment}
        error={postCompleteAssessmentError}
        type="complete"
        assessmentName={selectedWorkloadToComplete?.assessmentName}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ProcessGuidePanel open={guideOpen} onClose={() => setGuideOpen(false)} />
    </Box>
  );
};

export default WorkloadListPage;
