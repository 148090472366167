import React, { useEffect, useState } from "react";
import { Button, Row, Col, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { countryList, getRegionCode } from "../../utilities/countries";
import { putBillingInfo, putMailingInfo, setMailingInfo } from "../../store/actions";
import { validateAddressWithAPI } from "../../helpers/addressValidation";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";

const UpdateOrgAddress = ({ closeModal, addressType }) => {
  const [isAlertOpen, setAlertOpen] = useState({ status: false });
  const [addressValidationStatus, setAddressValidationStatus] = useState(null);
  const [addressValidationMessage, setAddressValidationMessage] = useState("");
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.orgList);
  const { updateOrgAddress } = useSelector((state) => state.account);
  const org = organizations[0] || {};

  useEffect(() => {
    if (updateOrgAddress && updateOrgAddress.response) {
      const alertMsg = updateOrgAddress.response.data ? updateOrgAddress.response.data : 'Successfully Updated';
      setAlertOpen({ className: 'success', message: alertMsg, status: true });
      setTimeout(() => {
        dispatch(setMailingInfo({}));
        closeModal();
      }, 500);
    } else if (updateOrgAddress && updateOrgAddress.error) {
      setAlertOpen({ className: 'danger', message: "Unable to update", status: true });
      dispatch(setMailingInfo({}));
    }
  }, [updateOrgAddress]);

  const validateAddress = async (address, country) => {
    if (!address.trim() || !country) return;

    setAddressValidationStatus(null);
    setAddressValidationMessage("Validating address...");

    const regionCode = getRegionCode(country);
    if (!regionCode) {
      setAddressValidationStatus("invalid");
      setAddressValidationMessage("Please select a valid country first");
      return;
    }

    try {
      const result = await validateAddressWithAPI(
        address,
        regionCode,
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      );

      setAddressValidationStatus(result.isValid ? "valid" : "invalid");
      setAddressValidationMessage(result.message);
      
      return result;
    } catch (error) {
      setAddressValidationStatus("invalid");
      setAddressValidationMessage("Failed to validate address. Please try again.");
      return null;
    }
  };

  const handleUpdateOrgAddress = async (values) => {
    const validationResult = await validateAddress(values.address, values.country);
    if (!validationResult || !validationResult.isValid) {
      setAlertOpen({
        className: 'danger',
        message: "Please enter a valid address",
        status: true
      });
      return;
    }

    const payload = {
      id: org.id,
      orgcode: org.orgcode,
      orgname: org.orgname,
      orgmetadata: org.orgmetadata,
      orgdescription: org.orgdescription,
      orgprimaryname: org.orgprimaryname,
      orgprimaryemail: org.orgprimaryemail,
      phoneNumber: org.phoneNumber,
      selltoken: "",
      resourceGroups: [],
      user: {},
    };

    if (addressType === 'mailing') {
      dispatch(putMailingInfo({
        ...payload,
        orgaddress: validationResult.formattedAddress || values.address,
        orgcity: validationResult.city,
        orgstate: validationResult.state,
        orgzip: validationResult.zip,
        orgcountry: values.country,
        orgbillingname: "",
        orgbillingemail: "",
        orgbillingaddress: "",
        orgbillingcity: "",
        orgbillingstate: "",
        orgbillingcountry: "",
        orgbillingzip: "",
      }));
    } else {
      dispatch(putBillingInfo({
        ...payload,
        orgaddress: "",
        orgcity: "",
        orgstate: "",
        orgcountry: "",
        orgzip: "",
        orgbillingname: org.orgbillingname,
        orgbillingemail: org.orgbillingemail,
        orgbillingaddress: validationResult.formattedAddress || values.address,
        orgbillingcity: validationResult.city,
        orgbillingstate: validationResult.state,
        orgbillingzip: validationResult.zip,
        orgbillingcountry: values.country,
      }));
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={addressType === 'mailing' ? {
          address: org.orgaddress || "",
          country: org.orgcountry || ""
        } : {
          address: org.orgbillingaddress || "",
          country: org.orgbillingcountry || ""
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            <Alert
              color={isAlertOpen.className}
              isOpen={isAlertOpen.status}
              toggle={() => setAlertOpen({ status: false })}
            >
              {isAlertOpen.message}
            </Alert>
            <Row className="address-row" style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
              <Col lg="6" style={{ display: "flex", alignItems: "center" }}>
                <FormControl fullWidth>
                  <InputLabel id="country-select-label">Country *</InputLabel>
                  <Select
                    name="country"
                    label="Country"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    labelId="country-select-label"
                    value={values.country}
                    onChange={(e) => {
                      setFieldValue("country", e.target.value);
                      setFieldValue("address", "");
                      setAddressValidationStatus(null);
                      setAddressValidationMessage("");
                    }}
                    style={{ height: "40px" }}
                  >
                    {countryList.map((country) => (
                      <MenuItem key={country.code} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col lg="6" style={{ display: "flex", alignItems: "center" }}>
                <FormControl fullWidth>
                  <TextField
                    name="address"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    label="Address *"
                    value={values.address}
                    placeholder={!values.country ? "Please select a country first" : "Enter complete address"}
                    type="text"
                    onChange={(e) => {
                      setFieldValue("address", e.target.value);
                      setAddressValidationStatus(null);
                      setAddressValidationMessage("");
                    }}
                    onBlur={() => validateAddress(values.address, values.country)}
                    error={addressValidationStatus === "invalid"}
                    disabled={!values.country}
                    style={{ height: "40px" }}
                    InputProps={{
                      endAdornment: addressValidationStatus === "valid" && (
                        <i
                          className="mdi mdi-check-circle"
                          style={{
                            position: "absolute",
                            right: "10px",
                            color: "green",
                          }}
                        />
                      ),
                    }}
                  />
                </FormControl>
              </Col>
            </Row>
            {addressValidationMessage && (
              <Typography
                variant="caption"
                color={addressValidationStatus === "valid" ? "success" : "error"}
                sx={{ mt: 0.5, display: "block" }}
              >
                {addressValidationMessage}
              </Typography>
            )}
            <div className="row mt-3">
              <div className="offset-md-8 col-md-4 col-sm-12">
                <div className="d-flex justify-content-end">
                  <Button
                    type="button"
                    className="m-2"
                    color="light"
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="m-2"
                    color="primary"
                    disabled={addressValidationStatus !== "valid"}
                    onClick={() => handleUpdateOrgAddress(values)}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UpdateOrgAddress;
