import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import { getFormattedQuestion, getImprovementPlanUrl } from '../../WorkloadAssessment/components/questionService';
import LaunchIcon from '@mui/icons-material/Launch';

const getRiskColor = (risk) => {
  switch (risk?.toUpperCase()) {
    case 'HIGH':
      return '#FF4444';
    case 'MEDIUM':
      return '#FFA500';
    case 'LOW':
      return '#4CAF50';
    case 'NONE':
      return '#2196F3';
    default:
      return '#757575';
  }
};

const QuestionsSummary = ({ data, pillarRefs }) => {
  if (!data) return null;

  return (
    <Box className="questions-summary" sx={{ p: 3 }}>
      {Object.entries(data).map(([pillarName, questions]) => (
        <Box key={pillarName} id={pillarName} ref={pillarRefs[pillarName]} sx={{ mb: 4 }}>
          <Box sx={{
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            p: 2,
            mb: 3,
            borderLeft: '4px solid #1976d2'
          }}>
            <Typography variant="h5" sx={{ 
              color: '#1976d2',
              fontWeight: 600 
            }}>
              {pillarName}
            </Typography>
          </Box>

          {questions.map((question, index) => (
            <Paper key={question.questionId} sx={{ mb: 2, p: 2 }}>
              <Box sx={{ 
                mb: 2,
                p: 1.5,
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                borderLeft: '4px solid #666'
              }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {/* Question {index + 1}: {question.questionTitle} */}
                  {getFormattedQuestion(question.questionTitle)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                  <Typography variant="body2" fontWeight="medium">Risk:</Typography>
                  <Chip 
                    label={question.risk || 'UNANSWERED'} 
                    size="small"
                    sx={{ 
                      bgcolor: getRiskColor(question.risk),
                      color: 'white',
                      fontWeight: 'bold'
                    }}
                  />
                </Box>
              </Box>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell 
                        sx={{ 
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          color: '#666',
                          width: '60%'
                        }}
                      >
                        Best Practice
                      </TableCell>
                      <TableCell 
                        align="center"
                        sx={{ 
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          color: '#666',
                          width: '20%'
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell 
                        align="center"
                        sx={{ 
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          color: '#666',
                          width: '20%'
                        }}
                      >
                        Nature
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {question.choices.map((choice) => (
                      <TableRow key={choice.choiceId}>
                        <TableCell>
                          {choice.status?.toUpperCase() === "SELECTED" ? (
                            <Typography variant="body2">
                              {choice.title}
                            </Typography>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Typography variant="body2">
                                {choice.title}
                              </Typography>
                              {getImprovementPlanUrl(choice.title) && (
                                <Chip
                                  label={
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                      <Typography sx={{ fontWeight: 'bold' }}>Improvement Plan</Typography>
                                      <LaunchIcon sx={{ fontSize: 16 }} />
                                    </Box>
                                  }
                                  size="small"
                                  color="info"
                                  component="a"
                                  href={getImprovementPlanUrl(choice.title)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  clickable
                                  sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                      textDecoration: 'none'
                                    }
                                  }}
                                />
                              )}
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Chip 
                            label={choice.status?.toUpperCase() === "SELECTED" ? "ANSWERED" : "UNANSWERED"}
                            size="small"
                            sx={{ 
                              bgcolor: choice.status === 'SELECTED' ? '#4CAF50' : '#757575',
                              color: 'white',
                              minWidth: '90px'
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Typography sx={{ color: '#666' }}>
                            {choice.automated ? 'Automated' : 'Manual'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default QuestionsSummary; 