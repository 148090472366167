import { FORGET_USER, FORGET_USER_SUCCESSFUL, FORGET_PASSWORD_API_FAILED, RESET_PASSWORD_STORE, VALIDATE_RESET_TOKEN, VALIDATE_RESET_TOKEN_SUCCESSFUL, VALIDATE_RESET_TOKEN_FAILED, RESET_PASSWORD_FORGOT, RESET_PASSWORD_FORGOT_SUCCESSFUL, RESET_PASSWORD_FORGOT_FAILED } from './actionTypes';

export const forgetUser = (user) => {
  
    return {
        type: FORGET_USER,
        payload: { user }
    }
}

export const forgetUserSuccessful = (message) => {
    return {
        type: FORGET_USER_SUCCESSFUL,
        payload: message
    }
}

export const userForgetPasswordError = (error) => {
    return {
        type: FORGET_PASSWORD_API_FAILED,
        payload: error
    }
}

export const clearStore = () => {
    return {
        type: RESET_PASSWORD_STORE
    }
}

export const validateResetToken = (token) =>{
    return {
        type: VALIDATE_RESET_TOKEN,
        payload: token
    }
}
export const validateResetTokenSuccess = (token) =>{
    return {
        type: VALIDATE_RESET_TOKEN_SUCCESSFUL,
        payload: token
    }
}
export const validateResetTokenFailed = (error) =>{
    return {
        type: VALIDATE_RESET_TOKEN_FAILED,
        payload: error
    }
}
export const resetPasswordForgot = (resetDTO) =>{
    return {
        type: RESET_PASSWORD_FORGOT,
        payload: resetDTO
    }
}
export const resetPasswordForgotSuccessful = (resetDTO) =>{
    return {
        type: RESET_PASSWORD_FORGOT_SUCCESSFUL,
        payload: resetDTO
    }
}
export const resetPasswordForgotFailed = (err) =>{
    return {
        type: RESET_PASSWORD_FORGOT_FAILED,
        payload: err
    }
}