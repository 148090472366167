import { takeLatest, put, call, all, fork } from "redux-saga/effects";
import {
  getCisPostureSuccessful,
  getCisPostureFailure,
  postCisScanSuccessful,
  postCisScanFailure,
  postCisPostureSuccessful,
  postCisPostureFailure,
  clearAllCisMessagesSuccessful,
} from "./cisWafrSlice";
import {
  getCisPosture as getCisPostureApi,
  postCisScan as postCisScanApi,
  postCisPosture as postCisPostureApi,
} from "../../helpers/auth_aws_helper";

// Saga for GET Call: getCisPosture
function* getCisPostureSaga({ payload }) {
  const { orgCode } = payload;

  if (!orgCode) {
    yield put(
      getCisPostureFailure({ message: "Organization code is missing." })
    );
    return;
  }
  try {
    const response = yield call(getCisPostureApi, { orgCode });
    console.log("*getCisPostureApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(getCisPostureSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching CIS Posture.";
      yield put(getCisPostureFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getCisPostureFailure({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getCisPostureFailure({ message: "No response received" }));
    } else {
      yield put(getCisPostureFailure({ message: error?.message }));
    }
  }
}

// Saga for POST Call: postCisScan
function* postCisScanSaga({ payload }) {
  console.log("🚀 ~ function*postCisScanSaga ~ payload:", payload);
  const { orgCode, accountNumber } = payload;

  if (!orgCode || !accountNumber) {
    yield put(
      postCisScanFailure({
        message: "Organization code or scan data is missing.",
      })
    );
    return;
  }
  try {
    const response = yield call(postCisScanApi, { orgCode, accountNumber });
    console.log("*postCisScanApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(postCisScanSuccessful(response?.data));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while starting CIS Scan.";
      yield put(postCisScanFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        postCisScanFailure({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(postCisScanFailure({ message: "No response received" }));
    } else {
      yield put(postCisScanFailure({ message: error?.message }));
    }
  }
}

function* postCisPostureSaga({ payload }) {
  console.log("🚀 ~ function*postCisPostureSaga ~ payload:", payload);
  
  try {
    const response = yield call(postCisPostureApi, payload );
    console.log("*postCisPostureApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(postCisPostureSuccessful(response?.data));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while posting CIS Posture.";
      yield put(postCisPostureFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        postCisPostureFailure({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(postCisPostureFailure({ message: "No response received" }));
    } else {
      yield put(postCisPostureFailure({ message: error?.message }));
    }
  }
}

// Saga for Clearing All Messages
function* clearAllCisMessagesSaga() {
  yield put(clearAllCisMessagesSuccessful());
}

// Watcher Saga
function* watchCisWafrSaga() {
  yield takeLatest("cisWafr/getCisPosture", getCisPostureSaga);
  yield takeLatest("cisWafr/postCisScan", postCisScanSaga);
  yield takeLatest("cisWafr/postCisPosture", postCisPostureSaga);
  yield takeLatest("cisWafr/clearAllCisMessages", clearAllCisMessagesSaga);
}

// Root Saga
export default function* cisWafrSaga() {
  yield all([fork(watchCisWafrSaga)]);
}
