import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import WorkloadAssessment from "./components/WorkloadAssessment";
import {
  getWafrQuestionnaire,
  clearAllWafrQuestionnaireMessages,
} from "./../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { enqueueSnackbar } from "notistack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useQuestionnaireFiltering } from "./hooks/useQuestionnaireFiltering";

const WorkloadAssessmentPage = () => {
  const { workloadId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    updateWafrAnswerMessage,
    isUpdatingWafrAnswer,
    updateWafrAnswerError,
  } = useSelector((state) => state.wafrQuestionnaire);

  const {
    orgCode,
    accCode,
    accNumber,
    assessmentName,
    frameworkName,
    ownerName,
    approverName,
    status,
    isReadOnly,
  } = location.state || {};

  const {
    wafrQuestionnaire,
    isGettingWafrQuestionnaire,
    getWafrQuestionnaireMessage,
    getWafrQuestionnaireError,
  } = useSelector((state) => state.wafrQuestionnaire);
  console.log(
    "🚀 ~ WorkloadAssessmentPage ~ getWafrQuestionnaireError:",
    getWafrQuestionnaireError
  );

  useEffect(() => {
    if (workloadId && orgCode && accCode) {
      dispatch(getWafrQuestionnaire({ workloadId, orgCode, accCode }));

      return () => {
        dispatch(clearAllWafrQuestionnaireMessages());
      };
    }
  }, [workloadId, dispatch, orgCode, accCode]);

  useEffect(() => {
    if (updateWafrAnswerMessage) {
      enqueueSnackbar(updateWafrAnswerMessage, { variant: "success" });
      console.log("updateWafrAnswerMessage = ", updateWafrAnswerMessage);
      if (workloadId && orgCode && accCode) {
        dispatch(getWafrQuestionnaire({ workloadId, orgCode, accCode }));
      }
    }
    if (updateWafrAnswerError) {
      enqueueSnackbar(updateWafrAnswerError, { variant: "error" });
      console.log("updateWafrAnswerError = ", updateWafrAnswerError);
    }
    if (
      updateWafrAnswerMessage ||
      updateWafrAnswerError ||
      getWafrQuestionnaireMessage
    ) {
      dispatch(clearAllWafrQuestionnaireMessages());
    }
  }, [
    updateWafrAnswerMessage,
    updateWafrAnswerError,
    getWafrQuestionnaireMessage,
    workloadId,
    orgCode,
    accCode,
    dispatch,
  ]);

  const { filteredData, handleFilterChange } =
    useQuestionnaireFiltering(wafrQuestionnaire);

  return (
    <Box
      className="page-content"
      sx={{
        backgroundColor: "#F9F9F9",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      {isGettingWafrQuestionnaire ? (
        <CircularProgress />
      ) : getWafrQuestionnaireError ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "50vh",
            width: "50%",
            backgroundColor: "#fef8f8",
            border: "1px solid #f44336",
            borderRadius: "8px",
            padding: "2rem",
            textAlign: "center",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#f44336"
            sx={{ mb: 1 }}
          >
            Something Went Wrong
          </Typography>
          <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
            {getWafrQuestionnaireError}
          </Typography>
        </Box>
      ) : (
        <WorkloadAssessment
          wlaData={filteredData}
          orgCode={orgCode}
          accCode={accCode}
          accNumber={accNumber}
          assessmentName={assessmentName}
          frameworkName={frameworkName}
          ownerName={ownerName}
          approverName={approverName}
          workloadId={workloadId}
          handleFilterChange={handleFilterChange}
          status={status}
          isReadOnly={isReadOnly}
          initialData={wafrQuestionnaire}
        />
      )}
    </Box>
  );
};

export default WorkloadAssessmentPage;
