import React, { useState } from "react";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";
import { Tab, Tabs } from "@mui/material";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";
import ApplicationsTagging from "./ApplicationsTagging";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const SpendAllocation = () => {
  const [activeTab, setActiveTab] = useState("tags2");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "tags2":
        // TagAllocation
        return <GetKibanaDashboard dashboardKey={"App Tagging"} />;
      case "tags":
        // TagAllocation
        return <ApplicationsTagging />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Workload Spend Summary" value="tags2" />
        <Tab label="Tag & Label" value="tags" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default SpendAllocation;
