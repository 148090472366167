import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWafrAttachmentsUrl, clearAllWafrQuestionnaireMessages, listWafrAttachments } from '../../../store/wafrQuestionnaire/wafrQuestionnaireSlice';
import { Snackbar, Alert, CircularProgress, Box, Typography, Paper, Fade, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSnackbar } from 'notistack';

const AttachmentUploader = ({ 
  accountCode, 
  workloadId, 
  questionId, 
  disabled = false 
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const messagesEndRef = useRef(null);
  const wsRef = useRef(null);
  const {
    isGettingWafrAttachmentsUrl,
    getWafrAttachmentsUrlMessage,
    getWafrAttachmentsUrlError,
    wafrAttachmentsUrl
  } = useSelector((state) => state.wafrQuestionnaire);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [webSocketStatus, setWebSocketStatus] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [showMessages, setShowMessages] = useState(false);
  const IAAC_SCAN_WEBSOCKET_URL = process.env.REACT_APP_IAAC_SCAN_WEBSOCKET_URL;

  // Get access token from localStorage
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      setAccessToken(authUser.accessToken);
    }
  }, []);

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Cleanup WebSocket on unmount
  useEffect(() => {
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  // Handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setSelectedFile(file);
    setUploadError(null);
    setIsUploading(true);
    setMessages([]); // Clear previous messages
    setShowMessages(false); // Reset message panel

    // Get the pre-signed URL
    dispatch(getWafrAttachmentsUrl({
      accountCode,
      workloadId,
      questionId,
      fileName: file.name
    }));

    // Clear the file input
    event.target.value = '';
  };

  // Extract complete filename from URL
  const extractFileNameFromUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const pathParts = urlObj.pathname.split('/');
      return pathParts[pathParts.length - 1];
    } catch (error) {
      console.error('Error parsing URL:', error);
      return null;
    }
  };

  // Handle API response and file upload
  useEffect(() => {
    const uploadFile = async () => {
      if (!wafrAttachmentsUrl?.uploadUrl || !selectedFile) return;

      try {
        const response = await fetch(wafrAttachmentsUrl.uploadUrl, {
          method: 'PUT',
          body: selectedFile,
          headers: {
            'Content-Type': selectedFile.type,
          },
        });

        if (response.ok) {
          const fileName = extractFileNameFromUrl(wafrAttachmentsUrl.uploadUrl);
          if (fileName) {
            initiateWebSocketConnection(fileName);
          }
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('Error:', error);
        setUploadError(error.message || 'Failed to upload file');
        setIsUploading(false);
        setShowMessages(false);
      }
    };

    uploadFile();
  }, [wafrAttachmentsUrl, selectedFile]);

  // WebSocket connection
  const initiateWebSocketConnection = (fileName) => {
    if (!accessToken || !fileName) return;

    const ws = new WebSocket(`${IAAC_SCAN_WEBSOCKET_URL}?token=${accessToken}`);
    wsRef.current = ws;

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      setWebSocketStatus(true);
      setShowMessages(true); // Show message panel immediately on connection
      ws.send(JSON.stringify({
        action: "sendMessage",
        fileName: fileName
      }));
    };

    ws.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      const newMessage = {
        text: messageData.Message,
        status: messageData.Status,
        isReceived: true,
        timestamp: new Date().toLocaleTimeString(),
      };
      
      setMessages(prevMessages => [...prevMessages, newMessage]);

      // Handle completion based on status
      if (messageData.Message === "Malware scan completed successfully. Your file has been uploaded.") {
        if (messageData.Status.toLowerCase() === 'success') {
          // Refresh attachment list only on success
          dispatch(listWafrAttachments({
            accountCode,
            workloadId,
            questionId
          }));
        }
        ws.close();
      }
    };

    ws.onclose = () => {
      console.log('WebSocket closed');
      setWebSocketStatus(false);
      wsRef.current = null;
      setIsUploading(false);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setWebSocketStatus(false);
      enqueueSnackbar("WebSocket connection error!", { variant: "error" });
      wsRef.current = null;
      setIsUploading(false);
      setShowMessages(false);
    };

    return ws;
  };

  // Handle error messages
  useEffect(() => {
    if (getWafrAttachmentsUrlError) {
      setIsUploading(false);
      setSelectedFile(null);
      setShowMessages(false);
      enqueueSnackbar(getWafrAttachmentsUrlError, { variant: "error" });
      dispatch(clearAllWafrQuestionnaireMessages());
    }
  }, [getWafrAttachmentsUrlError, dispatch, enqueueSnackbar]);

  return (
    <>
      <input
        type="file"
        onChange={handleFileSelect}
        disabled={disabled || isGettingWafrAttachmentsUrl || isUploading}
        style={{ display: 'none' }}
        id="file-upload-input"
        accept="*/*"
      />

      {/* Status Display */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, ml: 2 }}>
        {(isGettingWafrAttachmentsUrl || isUploading) && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CircularProgress size={16} />
            <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
              {isGettingWafrAttachmentsUrl 
                ? 'Getting upload URL...' 
                : `Uploading ${selectedFile?.name}...`}
            </Typography>
          </Box>
        )}
        {getWafrAttachmentsUrlError && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'error.main' }}>
            <ErrorIcon sx={{ fontSize: 16 }} />
            <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
              {getWafrAttachmentsUrlError}
            </Typography>
          </Box>
        )}
        
        {/* WebSocket Messages */}
        {showMessages && (
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2,
              overflow: 'auto',
              bgcolor: 'background.default',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              position: 'relative',
              width: '100%',
              maxWidth: '800px'
            }}
          >
            <IconButton
              size="small"
              onClick={() => setShowMessages(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                zIndex: 1
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            
            {/* Message Flow Container */}
            <Box 
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                overflowX: 'auto',
                pb: 1,
                pt: 1,
                px: 1
              }}
            >
              {messages.length === 0 && webSocketStatus ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} />
                  <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                    Waiting for scan to begin...
                  </Typography>
                </Box>
              ) : (
                <>
                  {messages.map((message, index) => (
                    <React.Fragment key={index}>
                      <Fade in={true}>
                        <Box 
                          sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            minWidth: '200px',
                            gap: 0.5, 
                            p: 1.5,
                            borderRadius: 1,
                            bgcolor: message.status.toLowerCase() === 'success' 
                              ? 'success.soft' 
                              : message.status.toLowerCase() === 'error'
                              ? 'error.soft'
                              : 'info.soft',
                            color: message.status.toLowerCase() === 'success'
                              ? 'success.main'
                              : message.status.toLowerCase() === 'error'
                              ? 'error.main'
                              : 'info.main',
                            border: '1px solid',
                            borderColor: message.status.toLowerCase() === 'success'
                              ? 'success.light'
                              : message.status.toLowerCase() === 'error'
                              ? 'error.light'
                              : 'info.light',
                          }}
                        >
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              fontSize: '0.8rem',
                              fontWeight: 500,
                              lineHeight: 1.4
                            }}
                          >
                            {message.text}
                          </Typography>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              fontSize: '0.65rem',
                              opacity: 0.8
                            }}
                          >
                            {message.timestamp}
                          </Typography>
                        </Box>
                      </Fade>
                      {index < messages.length - 1 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                          <ArrowForwardIcon />
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                  {/* Show spinner at the end if WebSocket is active */}
                  {webSocketStatus && (
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                      <CircularProgress size={20} />
                    </Box>
                  )}
                </>
              )}
            </Box>
            <div ref={messagesEndRef} />
          </Paper>
        )}
      </Box>

      {/* Error Snackbar */}
      <Snackbar
        open={!!uploadError}
        autoHideDuration={6000}
        onClose={() => setUploadError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert 
          onClose={() => setUploadError(null)} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {uploadError}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AttachmentUploader; 