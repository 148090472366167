import { createSlice } from "@reduxjs/toolkit";

export const cisWafrSlice = createSlice({
  name: "cisWafr",
  initialState: {
    isGettingCisPosture: false,
    getCisPostureMessage: null,
    getCisPostureError: null,
    cisPosture: null,

    isPostingCisScan: false,
    postCisScanMessage: null,
    postCisScanError: null,

    isPostingCisPosture: false,
    postCisPostureMessage: null,
    postCisPostureError: null,
  },
  reducers: {
    // GET Call: Trigger
    getCisPosture: (state) => {
      state.isGettingCisPosture = true;
      state.getCisPostureMessage = null;
      state.getCisPostureError = null;
      state.cisPosture = null;
    },
    // GET Call: Success
    getCisPostureSuccessful: (state, action) => {
      state.isGettingCisPosture = false;
      state.cisPosture = action.payload;
      state.getCisPostureMessage = "CIS Posture retrieved successfully!";
      state.getCisPostureError = null;
    },
    // GET Call: Failure
    getCisPostureFailure: (state, action) => {
      state.isGettingCisPosture = false;
      state.getCisPostureMessage = null;
      state.getCisPostureError = action.payload.message;
    },
    // POST Call: Trigger
    postCisScan: (state) => {
      state.isPostingCisScan = true;
      state.postCisScanMessage = null;
      state.postCisScanError = null;
    },
    // POST Call: Success
    postCisScanSuccessful: (state, action) => {
      state.isPostingCisScan = false;
      state.postCisScanMessage = action.payload;
      state.postCisScanError = null;
    },
    // POST Call: Failure
    postCisScanFailure: (state, action) => {
      state.isPostingCisScan = false;
      state.postCisScanMessage = null;
      state.postCisScanError = action.payload.message;
    },

    // POST Posture Call: Trigger
    postCisPosture: (state) => {
      state.isPostingCisPosture = true;
      state.postCisPostureMessage = null;
      state.postCisPostureError = null;
    },
    // POST Posture Call: Success
    postCisPostureSuccessful: (state, action) => {
      state.isPostingCisPosture = false;
      state.postCisPostureMessage = action.payload;
      state.postCisPostureError = null;
    },
    // POST Posture Call: Failure
    postCisPostureFailure: (state, action) => {
      state.isPostingCisPosture = false;
      state.postCisPostureMessage = null;
      state.postCisPostureError = action.payload.message;
    },

    // Clear All Messages
    clearAllCisMessages: (state, action) => {},

    clearAllCisMessagesSuccessful: (state, action) => {
      state.getCisPostureMessage = null;
      state.getCisPostureError = null;

      state.postCisScanMessage = null;
      state.postCisScanError = null;

      state.postCisPostureMessage = null;
      state.postCisPostureError = null;
    },
  },
});

export const {
  getCisPosture,
  getCisPostureSuccessful,
  getCisPostureFailure,
  postCisScan,
  postCisScanSuccessful,
  postCisScanFailure,
  postCisPosture, 
  postCisPostureSuccessful, 
  postCisPostureFailure, 
  clearAllCisMessages,
  clearAllCisMessagesSuccessful,
} = cisWafrSlice.actions;

export default cisWafrSlice.reducer;
