import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
} from '@mui/material';
import { pdfStyles } from '../pdfStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const getStatusColor = (status) => {
  switch (status?.toUpperCase()) {
    case 'GREEN':
      return '#4CAF50';
    case 'YELLOW':
      return '#FFA500';
    case 'RED':
      return '#FF4444';
    case 'UNSELECTED':
      return '#757575';
    default:
      return '#757575';
  }
};

const getStatusIcon = (status) => {
  switch (status?.toUpperCase()) {
    case 'COMPLIANT':
      return <CheckCircleIcon sx={{ color: '#4caf50' }} />;
    case 'NON_COMPLIANT':
      return <CancelIcon sx={{ color: '#f44336' }} />;
    case 'WARNING':
      return <WarningAmberIcon sx={{ color: '#ff9800' }} />;
    default:
      return null;
  }
};

const getStatusInfo = (status) => {
  switch (status?.toUpperCase()) {
    case 'GREEN':
      return {
        icon: <CheckCircleIcon sx={{ color: '#4caf50', fontSize: '1.2rem' }} />,
        // text: 'Green',
        color: '#4caf50'
      };
    case 'YELLOW':
      return {
        icon: <WarningAmberIcon sx={{ color: '#ff9800', fontSize: '1.2rem' }} />,
        // text: 'Yellow',
        color: '#ff9800'
      };
    case 'RED':
      return {
        icon: <CancelIcon sx={{ color: '#f44336', fontSize: '1.2rem' }} />,
        // text: 'Red',
        color: '#f44336'
      };
    default:
      return {
        icon: null,
        text: status || '-',
        color: '#757575'
      };
  }
};

const StatusCell = ({ status }) => {
  const statusInfo = getStatusInfo(status);
  
  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      gap: 1
    }}>
      {statusInfo.icon}
      <Typography sx={{ 
        color: statusInfo.color,
        fontWeight: 500
      }}>
        {statusInfo.text}
      </Typography>
    </Box>
  );
};

const ResourcesSummary = ({ data, pillarRefs }) => {
  if (!data) return null;

  const renderResourceDetailsTable = (resourceDetails, resourceCount) => {
    if (!resourceDetails?.length) return null;
    
    const firstResource = resourceDetails[0];
    const headerCells = Object.keys(firstResource).map(key => ({
      id: key,
      label: key
    }));

    return (
      <Box>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 2 
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#666' }}>
            Resource Details
          </Typography>
          <Chip
            label={`Resource Count: ${resourceCount}`}
            size="small"
            sx={{
              bgcolor: '#e0e0e0',
              fontWeight: 'bold'
            }}
          />
        </Box>
        <TableContainer component={Paper} variant="outlined" sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerCells.map((cell) => (
                  <TableCell 
                    key={cell.id}
                    sx={{ 
                      fontWeight: 'bold',
                      backgroundColor: '#f5f5f5',
                      color: '#666'
                    }}
                  >
                    {cell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {resourceDetails.map((detail, idx) => (
                <TableRow key={idx}>
                  {headerCells.map((cell) => (
                    <TableCell key={cell.id}>
                      {cell.id === 'Status' ? (
                        <StatusCell status={detail[cell.id]} />
                      ) : (
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {detail[cell.id] || '-'}
                        </Typography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <Box sx={{ p: 3 }} className="pdf-content">
      {Object.entries(data).map(([pillarName, questions]) => (
        <Box 
          key={pillarName} 
          id={pillarName}
          ref={pillarRefs[pillarName]}
          sx={{ mb: 4 }}
        >
          {questions.map((question) => (
            <TableContainer key={question.questionId} component={Paper} sx={{ mb: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell 
                      sx={{ 
                        fontWeight: 'bold',
                        backgroundColor: '#f5f5f5',
                        color: '#666',
                        width: '20%'
                      }}
                    >
                      Pillar Name
                    </TableCell>
                    <TableCell sx={{ width: '60%' }}>{pillarName}</TableCell>
                    <TableCell 
                      sx={{ 
                        width: '20%',
                        textAlign: 'right'
                      }}
                    >
                      <Chip 
                        label={`Risk: ${question.risk || 'NONE'}`}
                        size="small"
                        sx={{ 
                          bgcolor: question.risk === 'HIGH' ? '#FF4444' : '#757575',
                          color: 'white',
                          fontWeight: 'bold'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell 
                      sx={{ 
                        fontWeight: 'bold',
                        backgroundColor: '#f5f5f5',
                        color: '#666'
                      }}
                    >
                      Question
                    </TableCell>
                    <TableCell colSpan={2}>{question.questionTitle}</TableCell>
                  </TableRow>
                  
                  {/* Map through all choices/best practices */}
                  {question.choices.map((choice, index) => (
                    <React.Fragment key={choice.choiceId}>
                      <TableRow>
                        <TableCell 
                          sx={{ 
                            fontWeight: 'bold',
                            backgroundColor: '#f5f5f5',
                            color: '#666'
                          }}
                        >
                          Best Practice {index + 1}
                        </TableCell>
                        <TableCell>
                          {choice.title || '-'}
                        </TableCell>
                        <TableCell>
                          <Chip 
                            label={`Status: ${choice.status?.toUpperCase() === "SELECTED" ? "Answered" : "Unanswered"}`}
                            size="small"
                            sx={{ 
                              bgcolor: getStatusColor(choice.status),
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell 
                          sx={{ 
                            fontWeight: 'bold',
                            backgroundColor: '#f5f5f5',
                            color: '#666'
                          }}
                        >
                          Description
                        </TableCell>
                        <TableCell colSpan={2}>{choice.description || '-'}</TableCell>
                      </TableRow>

                      {/* Resource Details for each choice */}
                      {choice.resources?.map((resource) => (
                        <TableRow key={resource.checkID}>
                          <TableCell colSpan={3}>
                            <Box sx={{ mt: 2, px: 2, mb: 2 }}>
                              <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
                                {resource.name}:
                              </Typography>
                              <Typography 
                                dangerouslySetInnerHTML={{ __html: resource.description }}
                                sx={{ mb: 2 }}
                              />
                              {renderResourceDetailsTable(resource.resourceDetails, resource.resourceCount)}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </Box>
      ))}
      <Typography variant="caption" sx={{ display: 'block', mt: 2 }}>
        Note: The above table displays only upto 100 resource violations. 
      </Typography>
    </Box>
  );
};

export default ResourcesSummary; 