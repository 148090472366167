// utils/dateUtils.js

export const formatUtcDate = (timestamp) => {
    if (!timestamp) return "N/A";
  
    const date = new Date(timestamp);
  
    // Format: "Wed Feb 05 2025"
    const datePart = date.toUTCString().split(" ").slice(0, 4).join(" ");
  
    // Format: "HH:mm:ss UTC"
    const timePart = new Intl.DateTimeFormat("en-GB", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }).format(date) + " UTC";
  
    return `${datePart} ${timePart}`;
  };
  