import { useState, useCallback, useEffect } from 'react';

export const useQuestionnaireFiltering = (initialData) => {
  const [filteredData, setFilteredData] = useState(initialData);
  const [activeFilters, setActiveFilters] = useState([]);

  const applyFilters = useCallback((data, filters) => {
    if (!data) return data;
    
    const filtered = {};
    const typeFilter = filters.find(f => ['automated', 'manual'].includes(f));
    const statusFilter = filters.find(f => ['answered', 'unanswered', 'not_applicable'].includes(f));
    const riskFilters = filters.filter(f => ['high', 'medium', 'none'].includes(f));

    Object.keys(data).forEach(section => {
      const filteredQuestions = data[section].filter(question => {
        const isAutomated = question.choices.some(choice => choice.automated === true);
        
        // Apply Type filter first
        if (typeFilter) {
          if (typeFilter === 'automated' && !isAutomated) return false;
          if (typeFilter === 'manual' && isAutomated) return false;
        }

        // Then apply Status filter
        if (statusFilter) {
          if (statusFilter === 'answered' && (!question.risk || question.risk === "UNANSWERED")) return false;
          if (statusFilter === 'unanswered' && question.risk && question.risk !== "UNANSWERED") return false;
          if (statusFilter === 'not_applicable' && question.applicable) return false;
        }

        // Finally apply Risk filters (if any)
        if (riskFilters.length > 0) {
          return riskFilters.some(risk => 
            question.risk === risk.toUpperCase()
          );
        }

        return true;
      });

      filtered[section] = filteredQuestions;
    });

    return filtered;
  }, []);

  // Update filtered data when filters change
  useEffect(() => {
    const newFilteredData = applyFilters(initialData, activeFilters);
    setFilteredData(newFilteredData);
  }, [initialData, activeFilters, applyFilters]);

  // Handler for filter changes
  const handleFilterChange = useCallback((newFilters) => {
    setActiveFilters(newFilters);
  }, []);

  return {
    filteredData: filteredData || initialData,
    handleFilterChange,
    activeFilters
  };
}; 