import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Modal,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { createMilestone } from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";

const CreateMilestoneModal = ({ open, onClose, workloadId, accCode, orgCode }) => {
  console.log("🚀 ~ CreateMilestoneModal ~ workloadId:", workloadId)
  const dispatch = useDispatch();
  const { isCreatingMilestone } = useSelector((state) => state.wafrQuestionnaire);

  const [milestoneName, setMilestoneName] = useState("");

  const handleCreateMilestone = () => {
    const createMilestonePayload = {
      workLoadId: workloadId,
      accCode,
      orgCode,
      mileStoneName: milestoneName,
    };
    dispatch(createMilestone(createMilestonePayload));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-milestone-modal"
      aria-describedby="modal-for-creating-milestone"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 0,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F5F5F5",
            padding: "16px 24px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Create Milestone
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        {/* Form */}
        <Box
          component="form"
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Milestone Name"
            placeholder="Enter milestone name"
            required
            value={milestoneName}
            onChange={(e) => setMilestoneName(e.target.value)}
          />

          {/* Action Buttons */}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isCreatingMilestone || !milestoneName.trim()}
              onClick={handleCreateMilestone}
            >
              {!isCreatingMilestone ? "Save" : "Saving..."}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateMilestoneModal; 