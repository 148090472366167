import { createSlice } from "@reduxjs/toolkit";

export const wafrQuestionnaireSlice = createSlice({
  name: "wafrQuestionnaire",
  initialState: {
    isGettingWafrQuestionnaire: false,
    getWafrQuestionnaireMessage: null,
    getWafrQuestionnaireError: null,
    getWafrQuestionnairePayload: null,
    wafrQuestionnaire: null,

    // Initial state for getAssessmentList
    isGettingAssessmentList: false,
    getAssessmentListMessage: null,
    getAssessmentListError: null,
    assessmentList: null,

    // Delete Assessment
    isDeletingAssessment: false,
    deleteAssessmentMessage: null,
    deleteAssessmentError: null,

    // Update answer
    isUpdatingWafrAnswer: false,
    updateWafrAnswerMessage: null,
    updateWafrAnswerError: null,
    updateWafrAnswerPayload: null,

    // Creating workload
    isPostingWafrAssessment: false,
    postWafrAssessmentMessage: null,
    postWafrAssessmentError: null,
    postWafrAssessmentPayload: null,

    // Trusted Advisor Checks
    isPostingAutomatedChecks: false,
    postAutomatedChecksMessage: null,
    postAutomatedChecksError: null,
    postAutomatedChecksPayload: null,

    // Flagged Details
    isGettingFlaggedDetails: false,
    getFlaggedDetailsMessage: null,
    getFlaggedDetailsError: null,
    flaggedDetails: null,

    // Complete Assessment
    isPostingCompleteAssessment: false,
    postCompleteAssessmentMessage: null,
    postCompleteAssessmentError: null,
    postCompleteAssessmentPayload: null,

    // Add these new states for WAFR Summary
    isGettingWafrSummary: false,
    getWafrSummaryMessage: null,
    getWafrSummaryError: null,
    wafrSummary: null,

    // Add these new states for WAFR Report
    isGettingWafrReport: false,
    getWafrReportMessage: null,
    getWafrReportError: null,
    wafrReport: null,

    // Add these new states for Milestone List
    isGettingMilestoneList: false,
    getMilestoneListMessage: null,
    getMilestoneListError: null,
    milestoneList: null,

    // Add these new states for Milestone Details
    isGettingMilestoneDetails: false,
    getMilestoneDetailsMessage: null,
    getMilestoneDetailsError: null,
    milestoneDetails: null,

    // Add these new states for Create Milestone
    isCreatingMilestone: false,
    createMilestoneMessage: null,
    createMilestoneError: null,
    createMilestonePayload: null,

    // Add these new states for WAFR Attachments
    isGettingWafrAttachmentsUrl: false,
    getWafrAttachmentsUrlMessage: null,
    getWafrAttachmentsUrlError: null,
    wafrAttachmentsUrl: null,

    isListingWafrAttachments: false,
    listWafrAttachmentsMessage: null,
    listWafrAttachmentsError: null,
    wafrAttachmentsList: null,

    isDownloadingWafrAttachment: false,
    downloadWafrAttachmentMessage: null,
    downloadWafrAttachmentError: null,
    wafrAttachmentDownloadUrl: null,

    // Add these new states in initialState
    isGettingWafrStakeholders: false,
    getWafrStakeholdersMessage: null,
    getWafrStakeholdersError: null,
    wafrStakeholders: null,

    isPuttingWafrStakeholders: false,
    putWafrStakeholdersMessage: null,
    putWafrStakeholdersError: null,
    putWafrStakeholdersPayload: null,
  },
  reducers: {
    getWafrQuestionnaire: (state, action) => {
      state.isGettingWafrQuestionnaire = true;
      state.getWafrQuestionnaireMessage = null;
      state.getWafrQuestionnaireError = null;
      state.getWafrQuestionnairePayload = action.payload;
      state.wafrQuestionnaire = null;
    },
    getWafrQuestionnaireSuccessful: (state, action) => {
      state.isGettingWafrQuestionnaire = false;
      state.wafrQuestionnaire = action.payload;
      state.getWafrQuestionnairePayload = null;
      state.getWafrQuestionnaireMessage =
        "WAFR Questionnaire retrieved Successfully!";
      state.getWafrQuestionnaireError = null;
    },
    getWafrQuestionnaireFailure: (state, action) => {
      state.isGettingWafrQuestionnaire = false;
      state.getWafrQuestionnaireMessage = null;
      state.getWafrQuestionnaireError = action.payload.message;
      state.getWafrQuestionnairePayload = null;
    },
    clearAllWafrQuestionnaireMessages: (state, action) => {},
    clearAllWafrQuestionnaireMessagesSuccessful: (state, action) => {
      state.getWafrQuestionnaireMessage = null;
      state.getWafrQuestionnaireError = null;

      state.updateWafrAnswerError = null;
      state.updateWafrAnswerMessage = null;

      state.postWafrAssessmentMessage = null;
      state.postWafrAssessmentError = null;

      // Clear messages for postAutomatedChecks
      state.postAutomatedChecksMessage = null;
      state.postAutomatedChecksError = null;

      // Clear messages for getFlaggedDetails
      state.getFlaggedDetailsMessage = null;
      state.getFlaggedDetailsError = null;

      // Clear messages for postCompleteAssessment
      state.postCompleteAssessmentMessage = null;
      state.postCompleteAssessmentError = null;

      // Clear Message for Reports
      state.getWafrReportMessage = null;
      state.getWafrReportError = null;

      // Clear Message for Summary
      state.getWafrSummaryMessage = null;
      state.getWafrSummaryError = null;

      // Clear Milestone List messages
      state.getMilestoneListMessage = null;
      state.getMilestoneListError = null;

      // Clear Milestone Details messages
      state.getMilestoneDetailsMessage = null;
      state.getMilestoneDetailsError = null;

      // Clear Create Milestone messages
      state.createMilestoneMessage = null;
      state.createMilestoneError = null;

      // Clear Delete Assessment messages
      state.deleteAssessmentMessage = null;
      state.deleteAssessmentError = null;

      // Clear WAFR Attachments messages
      state.getWafrAttachmentsUrlMessage = null;
      state.getWafrAttachmentsUrlError = null;
      state.listWafrAttachmentsMessage = null;
      state.listWafrAttachmentsError = null;
      state.downloadWafrAttachmentMessage = null;
      state.downloadWafrAttachmentError = null;

      // Add to clearAllWafrQuestionnaireMessagesSuccessful
      state.getWafrStakeholdersMessage = null;
      state.getWafrStakeholdersError = null;
      state.putWafrStakeholdersMessage = null;
      state.putWafrStakeholdersError = null;
    },

    updateWafrAnswer: (state, action) => {
      state.isUpdatingWafrAnswer = true;
      state.updateWafrAnswerMessage = null;
      state.updateWafrAnswerError = null;
      state.updateWafrAnswerPayload = action.payload;
    },
    updateWafrAnswerSuccessful: (state, action) => {
      state.isUpdatingWafrAnswer = false;
      state.updateWafrAnswerMessage = "Answers were updated successfully";
      state.updateWafrAnswerPayload = null;
    },
    updateWafrAnswerFailure: (state, action) => {
      state.isUpdatingWafrAnswer = false;
      state.updateWafrAnswerError = action.payload.message;
      state.updateWafrAnswerPayload = null;
    },

    // Reducers for getAssessmentList
    getAssessmentList: (state) => {
      state.isGettingAssessmentList = true;
      state.getAssessmentListMessage = null;
      state.getAssessmentListError = null;
      state.assessmentList = null;
    },
    getAssessmentListSuccessful: (state, action) => {
      state.isGettingAssessmentList = false;
      state.assessmentList = action.payload;
      state.getAssessmentListMessage =
        "Assessment List retrieved Successfully!";
      state.getAssessmentListError = null;
    },
    getAssessmentListFailure: (state, action) => {
      state.isGettingAssessmentList = false;
      state.getAssessmentListMessage = null;
      state.getAssessmentListError = action.payload.message;
    },
    clearAllAssessmentMessages: (state) => {
      state.getAssessmentListMessage = null;
      state.getAssessmentListError = null;
    },

    postWafrAssessment: (state, action) => {
      state.isPostingWafrAssessment = true;
      state.postWafrAssessmentMessage = null;
      state.postWafrAssessmentError = null;
      state.postWafrAssessmentPayload = action.payload;
    },
    postWafrAssessmentSuccessful: (state, action) => {
      state.isPostingWafrAssessment = false;
      state.postWafrAssessmentMessage = action.payload;
      state.postWafrAssessmentPayload = null;
    },
    postWafrAssessmentFailure: (state, action) => {
      state.isPostingWafrAssessment = false;
      state.postWafrAssessmentError = action.payload.message;
      state.postWafrAssessmentPayload = null;
    },

    // Trusted Advisor Checks
    postAutomatedChecks: (state, action) => {
      state.isPostingAutomatedChecks = true;
      state.postAutomatedChecksMessage = null;
      state.postAutomatedChecksError = null;
      state.postAutomatedChecksPayload = action.payload;
    },
    postAutomatedChecksSuccessful: (state, action) => {
      state.isPostingAutomatedChecks = false;
      state.postAutomatedChecksMessage = action.payload;
      state.postAutomatedChecksPayload = null;
    },
    postAutomatedChecksFailure: (state, action) => {
      state.isPostingAutomatedChecks = false;
      state.postAutomatedChecksError = action.payload.message;
      state.postAutomatedChecksPayload = null;
    },

    // Complete Assessment
    postCompleteAssessment: (state, action) => {
      state.isPostingCompleteAssessment = true;
      state.postCompleteAssessmentMessage = null;
      state.postCompleteAssessmentError = null;
      state.postCompleteAssessmentPayload = action.payload;
    },
    postCompleteAssessmentSuccessful: (state, action) => {
      state.isPostingCompleteAssessment = false;
      state.postCompleteAssessmentMessage = action.payload;
      state.postCompleteAssessmentPayload = null;
    },
    postCompleteAssessmentFailure: (state, action) => {
      state.isPostingCompleteAssessment = false;
      state.postCompleteAssessmentError = action.payload.message;
      state.postCompleteAssessmentPayload = null;
    },

    // Flagged Details
    getFlaggedDetails: (state, action) => {
      state.isGettingFlaggedDetails = true;
      state.getFlaggedDetailsMessage = null;
      state.getFlaggedDetailsError = null;
      state.flaggedDetails = null;
    },
    getFlaggedDetailsSuccessful: (state, action) => {
      state.isGettingFlaggedDetails = false;
      state.flaggedDetails = action.payload.data;
      state.getFlaggedDetailsMessage = action.payload;
      state.getFlaggedDetailsError = null;
    },
    getFlaggedDetailsFailure: (state, action) => {
      state.isGettingFlaggedDetails = false;
      state.getFlaggedDetailsMessage = null;
      state.getFlaggedDetailsError = action.payload.message;
    },

    // Reducers for getWafrSummary
    getWafrSummary: (state, action) => {
      state.isGettingWafrSummary = true;
      state.getWafrSummaryMessage = null;
      state.getWafrSummaryError = null;
      state.wafrSummary = null;
    },
    getWafrSummarySuccessful: (state, action) => {
      state.isGettingWafrSummary = false;
      state.wafrSummary = action.payload;
      state.getWafrSummaryMessage = "WAFR Summary retrieved Successfully!";
      state.getWafrSummaryError = null;
    },
    getWafrSummaryFailure: (state, action) => {
      state.isGettingWafrSummary = false;
      state.getWafrSummaryMessage = null;
      state.getWafrSummaryError = action.payload.message;
    },

    // Reducers for getWafrReport
    getWafrReport: (state, action) => {
      state.isGettingWafrReport = true;
      state.getWafrReportMessage = null;
      state.getWafrReportError = null;
      state.wafrReport = null;
    },
    getWafrReportSuccessful: (state, action) => {
      state.isGettingWafrReport = false;
      state.wafrReport = action.payload;
      state.getWafrReportMessage = "WAFR Report retrieved Successfully!";
      state.getWafrReportError = null;
    },
    getWafrReportFailure: (state, action) => {
      state.isGettingWafrReport = false;
      state.getWafrReportMessage = null;
      state.getWafrReportError = action.payload.message;
    },

    // Reducers for getMilestoneList
    getMilestoneList: (state, action) => {
      state.isGettingMilestoneList = true;
      state.getMilestoneListMessage = null;
      state.getMilestoneListError = null;
      state.milestoneList = null;
    },
    getMilestoneListSuccessful: (state, action) => {
      state.isGettingMilestoneList = false;
      state.milestoneList = action.payload;
      state.getMilestoneListMessage = "Milestone List retrieved Successfully!";
      state.getMilestoneListError = null;
    },
    getMilestoneListFailure: (state, action) => {
      state.isGettingMilestoneList = false;
      state.getMilestoneListMessage = null;
      state.getMilestoneListError = action.payload.message;
    },

    // Reducers for getMilestoneDetails
    getMilestoneDetails: (state, action) => {
      state.isGettingMilestoneDetails = true;
      state.getMilestoneDetailsMessage = null;
      state.getMilestoneDetailsError = null;
      state.milestoneDetails = null;
    },
    getMilestoneDetailsSuccessful: (state, action) => {
      state.isGettingMilestoneDetails = false;
      state.milestoneDetails = action.payload;
      state.getMilestoneDetailsMessage = "Milestone Details retrieved Successfully!";
      state.getMilestoneDetailsError = null;
    },
    getMilestoneDetailsFailure: (state, action) => {
      state.isGettingMilestoneDetails = false;
      state.getMilestoneDetailsMessage = null;
      state.getMilestoneDetailsError = action.payload.message;
    },

    // Reducers for createMilestone
    createMilestone: (state, action) => {
      state.isCreatingMilestone = true;
      state.createMilestoneMessage = null;
      state.createMilestoneError = null;
      state.createMilestonePayload = action.payload;
    },
    createMilestoneSuccessful: (state, action) => {
      state.isCreatingMilestone = false;
      state.createMilestoneMessage = action.payload;
      state.createMilestonePayload = null;
    },
    createMilestoneFailure: (state, action) => {
      state.isCreatingMilestone = false;
      state.createMilestoneMessage = null;
      state.createMilestoneError = action.payload.message;
      state.createMilestonePayload = null;
    },

    // Delete Assessment
    deleteAssessment: (state, action) => {
      state.isDeletingAssessment = true;
      state.deleteAssessmentMessage = null;
      state.deleteAssessmentError = null;
    },
    deleteAssessmentSuccessful: (state, action) => {
      state.isDeletingAssessment = false;
      state.deleteAssessmentMessage = action.payload;
      state.deleteAssessmentError = null;
    },
    deleteAssessmentFailure: (state, action) => {
      state.isDeletingAssessment = false;
      state.deleteAssessmentMessage = null;
      state.deleteAssessmentError = action.payload.message;
    },

    // Add new reducers for WAFR Attachments
    getWafrAttachmentsUrl: (state, action) => {
      state.isGettingWafrAttachmentsUrl = true;
      state.getWafrAttachmentsUrlMessage = null;
      state.getWafrAttachmentsUrlError = null;
      state.wafrAttachmentsUrl = null;
    },
    getWafrAttachmentsUrlSuccessful: (state, action) => {
      state.isGettingWafrAttachmentsUrl = false;
      state.wafrAttachmentsUrl = action.payload;
      state.getWafrAttachmentsUrlMessage = "Attachment URL retrieved successfully!";
      state.getWafrAttachmentsUrlError = null;
    },
    getWafrAttachmentsUrlFailure: (state, action) => {
      state.isGettingWafrAttachmentsUrl = false;
      state.getWafrAttachmentsUrlMessage = null;
      state.getWafrAttachmentsUrlError = action.payload.message;
    },

    listWafrAttachments: (state, action) => {
      state.isListingWafrAttachments = true;
      state.listWafrAttachmentsMessage = null;
      state.listWafrAttachmentsError = null;
      state.wafrAttachmentsList = null;
    },
    listWafrAttachmentsSuccessful: (state, action) => {
      state.isListingWafrAttachments = false;
      state.wafrAttachmentsList = action.payload;
      state.listWafrAttachmentsMessage = "Attachments list retrieved successfully!";
      state.listWafrAttachmentsError = null;
    },
    listWafrAttachmentsFailure: (state, action) => {
      state.isListingWafrAttachments = false;
      state.listWafrAttachmentsMessage = null;
      state.listWafrAttachmentsError = action.payload.message;
    },

    downloadWafrAttachment: (state, action) => {
      state.isDownloadingWafrAttachment = true;
      state.downloadWafrAttachmentMessage = null;
      state.downloadWafrAttachmentError = null;
      state.wafrAttachmentDownloadUrl = null;
    },
    downloadWafrAttachmentSuccessful: (state, action) => {
      state.isDownloadingWafrAttachment = false;
      state.wafrAttachmentDownloadUrl = action.payload;
      state.downloadWafrAttachmentMessage = "Download URL retrieved successfully!";
      state.downloadWafrAttachmentError = null;
    },
    downloadWafrAttachmentFailure: (state, action) => {
      state.isDownloadingWafrAttachment = false;
      state.downloadWafrAttachmentMessage = null;
      state.downloadWafrAttachmentError = action.payload.message;
    },

    // Add these new reducers
    getWafrStakeholders: (state, action) => {
      state.isGettingWafrStakeholders = true;
      state.getWafrStakeholdersMessage = null;
      state.getWafrStakeholdersError = null;
      state.wafrStakeholders = null;
    },
    getWafrStakeholdersSuccessful: (state, action) => {
      state.isGettingWafrStakeholders = false;
      state.wafrStakeholders = action.payload;
      state.getWafrStakeholdersMessage = "Stakeholders retrieved successfully!";
      state.getWafrStakeholdersError = null;
    },
    getWafrStakeholdersFailure: (state, action) => {
      state.isGettingWafrStakeholders = false;
      state.getWafrStakeholdersMessage = null;
      state.getWafrStakeholdersError = action.payload.message;
    },

    putWafrStakeholders: (state, action) => {
      state.isPuttingWafrStakeholders = true;
      state.putWafrStakeholdersMessage = null;
      state.putWafrStakeholdersError = null;
      state.putWafrStakeholdersPayload = action.payload;
    },
    putWafrStakeholdersSuccessful: (state, action) => {
      state.isPuttingWafrStakeholders = false;
      state.putWafrStakeholdersMessage = "Stakeholder updated successfully!";
      state.putWafrStakeholdersPayload = null;
    },
    putWafrStakeholdersFailure: (state, action) => {
      state.isPuttingWafrStakeholders = false;
      state.putWafrStakeholdersMessage = null;
      state.putWafrStakeholdersError = action.payload.message;
      state.putWafrStakeholdersPayload = null;
    },
  },
});

export const {
  getWafrQuestionnaire,
  getWafrQuestionnaireSuccessful,
  getWafrQuestionnaireFailure,
  clearAllWafrQuestionnaireMessages,
  clearAllWafrQuestionnaireMessagesSuccessful,

  getAssessmentList,
  getAssessmentListSuccessful,
  getAssessmentListFailure,
  clearAllAssessmentMessages,

  updateWafrAnswer,
  updateWafrAnswerSuccessful,
  updateWafrAnswerFailure,

  postWafrAssessment,
  postWafrAssessmentSuccessful,
  postWafrAssessmentFailure,

  postAutomatedChecks,
  postAutomatedChecksSuccessful,
  postAutomatedChecksFailure,

  postCompleteAssessment,
  postCompleteAssessmentSuccessful,
  postCompleteAssessmentFailure,

  getFlaggedDetails,
  getFlaggedDetailsSuccessful,
  getFlaggedDetailsFailure,

  getWafrSummary,
  getWafrSummarySuccessful,
  getWafrSummaryFailure,

  getWafrReport,
  getWafrReportSuccessful,
  getWafrReportFailure,

  getMilestoneList,
  getMilestoneListSuccessful,
  getMilestoneListFailure,

  getMilestoneDetails,
  getMilestoneDetailsSuccessful,
  getMilestoneDetailsFailure,

  createMilestone,
  createMilestoneSuccessful,
  createMilestoneFailure,

  deleteAssessment,
  deleteAssessmentSuccessful,
  deleteAssessmentFailure,

  // Export new actions
  getWafrAttachmentsUrl,
  getWafrAttachmentsUrlSuccessful,
  getWafrAttachmentsUrlFailure,
  listWafrAttachments,
  listWafrAttachmentsSuccessful,
  listWafrAttachmentsFailure,
  downloadWafrAttachment,
  downloadWafrAttachmentSuccessful,
  downloadWafrAttachmentFailure,

  // Add to exports
  getWafrStakeholders,
  getWafrStakeholdersSuccessful,
  getWafrStakeholdersFailure,
  putWafrStakeholders,
  putWafrStakeholdersSuccessful,
  putWafrStakeholdersFailure,
} = wafrQuestionnaireSlice.actions;

export default wafrQuestionnaireSlice.reducer;
