import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const steps = [
  {
    label: 'Create Assessment',
    description: 'Start by creating a new workload assessment using the "Create Assessment" button.',
    icon: <AddCircleOutlineIcon />,
    optional: false
  },
  {
    label: 'CIS Assessment',
    description: 'Run the CIS assessment to evaluate security posture of your environment against CIS AWS Benchmark 3.0 standards',
    icon: <SecurityIcon />,
    optional: false
  },
  {
    label: 'Run Automated Checks',
    description: 'Run automated checks from the actions menu to validate configurations automatically.',
    icon: <PlayArrowIcon />,
    optional: false
  },
  {
    label: 'Complete Manual Assessment',
    description: 'Click “View” to answer all the relevant questions to evaluate the workload',
    icon: <AssignmentIcon />,
    optional: false
  },
  {
    label: 'Complete Assessment',
    description: 'Once done, mark the assessment as complete to lock it for review.',
    icon: <DoneAllIcon />,
    optional: false
  }
];

const ProcessGuidePanel = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          p: 0,
        }
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#F5F5F5",
          padding: "16px 24px",
          borderBottom: "1px solid #E0E0E0",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Assessment Process Guide
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider />

      {/* Content */}
      <Box sx={{ padding: "24px" }}>
        <Stepper orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label} active={true}>
              <StepLabel
                StepIconComponent={() => (
                  <Paper
                    elevation={0}
                    sx={{
                      width: 35,
                      height: 35,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'white'
                    }}
                  >
                    {step.icon}
                  </Paper>
                )}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body1" fontWeight="medium">
                    {step.label}
                  </Typography>
                  {step.optional && (
                    <Typography variant="caption" color="text.secondary">
                      (Optional)
                    </Typography>
                  )}
                </Box>
              </StepLabel>
              <StepContent>
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  sx={{ mt: 1, mb: 2 }}
                >
                  {step.description}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Drawer>
  );
};

export default ProcessGuidePanel; 