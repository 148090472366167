import React from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const SummaryContent = ({ wafrSummary }) => {
  if (!wafrSummary) return null;

  // Transform pillarSummaries data for the Questions table
  const questionSummaryData = wafrSummary.pillarSummaries.map((pillar) => ({
    pillar: pillar.pillarName,
    totalQuestions:
      pillar.riskCounts.unanswered +
      pillar.riskCounts.high +
      pillar.riskCounts.medium +
      pillar.riskCounts.none +
      pillar.riskCounts.not_APPLICABLE,
    unanswered: pillar.riskCounts.unanswered,
    high: pillar.riskCounts.high,
    medium: pillar.riskCounts.medium,
    none: pillar.riskCounts.none,
    notApplicable: pillar.riskCounts.not_APPLICABLE,
  }));

  // Calculate totals
  const totals = questionSummaryData.reduce(
    (acc, curr) => ({
      totalQuestions: acc.totalQuestions + curr.totalQuestions,
      unanswered: acc.unanswered + curr.unanswered,
      high: acc.high + curr.high,
      medium: acc.medium + curr.medium,
      none: acc.none + curr.none,
      notApplicable: acc.notApplicable + curr.notApplicable,
    }),
    {
      totalQuestions: 0,
      unanswered: 0,
      high: 0,
      medium: 0,
      none: 0,
      notApplicable: 0,
    }
  );

  // Add these console logs after the calculations
  console.log("Pillar Summaries:", wafrSummary.pillarSummaries);
  console.log("Question Summary Data:", questionSummaryData);
  console.log("Totals:", totals);

  const calculatePercentages = (data) => {
    if (!data || data.length === 0) return { notApplicablePercentage: '0%' };
    
    const total = data.length;
    const notApplicable = data.filter(item => item.risk === 'NOT_APPLICABLE').length;
    const notApplicablePercentage = ((notApplicable / total) * 100).toFixed(1);
    
    return {
      notApplicablePercentage: `${notApplicablePercentage}%`
    };
  };

  const tableStyles = {
    tableCell: {
      padding: "8px 16px",
      borderRight: "1px solid #e0e0e0",
      fontSize: "0.875rem",
    },
    headerCell: {
      fontWeight: "bold",
      backgroundColor: "#f5f5f5",
      padding: "12px 16px",
      borderRight: "1px solid #e0e0e0",
    },
    totalsRow: {
      backgroundColor: "#f5f5f5",
    },
  };

  return (
    <Box className="summary-content" sx={{ p: 3 }}>
      {/* Summary by Questions */}
      <Box mb={4}>
        <TableContainer component={Paper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={tableStyles.headerCell}>Pillar</TableCell>
                <TableCell align="center" sx={tableStyles.headerCell}>
                  Total Questions
                </TableCell>
                <TableCell align="center" sx={tableStyles.headerCell}>
                  Unanswered
                </TableCell>
                <TableCell align="center" sx={tableStyles.headerCell}>
                  High Risk Issues
                </TableCell>
                <TableCell align="center" sx={tableStyles.headerCell}>
                  Medium Risk Issues
                </TableCell>
                <TableCell align="center" sx={tableStyles.headerCell}>
                  None
                </TableCell>
                <TableCell align="center" sx={tableStyles.headerCell}>
                  Not Applicable
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionSummaryData.map((row, index) => (
                <TableRow key={index} hover>
                  <TableCell sx={tableStyles.tableCell}>{row.pillar}</TableCell>
                  <TableCell align="center" sx={tableStyles.tableCell}>
                    {row.totalQuestions}
                  </TableCell>
                  <TableCell align="center" sx={tableStyles.tableCell}>
                    {row.unanswered}
                  </TableCell>
                  <TableCell align="center" sx={tableStyles.tableCell}>
                    {row.high}
                  </TableCell>
                  <TableCell align="center" sx={tableStyles.tableCell}>
                    {row.medium}
                  </TableCell>
                  <TableCell align="center" sx={tableStyles.tableCell}>
                    {row.none}
                  </TableCell>
                  <TableCell align="center" sx={tableStyles.tableCell}>
                    {row.notApplicable}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={tableStyles.totalsRow}>
                <TableCell
                  sx={{ ...tableStyles.tableCell, fontWeight: "bold" }}
                >
                  Total (includes all pillars)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableStyles.tableCell, fontWeight: "bold" }}
                >
                  {totals.totalQuestions}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableStyles.tableCell, fontWeight: "bold" }}
                >
                  {totals.unanswered} (
                  {((totals.unanswered / totals.totalQuestions) * 100).toFixed(
                    2
                  )}
                  %)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableStyles.tableCell, fontWeight: "bold" }}
                >
                  {totals.high} (
                  {((totals.high / totals.totalQuestions) * 100).toFixed(2)}%)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableStyles.tableCell, fontWeight: "bold" }}
                >
                  {totals.medium} (
                  {((totals.medium / totals.totalQuestions) * 100).toFixed(2)}%)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableStyles.tableCell, fontWeight: "bold" }}
                >
                  {totals.none} (
                  {((totals.none / totals.totalQuestions) * 100).toFixed(2)}%)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableStyles.tableCell, fontWeight: "bold" }}
                >
                  {totals.notApplicable} ({((totals.notApplicable / totals.totalQuestions) * 100).toFixed(2)}%)
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Summary by Best Practices section will be added here */}
    </Box>
  );
};

export default SummaryContent;
