import React, { Component } from "react";

import {
  Row,
  Col,
  Button,
  Container,
  NavItem,
  NavLink,
  Progress,
  Label,
  FormGroup,
  TabContent,
  TabPane,
  Input,
  Alert,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

import {
  awsOnboard,
  clearAllAwsMarketplaceMessages,
} from "../../store/awsMarketplace/awsMarketplaceSlice";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { countryList, getRegionCode } from "../../utilities/countries";
import classnames from "classnames";
import logodark from "../../assets/images/Expanded_Colored_PoweredBy.png";
import { validateAddressWithAPI } from "../../helpers/addressValidation";

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Tooltip,
  IconButton,
  InputAdornment,
  Divider,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    this.state = {
      compliance: "",
      complianceError: true,
      activeTabProgress: 1,
      lastStepNumber: 4,
      progressValue: 0,
      progressIncrement: 0,
      orgName: "",
      orgCode: null,
      orgAddress: "",
      orgAddressValidationStatus: null,
      orgAddressValidationMessage: "",
      orgCity: "",
      orgState: "",
      orgZipCode: "",
      orgCountry: "",
      orgPhone: "",
      orgPhoneError: null,
      orgPrimaryContact: "",
      orgEmail: "",
      copybilling: false,
      copybillingForAccountInfo: false,
      orgBillingName: "",
      orgBillingEmail: "",
      orgBillingAddress: "",
      orgBillingState: "",
      orgBillingCity: "",
      orgBillingZipCode: "",
      orgBillingCountry: "",
      userFirstName: "",
      userLastName: "",
      userName: "",
      customerAwsAccountId: "",
      customerAwsAccountName: "",
      customerAwsAccountAddress: "",
      customerAwsAccountCity: "",
      customerAwsAccountCountry: "",
      customerAwsAccountEmail: "",
      customerAwsAccountPhoneNumber: "",
      customerAwsAccountState: "",
      customerAwsAccountZip: "",
      orgNameTouched: false,
      orgAddressTouched: false,
      orgCityTouched: false,
      orgStateTouched: false,
      orgZipCodeTouched: false,
      orgCountryTouched: false,
      orgPhoneTouched: false, //check
      orgPrimaryContactTouched: false,
      orgEmailTouched: false,
      orgBillingNameTouched: false,
      orgBillingEmailTouched: false,
      orgBillingAddressTouched: false,
      orgBillingCityTouched: false,
      orgBillingStateTouched: false,
      orgBillingZipCodeTouched: false,
      orgBillingCountryTouched: false,
      customerAwsAccountNameTouched: false,
      customerAwsAccountIdTouched: false,
      customerAwsAccountAddressTouched: false,
      customerAwsAccountCityTouched: false,
      customerAwsAccountStateTouched: false,
      customerAwsAccountZipTouched: false,
      customerAwsAccountCountryTouched: false,
      customerAwsAccountEmailTouched: false,
      userFirstNameTouched: false,
      userLastNameTouched: false,
      userEmailTouched: false,
      complianceOptions: [],

      customerAwsPhoneError: null,
      token: token || null,
      userEmail: "",
      step1Error: false,
      step2Error: false,
      step3Error: false,
      // step4Error: false,
      // checkbox: false,
      fulfillmentDone: false,
      disableField: false,
      customerAwsAddressValidationStatus: null,
      customerAwsAddressValidationMessage: "",
    };
  }

  toggleTabProgress = (tab) => {
    console.log("toggle tab progress tab = ", tab);
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= this.state.lastStepNumber) {
        this.setState({
          activeTabProgress: tab,
          progressValue: Math.ceil(tab * this.state.progressIncrement),
        });
      }
    }
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    this.setState({ token: token });

    console.log(
      "componentDidMount, props.fulfillmentResponse: ",
      this.props?.fulfillmentResponse
    );
    if (this.props?.fulfillmentResponse) {
      const progressIncrement =
        this.props.fulfillmentResponse?.code == 1001 ? 25 : 33.33;
      const activeTabProgress =
        this.props.fulfillmentResponse?.code == 1001 ? 1 : 2;
      const lastStepNumber =
        this.props.fulfillmentResponse?.code == 1001 ? 4 : 3;
      const disableField =
        this.props.fulfillmentResponse?.code == 1001 ? false : true;

      const complianceOptions =
        this.props.fulfillmentResponse?.complianceList || [];

      this.setState({
        fulfillmentDone: true,
        code: this.props.fulfillmentResponse?.code,
        complianceOptions,
        orgEmail:
          this.props.fulfillmentResponse?.code == 1001
            ? this.props.fulfillmentResponse?.email
            : this.props.fulfillmentResponse?.orgPrimaryEmail
            ? this.props.fulfillmentResponse?.orgPrimaryEmail
            : "",
        customerAwsAccountId: this.props.fulfillmentResponse
          ?.customerAwsAccountId
          ? this.props.fulfillmentResponse?.customerAwsAccountId
          : "",
        orgCode: this.props.fulfillmentResponse?.orgCode
          ? this.props.fulfillmentResponse?.code == 1001
            ? null
            : this.props.fulfillmentResponse?.orgCode
          : null,
        orgName: this.props.fulfillmentResponse?.orgName
          ? this.props.fulfillmentResponse?.orgName
          : "",
        orgPrimaryName: this.props.fulfillmentResponse?.orgPrimaryName,
        orgPrimaryContact: this.props.fulfillmentResponse?.orgPrimaryName
          ? this.props.fulfillmentResponse?.orgPrimaryName
          : "",
        orgAddress: this.props.fulfillmentResponse?.orgAddress
          ? this.props.fulfillmentResponse?.orgAddress
          : "",
        orgCity: this.props.fulfillmentResponse?.orgCity
          ? this.props.fulfillmentResponse?.orgCity
          : "",
        orgState: this.props.fulfillmentResponse?.orgState
          ? this.props.fulfillmentResponse?.orgState
          : "",
        orgCountry: this.props.fulfillmentResponse?.orgCountry
          ? this.props.fulfillmentResponse?.orgCountry
          : "",
        orgZipCode: this.props.fulfillmentResponse?.orgZip
          ? this.props.fulfillmentResponse?.orgZip
          : "",
        orgBillingName: this.props.fulfillmentResponse?.orgBillingName
          ? this.props.fulfillmentResponse?.orgBillingName
          : "",
        orgBillingEmail: this.props.fulfillmentResponse?.orgBillingEmail
          ? this.props.fulfillmentResponse?.orgBillingEmail
          : "",
        orgBillingAddress: this.props.fulfillmentResponse?.orgBillingAddress
          ? this.props.fulfillmentResponse?.orgBillingAddress
          : "",
        orgBillingState: this.props.fulfillmentResponse?.orgBillingState
          ? this.props.fulfillmentResponse?.orgBillingState
          : "",
        orgBillingCountry: this.props.fulfillmentResponse?.orgBillingCountry
          ? this.props.fulfillmentResponse?.orgBillingCountry
          : "",
        orgBillingZipCode: this.props.fulfillmentResponse?.orgBillingZip
          ? this.props.fulfillmentResponse?.orgBillingZip
          : "",
        orgBillingCity: this.props.fulfillmentResponse?.orgBillingCity
          ? this.props.fulfillmentResponse?.orgBillingCity
          : "",
        orgPhone: this.props.fulfillmentResponse?.phoneNumber
          ? this.props.fulfillmentResponse?.phoneNumber
          : "",
        // Set up Progress
        progressIncrement: progressIncrement,
        activeTabProgress: activeTabProgress,
        lastStepNumber: lastStepNumber,
        progressValue: Math.ceil(activeTabProgress * progressIncrement),
        disableField: disableField,
      });

      // Validate address on page load if orgAddress and orgCountry are populated
      if (this.state.orgAddress && this.state.orgCountry) {
        this.validateOrgAddress();
      }
    } else {
      this.setState({
        fulfillmentDone: false,
      });
    }
  }

  // handleChange = () => {
  //   if (this.state.checkbox) {
  //     this.setState({ checkbox: false });
  //   } else {
  //     this.setState({ checkbox: true });
  //   }
  // };

  handleComplianceChange = (event) => {
    this.setState({ compliance: event.target.value, complianceError: false });
  };

  handlePhoneChange = (value) => {
    let isInvalidResult;
    if (value === null || value === "" || value === undefined) {
      isInvalidResult = true;
      value = "";
    } else {
      isInvalidResult = !isPossiblePhoneNumber(value);
    }
    if (isInvalidResult) {
      this.setState({
        orgPhone: value,
        orgPhoneError: "Please enter a valid phone number",
      });
    } else {
      this.setState({ orgPhone: value, orgPhoneError: "" });
    }
  };

  handlePhoneBlur = () => {
    let isInvalidResult;
    if (!this.state.orgPhone) {
      isInvalidResult = true;
    } else {
      isInvalidResult = !isPossiblePhoneNumber(this.state.orgPhone);
    }
    if (isInvalidResult) {
      this.setState({ orgPhoneError: "Please enter a valid phone number" });
    } else {
      this.setState({ orgPhoneError: "" });
    }
  };

  handleCustomerAwsPhoneChange = (value) => {
    let isInvalidResult;
    if (value === null || value === "" || value === undefined) {
      isInvalidResult = true;
      value = "";
    } else {
      isInvalidResult = !isPossiblePhoneNumber(value);
    }
    if (isInvalidResult) {
      this.setState({
        customerAwsAccountPhoneNumber: value,
        customerAwsPhoneError: "Please enter a valid phone number",
      });
    } else {
      this.setState({
        customerAwsAccountPhoneNumber: value,
        customerAwsPhoneError: "",
      });
    }
  };

  handleCustomerAwsPhoneBlur = () => {
    let isInvalidResult;
    if (!this.state.customerAwsAccountPhoneNumber) {
      isInvalidResult = true;
    } else {
      isInvalidResult = !isPossiblePhoneNumber(
        this.state.customerAwsAccountPhoneNumber
      );
    }
    if (isInvalidResult) {
      this.setState({
        customerAwsPhoneError: "Please enter a valid phone number",
      });
    } else {
      this.setState({ customerAwsPhoneError: "" });
    }
  };

  handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "copybilling") {
      if (checked) {
        this.setState(
          {
            [name]: true,
            orgBillingName: this.state.orgName,
            orgBillingEmail: this.state.orgEmail,
            orgBillingAddress: this.state.orgAddress,
            orgBillingCity: this.state.orgCity,
            orgBillingState: this.state.orgState,
            orgBillingZipCode: this.state.orgZipCode,
            orgBillingCountry: this.state.orgCountry,
          },
          () => {
            // Trigger billing address validation after state is updated
            if (this.state.orgBillingAddress && this.state.orgBillingCountry) {
              this.validateBillingAddress();
            }
          }
        );
      } else {
        this.setState({
          [name]: false,
          orgBillingName: "",
          orgBillingEmail: "",
          orgBillingAddress: "",
          orgBillingState: "",
          orgBillingCity: "",
          orgBillingZipCode: "",
          orgBillingCountry: "",
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleInputChangeForAccountInfo = (e) => {
    const { name, value, checked } = e.target;
    if (name === "copybillingForAccountInfo") {
      if (checked) {
        this.setState({
          [name]: true,
          customerAwsAccountAddress: this.state.orgAddress,
          customerAwsAccountCountry: this.state.orgCountry,
          customerAwsAccountEmail: this.state.orgEmail,
          customerAwsAccountPhoneNumber: this.state.orgPhone,
          customerAwsAddressValidationStatus: this.state.orgAddressValidationStatus,
          customerAwsAddressValidationMessage: this.state.orgAddressValidationMessage,
          customerAwsAccountCity: this.state.orgCity,
          customerAwsAccountState: this.state.orgState,
          customerAwsAccountZip: this.state.orgZipCode,
        });
      } else {
        this.setState({
          [name]: false,
          customerAwsAccountAddress: "",
          customerAwsAccountCountry: "",
          customerAwsAccountEmail: "",
          customerAwsAccountPhoneNumber: "",
          customerAwsAddressValidationStatus: null,
          customerAwsAddressValidationMessage: "",
          customerAwsAccountCity: "",
          customerAwsAccountState: "",
          customerAwsAccountZip: "",
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (typeof this.props.organization === "string") {
      this.toggleTabProgress(this.state.activeTabProgress + 1);
    }
    if (
      this.props.awsOnboardingMessage &&
      this.state.activeTabProgress === this.state.lastStepNumber - 1
    ) {
      this.setState({ activeTabProgress: this.state.lastStepNumber });
      this.toggleTabProgress(this.state.activeTabProgress + 1);
      //Redirect to login after 10 seconds on success
      this.timer = setTimeout(() => {
        this.props.history.push("/login");
      }, 10000);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleSubmit = () => {
    const payload = {
      firstName: this.state.userFirstName, //"^[a-zA-Z]{1,25}$", message = "first Name should be only characters and between 1 to 25 characters"
      lastName: this.state.userLastName, // "^[a-zA-Z]{1,25}$", message = "last Name should be only characters and between 1 to 25 characters"
      orgPrimaryName: this.state.orgPrimaryContact, // allow space and digits
      orgPrimaryEmail: this.state.orgEmail, // "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
      userEmail: this.state.userEmail, // "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
      phoneNumber: this.state.orgPhone, //"^\\+\\d{1,3}(\\s?\\d{1,4}){1,4}$"
      orgAddress: this.state.orgAddress, // not empty
      orgZip: this.state.orgZipCode, //"\\d+"
      orgCity: this.state.orgCity, // not empty
      orgState: this.state.orgState, // not empty
      orgCountry: this.state.orgCountry, // not empty

      orgBillingAddress: this.state.orgAddress,
      orgBillingZip: this.state.orgZipCode,
      orgBillingCity: this.state.orgCity,
      orgBillingState: this.state.orgState,
      orgBillingCountry: this.state.orgCountry,
      orgBillingEmail: this.state.orgEmail,

      customerAwsAccountId: this.state.customerAwsAccountId, //"\\d{12}", message = "AWS account Number must be exactly 12 digits")
      customerAwsAccountName: this.state.customerAwsAccountName, // allow space and digits
      customerAwsAccountAddress: this.state.customerAwsAccountAddress, // not empty
      customerAwsAccountCity: this.state.customerAwsAccountCity, // not empty
      customerAwsAccountCountry: this.state.customerAwsAccountCountry, // not empty
      customerAwsAccountEmail: this.state.customerAwsAccountEmail, // "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
      customerAwsAccountPhoneNumber: this.state.customerAwsAccountPhoneNumber, // "^\\+\\d{1,3}(\\s?\\d{1,4}){1,4}$"
      customerAwsAccountState: this.state.customerAwsAccountState, // not empty
      customerAwsAccountZip: this.state.customerAwsAccountZip, // "\\d+", message = "Zip code must be a numeric value"
      complianceList: this.state.compliance,
      location: "NA", // not empty
      token: this.state.token, // not empty
      code: this.state.code, //regexp = "1001|1002", message = "code cannot be accepted"
      orgName: this.state.orgName, // not empty
      orgCode: this.state.orgCode,
    };
    console.log("payload: ", payload);
    this.props.awsOnboard(payload);
  };

  isStep1Valid = () => {
    const {
      orgName,
      orgAddress,
      orgCountry,
      orgPhone,
      orgPrimaryContact,
      orgEmail,
      orgAddressValidationStatus
    } = this.state;

    return (
      orgName !== "" &&
      orgAddress !== "" &&
      orgAddressValidationStatus === "valid" &&
      this.isValidCountry(orgCountry) &&
      this.isValidPhone(orgPhone) &&
      orgPrimaryContact !== "" &&
      this.isValidEmail(orgEmail)
    );
  };

  handleStep1 = () => {
    if (this.isStep1Valid()) {
      this.setState({ step1Error: false });
      return true;
    } else {
      this.setState({ step1Error: true });
      // return false;
    }
  };

  isStep2Valid = () => {
    const {
      customerAwsAccountId,
      customerAwsAccountName,
      customerAwsAccountAddress,
      customerAwsAccountCountry,
      customerAwsAccountPhoneNumber,
      customerAwsAccountEmail,
      customerAwsAddressValidationStatus,
      compliance
    } = this.state;

    const addressIsValid = this.state.copybillingForAccountInfo ? 
      true : // If copying from org address, it's already validated
      (customerAwsAccountAddress !== "" && 
       this.isValidCountry(customerAwsAccountCountry) && 
       customerAwsAddressValidationStatus === "valid");

    return (
      customerAwsAccountId !== "" &&
      this.isValidAccountNumber(customerAwsAccountId) &&
      this.isValidAccountName(customerAwsAccountName) &&
      compliance !== "" &&
      addressIsValid &&
      this.isValidPhone(customerAwsAccountPhoneNumber) &&
      this.isValidEmail(customerAwsAccountEmail)
    );
  };

  handleStep2 = () => {
    if (this.isStep2Valid()) {
      this.setState({ step2Error: false });
      return true;
    } else {
      this.setState({ step2Error: true });
      return false;
    }
  };

  isStep3Valid = () => {
    const { userFirstName, userLastName, userEmail } = this.state;
    if (
      userFirstName !== "" &&
      userLastName !== "" &&
      this.isValidEmail(userEmail)
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleStep3 = () => {
    if (this.isStep3Valid()) {
      this.setState({ step3Error: false });
      return true;
    } else {
      this.setState({ step3Error: true });
      return false;
    }
  };

  isCurrentStepValid = () => {
    // console.log("isCurrentStepValid code = ", this.state.code);
    if (this.state.code == 1001) {
      // console.log("isCurrentStepValid 1001");
      if (this.state.activeTabProgress === 1) {
        return this.isStep1Valid();
      } else if (this.state.activeTabProgress === 2) {
        return this.isStep2Valid();
      } else if (this.state.activeTabProgress === 3) {
        return this.isStep3Valid();
      } else {
        return false;
      }
    } else if (this.state.code == 1002) {
      console.log("isCurrentStepValid 1002");
      if (this.state.activeTabProgress === 2) {
        return this.isStep2Valid();
      } else {
        return true;
      }
    }
  };

  isValidPhone = (phone) => {
    let isValidPhone;
    if (phone === null) {
      isValidPhone = false;
    } else {
      isValidPhone = isPossiblePhoneNumber(phone);
    }
    return isValidPhone;
  };

  isValidName = (field) => {
    const nameRegex = /^[a-zA-Z\s]{1,50}$/; // Added \s to include spaces
    return nameRegex.test(field);
  };

  invalidNameErrorMessage = (field, fieldName) => {
    if (field.length === 0) {
      return `${fieldName} is required`;
    } else if (!this.isValidName(field)) {
      return `${fieldName} must be 1-50 letters or spaces`;
    } else {
      return "";
    }
  };

  isValidCompositeName = (field) => {
    const nameRegex = /^[a-zA-Z\s]{1,50}$/; // \s already included for spaces
    return nameRegex.test(field);
  };

  invalidCompositeNameErrorMessage = (field, fieldName) => {
    if (field.length === 0) {
      return `${fieldName} is required`;
    } else if (!this.isValidCompositeName(field)) {
      return `${fieldName} must be 1-50 letters or spaces`;
    } else {
      return "";
    }
  };

  isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  invalidEmailErrorMessage = (email) => {
    if (email.length === 0) {
      return "Email is required";
    } else if (!this.isValidEmail(email)) {
      return "Email is not valid";
    } else {
      return "";
    }
  };

  isValidCity = (city) => {
    const cityRegex = /^[A-Za-z ]+$/;
    return cityRegex.test(city);
  };

  invalidCityErrorMessage = (city) => {
    if (city.length === 0) {
      return "City is required";
    } else if (!this.isValidCity(city)) {
      return "Enter correct city";
    } else {
      return "";
    }
  };

  isValidOrgName = (name) => {
    const nameRegex = /^[a-zA-Z0-9\s\-@#$%&*!]{1,}$/;
    return nameRegex.test(name);
  };

  invalidOrgNameErrorMessage = (name, fieldName) => {
    if (name.length === 0) {
      return `${fieldName} is required`;
    } else if (!this.isValidOrgName(name)) {
      return `${fieldName} can have only 25 characters with alphabets, digits, or spaces`;
    } else {
      return "";
    }
  };

  isValidAccountNumber = (accountNumber) => {
    const accountNumberRegex = /\d{12}/;
    return accountNumberRegex.test(accountNumber);
  };

  invalidAccountNumberErrorMessage = (accountNumber) => {
    if (accountNumber.length === 0) {
      return "Account Number is required";
    } else if (!this.isValidAccountNumber(accountNumber)) {
      return "Enter 12 digits Account Number";
    } else {
      return "";
    }
  };

  isValidAccountName = (accountName) => {
    const accountNameRegex = /^.{1,50}$/;
    if (
      accountName === null ||
      accountName === undefined ||
      accountName === ""
    ) {
      return true;
    }
    return accountNameRegex.test(accountName);
  };

  invalidAccountNameErrorMessage = (accountName) => {
    if (!this.isValidAccountName(accountName)) {
      return "Enter correct Account Name";
    } else {
      return "";
    }
  };

  invalidComplianceErrorMessage = (compliance) => {
    if (compliance.length === 0) {
      return "Compliance is required";
    } else {
      return "";
    }
  };

  isValidState = (state) => {
    const stateRegex = /^[A-Za-z ]+$/;
    return stateRegex.test(state);
  };

  invalidStateErrorMessage = (state) => {
    if (state.length === 0) {
      return "State is required";
    } else if (!this.isValidState(state)) {
      return "Enter correct state";
    } else {
      return "";
    }
  };

  isValidZip = (zip) => {
    // const zipRegex = /\b\d{5,}\b/;
    // return zipRegex.test(zip);
    return true;
  };

  invalidZipErrorMessage = (zip) => {
    if (zip.length === 0) {
      return "Zip code is required";
    } else if (!this.isValidZip(zip)) {
      return "Enter correct zip code";
    } else {
      return "";
    }
  };

  isValidCountry = (country) => {
    return !(country.length === 0) && !(country === "Select Country");
  };

  invalidCountryErrorMessage = (country) => {
    // console.log("Selected country is ."+country+".");
    if (country.length === 0) {
      return "Country is required";
    } else if (country === "Select Country") {
      return "Select correct country";
    } else {
      return "";
    }
  };

  handleNext = () => {
    if (this.state.code == 1001) {
      if (
        this.state.activeTabProgress === this.state.lastStepNumber - 1 &&
        this.handleStep3()
      ) {
        this.handleSubmit();
      } else {
        if (
          (this.state.activeTabProgress === 1 && this.handleStep1()) ||
          (this.state.activeTabProgress === 2 && this.handleStep2())
        ) {
          this.toggleTabProgress(this.state.activeTabProgress + 1);
        }
      }
    } else if (this.state.code == 1002) {
      if (
        this.state.activeTabProgress === this.state.lastStepNumber - 1 &&
        this.handleStep2()
      ) {
        this.handleSubmit();
      } else {
        this.toggleTabProgress(this.state.activeTabProgress + 1);
      }
    }
  };

  validateOrgAddress = async () => {
    if (!this.state.orgAddress.trim() || !this.state.orgCountry) return;

    this.setState({
      orgAddressValidationStatus: null,
      orgAddressValidationMessage: "Validating address...",
    });

    const regionCode = getRegionCode(this.state.orgCountry);
    if (!regionCode) {
      this.setState({
        orgAddressValidationStatus: "invalid",
        orgAddressValidationMessage: "Please select a valid country first",
      });
      return;
    }

    try {
      const result = await validateAddressWithAPI(
        this.state.orgAddress,
        regionCode,
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      );

      this.setState({
        orgAddressValidationStatus: result.isValid ? "valid" : "invalid",
        orgAddressValidationMessage: result.message,
        orgAddress: result.formattedAddress || this.state.orgAddress,
        orgCity: result.city,
        orgState: result.state,
        orgZipCode: result.zip,
      });
    } catch (error) {
      this.setState({
        orgAddressValidationStatus: "invalid",
        orgAddressValidationMessage:
          "Failed to validate address. Please try again.",
      });
    }
  };

  validateCustomerAwsAddress = async () => {
    if (!this.state.customerAwsAccountAddress.trim() || !this.state.customerAwsAccountCountry) return;

    this.setState({
      customerAwsAddressValidationStatus: null,
      customerAwsAddressValidationMessage: "Validating address...",
    });

    const regionCode = getRegionCode(this.state.customerAwsAccountCountry);
    if (!regionCode) {
      this.setState({
        customerAwsAddressValidationStatus: "invalid",
        customerAwsAddressValidationMessage: "Please select a valid country first",
      });
      return;
    }

    try {
      const result = await validateAddressWithAPI(
        this.state.customerAwsAccountAddress,
        regionCode,
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      );

      this.setState({
        customerAwsAddressValidationStatus: result.isValid ? "valid" : "invalid",
        customerAwsAddressValidationMessage: result.message,
        customerAwsAccountAddress: result.formattedAddress || this.state.customerAwsAccountAddress,
        customerAwsAccountCity: result.city,
        customerAwsAccountState: result.state,
        customerAwsAccountZip: result.zip,
      });
    } catch (error) {
      this.setState({
        customerAwsAddressValidationStatus: "invalid",
        customerAwsAddressValidationMessage: "Failed to validate address. Please try again.",
      });
    }
  };

  render() {
    if (
      this.state.token === null ||
      this.state.token === undefined ||
      this.state.token === ""
    ) {
      return (
        <React.Fragment>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col>
                <div className="authentication-page-content auth-org-reg p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logodark} height="60" alt="logo" />
                              </Link>
                            </div>
                            {this.state.token === null ||
                            this.state.token === undefined ||
                            this.state.token === "" ? (
                              <>
                                <Typography
                                  style={{ color: "red", marginTop: "1rem" }}
                                >
                                  You need a token for using this feature.
                                </Typography>
                                <Typography style={{ color: "red" }}>
                                  It appears that URL does not contain a token.
                                </Typography>
                              </>
                            ) : !this.state.fulfillmentDone ? (
                              <>
                                <Typography
                                  style={{ color: "red", marginTop: "1rem" }}
                                >
                                  It seems like you've refreshed the page or
                                  missed a step in the onboarding process.
                                </Typography>
                                <Typography style={{ color: "red" }}>
                                  Please return to the signup page and complete
                                  the onboarding again. We appreciate your
                                  understanding.
                                </Typography>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col lg={6}>
              <div className="authentication-page-content auth-org-reg p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img src={logodark} height="60" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-5">
                            Please complete the steps below to onboard your
                            account with CloudCatcher.
                          </h4>
                        </div>

                        <div id="progrss-wizard" className="twitter-bs-wizard">
                          <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTabProgress === 1,
                                })}
                              >
                                <span className="step-number">01</span>
                                <span className="step-title">
                                  Organization Information
                                </span>
                              </NavLink>
                            </NavItem>

                            {/* <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTabProgress === 2,
                                })}
                              >
                                <span className="step-number">02</span>
                                <span className="step-title">
                                  <strong>Billing Information</strong>
                                </span>
                              </NavLink>
                            </NavItem> */}

                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTabProgress === 2,
                                })}
                              >
                                <span className="step-number">02</span>
                                <span className="step-title">
                                  Account Information
                                </span>
                              </NavLink>
                            </NavItem>

                            {this.state.code == 1001 && (
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTabProgress === 3,
                                  })}
                                >
                                  <span className="step-number">03</span>
                                  <span className="step-title">
                                    Create Admin User Account
                                  </span>
                                </NavLink>
                              </NavItem>
                            )}

                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active:
                                    this.state.activeTabProgress ===
                                    this.state.lastStepNumber,
                                })}
                              >
                                <span className="step-number">
                                  {this.state.code == 1001 ? "04" : "03"}
                                </span>
                                <span className="step-title">
                                  Account Created
                                </span>
                              </NavLink>
                            </NavItem>
                          </ul>

                          <div id="bar" className="mt-2">
                            <Progress
                              color="success"
                              striped
                              animated
                              value={this.state.progressValue}
                            />
                          </div>

                          {(this.state.step1Error ||
                            this.state.step2Error ||
                            this.state.step3Error) && (
                            <Alert className="mt-3 mb-0" color="danger">
                              Please fill all required fields
                            </Alert>
                          )}

                          <TabContent
                            activeTab={this.state.activeTabProgress}
                            className="twitter-bs-wizard-tab-content"
                          >
                            <TabPane tabId={1}>
                              <AvForm className="form-horizontal">
                                <Row>
                                  <Col lg="12">
                                    <FormGroup>
                                      <TextField
                                        name="orgName"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Organization Name *"
                                        value={this.state.orgName}
                                        placeholder="Organization Name"
                                        type="text"
                                        id="basicpill-orgName-input"
                                        className={`form-control ${
                                          this.state.step1Error &&
                                          this.state.orgName === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgNameTouched: true,
                                          })
                                        }
                                        error={
                                          this.state.orgNameTouched &&
                                          (this.state.orgName.length === 0 ||
                                            !this.isValidOrgName(
                                              this.state.orgName
                                            ))
                                        }
                                        helperText={
                                          this.state.orgNameTouched
                                            ? this.invalidOrgNameErrorMessage(
                                                this.state.orgName,
                                                "Organization name"
                                              )
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row
                                  className="address-row"
                                  style={{
                                    marginTop: "10px",
                                    display: "flex",
                                    alignItems: "center", // Align items vertically in the center
                                  }}
                                >
                                  <Col
                                    lg="6"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FormGroup
                                      style={{ marginBottom: 0, width: "100%" }}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel id="org-country-select-label">
                                          Country *
                                        </InputLabel>
                                        <Select
                                          name="orgCountry"
                                          label="Country"
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          id="basicpill-orgCountry-input"
                                          labelId="org-country-select-label"
                                          value={this.state.orgCountry}
                                          onChange={(e) => {
                                            this.handleInputChange(e);
                                            this.setState({
                                              orgAddress: "",
                                              orgAddressValidationStatus: null,
                                              orgAddressValidationMessage: "",
                                            });
                                          }}
                                          style={{ height: "40px" }} // Set a consistent height
                                          onBlur={() =>
                                            this.setState({
                                              orgCountryTouched: true,
                                            })
                                          }
                                          error={
                                            this.state.orgCountryTouched &&
                                            !this.isValidCountry(
                                              this.state.orgCountry
                                            )
                                          }
                                          helperText={
                                            this.state.orgCountryTouched
                                              ? this.invalidCountryErrorMessage(
                                                  this.state.orgCountry
                                                )
                                              : ""
                                          }
                                          disabled={this.state.disableField}
                                        >
                                          {countryList.map((country) => (
                                            <MenuItem
                                              key={country.code}
                                              value={country.name}
                                            >
                                              {country.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="6"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FormGroup
                                      style={{ marginBottom: 0, width: "100%" }}
                                    >
                                      <TextField
                                        name="orgAddress"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Address *"
                                        value={this.state.orgAddress}
                                        placeholder={
                                          !this.state.orgCountry
                                            ? "Please select a country first"
                                            : "Enter complete address"
                                        }
                                        type="text"
                                        id="basicpill-orgAddress-input"
                                        className={`form-control ${
                                          this.state.step1Error &&
                                          this.state.orgAddress === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={(e) => {
                                          this.setState({
                                            orgAddress: e.target.value,
                                            orgAddressValidationStatus: null,
                                            orgAddressValidationMessage: "",
                                          });
                                        }}
                                        onBlur={() => {
                                          this.validateOrgAddress();
                                          this.setState({
                                            orgAddressTouched: true,
                                          });
                                        }}
                                        error={
                                          this.state
                                            .orgAddressValidationStatus ===
                                          "invalid"
                                        }
                                        helperText={
                                          this.state.orgAddressTouched
                                            ? this.state.orgAddress.length === 0
                                              ? "Address is required"
                                              : ""
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                        style={{ height: "40px" }} // Set a consistent height
                                        InputProps={{
                                          endAdornment: this.state
                                            .orgAddressValidationStatus ===
                                            "valid" && (
                                            <i
                                              className="mdi mdi-check-circle"
                                              style={{
                                                position: "absolute",
                                                right: "10px",
                                                color: "green",
                                              }}
                                            />
                                          ),
                                        }}
                                      />
                                      {this.state
                                        .orgAddressValidationMessage && (
                                        <Typography
                                          variant="caption"
                                          color={
                                            this.state
                                              .orgAddressValidationStatus ===
                                            "valid"
                                              ? "success"
                                              : "error"
                                          }
                                          sx={{ mt: 0.5, display: "block" }}
                                        >
                                          {
                                            this.state
                                              .orgAddressValidationMessage
                                          }
                                        </Typography>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                  <Col lg="4">
                                    <FormGroup>
                                      <PhoneInput
                                        className={
                                          !this.state.orgPhoneError
                                            ? "phone-input"
                                            : "phone-input invalid"
                                        }
                                        id="basicpill-orgPhone-input"
                                        name="orgPhone"
                                        aria-describedby="Phone Number"
                                        placeholder="Phone Number"
                                        value={this.state.orgPhone}
                                        maxLength={16}
                                        onChange={this.handlePhoneChange}
                                        onBlur={this.handlePhoneBlur}
                                        required
                                        disabled={this.state.disableField}
                                      />
                                      {this.state.orgPhoneError ? (
                                        <div
                                          className="phone-error"
                                          style={{
                                            fontSize: "80%",
                                            marginTop: "0.25rem",
                                          }}
                                        >
                                          {this.state.orgPhoneError}
                                        </div>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <TextField
                                        name="orgPrimaryContact"
                                        value={this.state.orgPrimaryContact}
                                        placeholder="Primary Contact Name"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Primary Contact Name *"
                                        id="basicpill-orgPrimaryContact-input"
                                        className={`form-control ${
                                          this.state.step1Error &&
                                          this.state.orgPrimaryContact === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgPrimaryContactTouched: true,
                                          })
                                        }
                                        error={
                                          this.state.orgPrimaryContactTouched &&
                                          (this.state.orgPrimaryContact
                                            .length === 0 ||
                                            !this.isValidCompositeName(
                                              this.state.orgPrimaryContact
                                            ))
                                        }
                                        helperText={
                                          this.state.orgPrimaryContactTouched
                                            ? this.invalidCompositeNameErrorMessage(
                                                this.state.orgPrimaryContact,
                                                "Primary contact Name"
                                              )
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <TextField
                                        name="orgEmail"
                                        value={this.state.orgEmail}
                                        placeholder="Email"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Email *"
                                        id="basicpill-orgEmail-input"
                                        className={`form-control ${
                                          this.state.step1Error &&
                                          this.state.orgEmail === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgEmailTouched: true,
                                          })
                                        }
                                        inputProps={{
                                          type: "email",
                                        }}
                                        error={
                                          this.state.orgEmailTouched &&
                                          (this.state.orgEmail.length === 0 ||
                                            !this.isValidEmail(
                                              this.state.orgEmail
                                            ))
                                        }
                                        helperText={
                                          this.state.orgEmailTouched
                                            ? this.invalidEmailErrorMessage(
                                                this.state.orgEmail
                                              )
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="custom-control custom-checkbox mb-0">
                                      <Input
                                        type="checkbox"
                                        id="termsAndConditionsCheckbox2"
                                        className="custom-control-input"
                                        onChange={() => false}
                                        checked={this.state.customchk}
                                        disabled={this.state.disableField}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </TabPane>
                            {/* <TabPane tabId={2}>
                              <AvForm className="form-horizontal">
                                <Row>
                                  <Col lg="12">
                                    <div className="custom-control custom-checkbox mb-3">
                                      <Input
                                        type="checkbox"
                                        id="billingCheckbox"
                                        className="custom-control-input"
                                        name="copybilling"
                                        checked={this.state.copybilling}
                                        onChange={this.handleInputChange}
                                        disabled={this.state.disableField}
                                      />
                                      <Label
                                        htmlFor="billingCheckbox"
                                        className="custom-control-label"
                                      >
                                        Same as organization information
                                      </Label>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup>
                                      <TextField
                                        name="orgBillingName"
                                        value={this.state.orgBillingName}
                                        placeholder="Organization Name"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Organization Name *"
                                        id="basicpill-orgBillingName-input"
                                        className={`form-control ${
                                          this.state.step2Error &&
                                          this.state.orgBillingName === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgBillingNameTouched: true,
                                          })
                                        }
                                        error={
                                          this.state.orgBillingNameTouched &&
                                          (this.state.orgBillingName.length ===
                                          0
                                            ? true
                                            : false)
                                        }
                                        helperText={
                                          this.state.orgBillingNameTouched
                                            ? this.state.orgBillingName
                                                .length === 0
                                              ? "Organization name is required"
                                              : ""
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <TextField
                                        name="orgBillingEmail"
                                        value={this.state.orgBillingEmail}
                                        placeholder="Email"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Email *"
                                        id="basicpill-orgBillingEmail-input"
                                        className={`form-control ${
                                          this.state.step2Error &&
                                          this.state.orgBillingEmail === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgBillingEmailTouched: true,
                                          })
                                        }
                                        inputProps={{
                                          type: "email",
                                        }}
                                        error={
                                          this.state.orgBillingEmailTouched &&
                                          (this.state.orgBillingEmail.length ===
                                            0 ||
                                            !this.isValidEmail(
                                              this.state.orgBillingEmail
                                            ))
                                        }
                                        helperText={this.invalidEmailErrorMessage(
                                          this.state.orgBillingEmail
                                        )}
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <FormGroup>
                                      <TextField
                                        name="orgBillingAddress"
                                        value={this.state.orgBillingAddress}
                                        placeholder="Address"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Address *"
                                        id="basicpill-orgBillingAddress-input"
                                        className={`form-control ${
                                          this.state.step2Error &&
                                          this.state.orgBillingAddress === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgBillingAddressTouched: true,
                                          })
                                        }
                                        error={
                                          this.state.orgBillingAddressTouched &&
                                          (this.state.orgBillingAddress
                                            .length === 0
                                            ? true
                                            : false)
                                        }
                                        helperText={
                                          this.state.orgBillingAddressTouched
                                            ? this.state.orgBillingAddress
                                                .length === 0
                                              ? "Address is required"
                                              : ""
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="4">
                                    <FormGroup>
                                      <TextField
                                        name="orgBillingCity"
                                        value={this.state.orgBillingCity}
                                        placeholder="City"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="City *"
                                        id="basicpill-orgBillingCity-input"
                                        className={`form-control ${
                                          this.state.step2Error &&
                                          this.state.orgBillingCity === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgBillingCityTouched: true,
                                          })
                                        }
                                        error={
                                          this.state.orgBillingCityTouched &&
                                          (this.state.orgBillingCity.length ===
                                            0 ||
                                            !this.isValidCity(
                                              this.state.orgBillingCity
                                            ))
                                        }
                                        helperText={
                                          this.state.orgBillingCityTouched
                                            ? this.invalidCityErrorMessage(
                                                this.state.orgBillingCity
                                              )
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <TextField
                                        name="orgBillingState"
                                        value={this.state.orgBillingState}
                                        placeholder="State"
                                        type="text"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="State *"
                                        id="basicpill-orgBillingState-input"
                                        className={`form-control ${
                                          this.state.step2Error &&
                                          this.state.orgBillingState === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgBillingStateTouched: true,
                                          })
                                        }
                                        error={
                                          this.state.orgBillingStateTouched &&
                                          (this.state.orgBillingState.length ===
                                            0 ||
                                            !this.isValidState(
                                              this.state.orgBillingState
                                            ))
                                        }
                                        helperText={
                                          this.state.orgBillingStateTouched
                                            ? this.invalidStateErrorMessage(
                                                this.state.orgBillingState
                                              )
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <TextField
                                        name="orgBillingZipCode"
                                        value={this.state.orgBillingZipCode}
                                        placeholder="Zip/Postal Code"
                                        type="number"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        label="Zip Code *"
                                        id="basicpill-orgBillingZipCode-input"
                                        className={`form-control ${
                                          this.state.step2Error &&
                                          this.state.orgBillingZipCode === ""
                                            ? "is-invalid-border"
                                            : null
                                        }`}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgBillingZipCodeTouched: true,
                                          })
                                        }
                                        error={
                                          this.state.orgBillingZipCodeTouched &&
                                          (this.state.orgBillingZipCode
                                            .length === 0 ||
                                            !this.isValidZip(
                                              this.state.orgBillingZipCode
                                            ))
                                        }
                                        helperText={
                                          this.state.orgBillingZipCodeTouched
                                            ? this.invalidZipErrorMessage(
                                                this.state.orgBillingZipCode
                                              )
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                  <Col lg="12">
                                    <FormControl fullWidth>
                                      <InputLabel id="org-country-select-label">
                                        Country *
                                      </InputLabel>
                                      <Select
                                        name="orgBillingCountry"
                                        label="Country"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        id="basicpill-orgBillingCountry-input"
                                        labelId="billing-country-select-label"
                                        value={this.state.orgBillingCountry}
                                        onChange={this.handleInputChange}
                                        onBlur={() =>
                                          this.setState({
                                            orgBillingCountryTouched: true,
                                          })
                                        }
                                        error={
                                          this.state.orgBillingCountryTouched &&
                                          !this.isValidCountry(
                                            this.state.orgBillingCountry
                                          )
                                        }
                                        helperText={
                                          this.state.orgBillingCountryTouched
                                            ? this.invalidCountryErrorMessage(
                                                this.state.orgBillingCountry
                                              )
                                            : ""
                                        }
                                        disabled={this.state.disableField}
                                      >
                                        {countryList.map((country) => {
                                          return (
                                            <MenuItem
                                              key={country}
                                              value={country}
                                            >
                                              {country}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Col>
                                </Row>
                              </AvForm>
                            </TabPane> */}

                            <TabPane tabId={2}>
                              {/* <h5 className="mb-4">Set up Licensing</h5> */}
                              <div>
                                <AvForm className="form-horizontal">
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <TextField
                                          name="customerAwsAccountName"
                                          value={
                                            this.state.customerAwsAccountName
                                          }
                                          placeholder="Account Name"
                                          type="text"
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          label="Account Name"
                                          id="basicpill-customerAwsAccountName-input"
                                          className={`form-control ${
                                            this.state.step2Error &&
                                            this.state
                                              .customerAwsAccountName === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          onBlur={() =>
                                            this.setState({
                                              customerAwsAccountNameTouched: true,
                                            })
                                          }
                                          error={
                                            this.state
                                              .customerAwsAccountNameTouched &&
                                            this.state.customerAwsAccountName
                                              .length !== 0 &&
                                            !this.isValidAccountName(
                                              this.state.customerAwsAccountName
                                            )
                                          }
                                          helperText={
                                            this.state
                                              .customerAwsAccountNameTouched
                                              ? this.invalidAccountNameErrorMessage(
                                                  this.state
                                                    .customerAwsAccountName
                                                )
                                              : ""
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup>
                                        <TextField
                                          name="customerAwsAccountId"
                                          value={
                                            this.state.customerAwsAccountId
                                          }
                                          placeholder="Account Number"
                                          type="number"
                                          variant="outlined"
                                          margin="dense"
                                          size="small"
                                          label="Account Number *"
                                          disabled={true}
                                          id="basicpill-customerAwsAccountId-input"
                                          className={`form-control ${
                                            this.state.step2Error &&
                                            this.state.customerAwsAccountId ===
                                              ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          onBlur={() =>
                                            this.setState({
                                              customerAwsAccountIdTouched: true,
                                            })
                                          }
                                          error={
                                            this.state
                                              .customerAwsAccountIdTouched &&
                                            (this.state.customerAwsAccountId
                                              .length === 0 ||
                                              !this.isValidAccountNumber(
                                                this.state.customerAwsAccountId
                                              ))
                                          }
                                          helperText={
                                            this.state
                                              .customerAwsAccountIdTouched
                                              ? this.invalidAccountNumberErrorMessage(
                                                  this.state
                                                    .customerAwsAccountId
                                                )
                                              : ""
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div
                                    style={{
                                      backgroundColor: "#EFEFEF",
                                      marginTop: "1rem",
                                      borderRadius: "4px",
                                      padding: "1rem",
                                    }}
                                  >
                                    <Row>
                                      <Col lg="12">
                                        <div className="custom-control custom-checkbox mb-3">
                                          <Input
                                            type="checkbox"
                                            id="billingCheckboxForAccountInfo"
                                            className="custom-control-input"
                                            name="copybillingForAccountInfo"
                                            checked={this.state.copybillingForAccountInfo}
                                            onChange={this.handleInputChangeForAccountInfo}
                                          />
                                          <Label
                                            htmlFor="billingCheckboxForAccountInfo"
                                            className="custom-control-label"
                                          >
                                            Same as organization information
                                          </Label>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="address-row" style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
                                      <Col lg="6" style={{ display: "flex", alignItems: "center" }}>
                                        <FormGroup style={{ marginBottom: 0, width: "100%" }}>
                                          <FormControl fullWidth>
                                            <InputLabel id="customer-aws-country-select-label">Country *</InputLabel>
                                            <Select
                                              name="customerAwsAccountCountry"
                                              label="Country"
                                              variant="outlined"
                                              margin="dense"
                                              size="small"
                                              id="basicpill-customerAwsAccountCountry-input"
                                              labelId="customer-aws-country-select-label"
                                              value={this.state.customerAwsAccountCountry}
                                              onChange={(e) => {
                                                this.handleInputChange(e);
                                                this.setState({
                                                  customerAwsAccountAddress: "",
                                                  customerAwsAddressValidationStatus: null,
                                                  customerAwsAddressValidationMessage: "",
                                                });
                                              }}
                                              style={{ height: "40px" }}
                                              disabled={this.state.copybillingForAccountInfo}
                                            >
                                              {countryList.map((country) => (
                                                <MenuItem key={country.code} value={country.name}>
                                                  {country.name}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6" style={{ display: "flex", alignItems: "center" }}>
                                        <FormGroup style={{ marginBottom: 0, width: "100%" }}>
                                          <TextField
                                            name="customerAwsAccountAddress"
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            label="Address *"
                                            value={this.state.customerAwsAccountAddress}
                                            placeholder={!this.state.customerAwsAccountCountry ? "Please select a country first" : "Enter complete address"}
                                            type="text"
                                            id="basicpill-customerAwsAccountAddress-input"
                                            onChange={(e) => {
                                              this.setState({
                                                customerAwsAccountAddress: e.target.value,
                                                customerAwsAddressValidationStatus: null,
                                                customerAwsAddressValidationMessage: "",
                                              });
                                            }}
                                            onBlur={() => {
                                              if (!this.state.copybillingForAccountInfo) {
                                                this.validateCustomerAwsAddress();
                                              }
                                              this.setState({
                                                customerAwsAccountAddressTouched: true,
                                              });
                                            }}
                                            error={this.state.customerAwsAddressValidationStatus === "invalid"}
                                            helperText={
                                              this.state.customerAwsAccountAddressTouched
                                                ? this.state.customerAwsAccountAddress.length === 0
                                                  ? "Address is required"
                                                  : ""
                                                : ""
                                            }
                                            disabled={!this.state.customerAwsAccountCountry || this.state.copybillingForAccountInfo}
                                            style={{ height: "40px" }}
                                            InputProps={{
                                              endAdornment: this.state.customerAwsAddressValidationStatus === "valid" && (
                                                <i
                                                  className="mdi mdi-check-circle"
                                                  style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    color: "green",
                                                  }}
                                                />
                                              ),
                                            }}
                                          />
                                          {this.state.customerAwsAddressValidationMessage && (
                                            <Typography
                                              variant="caption"
                                              color={
                                                this.state.customerAwsAddressValidationStatus === "valid"
                                                  ? "success"
                                                  : "error"
                                              }
                                              sx={{ mt: 0.5, display: "block" }}
                                            >
                                              {this.state.customerAwsAddressValidationMessage}
                                            </Typography>
                                          )}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                      <Col lg="6">
                                        <FormGroup>
                                          <PhoneInput
                                            className={!this.state.customerAwsPhoneError ? "phone-input" : "phone-input invalid"}
                                            id="basicpill-customerAwsPhone-input"
                                            name="customerAwsAccountPhoneNumber"
                                            aria-describedby="Phone Number"
                                            placeholder="Phone Number"
                                            value={this.state.customerAwsAccountPhoneNumber}
                                            maxLength={16}
                                            onChange={this.handleCustomerAwsPhoneChange}
                                            onBlur={this.handleCustomerAwsPhoneBlur}
                                            required
                                            disabled={this.state.copybillingForAccountInfo}
                                          />
                                          {this.state.customerAwsPhoneError && (
                                            <div className="phone-error" style={{ fontSize: "80%", marginTop: "0.25rem" }}>
                                              {this.state.customerAwsPhoneError}
                                            </div>
                                          )}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <TextField
                                            name="customerAwsAccountEmail"
                                            value={this.state.customerAwsAccountEmail}
                                            placeholder="Account Email"
                                            type="email"
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            label="Account Email *"
                                            id="basicpill-customerAwsAccountEmail-input"
                                            onChange={this.handleInputChange}
                                            onBlur={() => this.setState({ customerAwsAccountEmailTouched: true })}
                                            error={
                                              this.state.customerAwsAccountEmailTouched &&
                                              (this.state.customerAwsAccountEmail.length === 0 ||
                                                !this.isValidEmail(this.state.customerAwsAccountEmail))
                                            }
                                            helperText={
                                              this.state.customerAwsAccountEmailTouched
                                                ? this.invalidEmailErrorMessage(this.state.customerAwsAccountEmail)
                                                : ""
                                            }
                                            disabled={this.state.copybillingForAccountInfo}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>
                                  <Row style={{ marginTop: "1rem" }}>
                                    <Col md="12">
                                      <p>
                                        Select Compliance Standard from
                                        available options below:{" "}
                                      </p>
                                      <FormGroup>
                                        <FormControl
                                          component="fieldset"
                                          error={this.state.complianceError}
                                          fullWidth
                                        >
                                          <InputLabel id="org-country-select-label">
                                            Compliance *
                                          </InputLabel>
                                          <Select
                                            labelId="compliance-label"
                                            label="Compliance"
                                            id="compliance"
                                            name="compliance"
                                            size="small"
                                            value={this.state.compliance}
                                            onChange={
                                              this.handleComplianceChange
                                            }
                                            renderValue={(selected) => selected}
                                            MenuProps={{
                                              PaperProps: {
                                                style: {
                                                  maxHeight: 400,
                                                },
                                              },
                                            }}
                                          >
                                            {Object.entries(
                                              this.state.complianceOptions
                                            ).flatMap(([key, value], index) => [
                                              <MenuItem key={key} value={key}>
                                                <Typography variant="body1">
                                                  {`${key.toUpperCase()} - ${value}`}
                                                </Typography>
                                              </MenuItem>,
                                              index <
                                                Object.keys(
                                                  this.state.complianceOptions
                                                ).length -
                                                  1 && (
                                                <Divider
                                                  key={`${key}-divider`}
                                                />
                                              ),
                                            ])}
                                          </Select>
                                        </FormControl>
                                      </FormGroup>
                                      <p>
                                        Please contact us at{" "}
                                        <a href="mailto:Support.CloudCatcher@cloudnovatech.com">
                                          support.cloudcatcher@cloudnovetech.com
                                        </a>{" "}
                                        to add additional compliance standards
                                        after your account has been onboarded
                                        with CloudCatcher
                                      </p>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </div>
                            </TabPane>

                            {this.state.code == 1001 && (
                              <TabPane tabId={3}>
                                <div>
                                  <AvForm className="form-horizontal">
                                    <Row>
                                      <Col md="6">
                                        <FormGroup>
                                          <TextField
                                            name="userFirstName"
                                            value={this.state.userFirstName}
                                            placeholder="First name"
                                            type="text"
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            label="First Name *"
                                            id="basicpill-userFirstName-input"
                                            className={`form-control ${
                                              this.state.step3Error &&
                                              this.state.userFirstName === ""
                                                ? "is-invalid-border"
                                                : null
                                            }`}
                                            onChange={this.handleInputChange}
                                            onBlur={() =>
                                              this.setState({
                                                userFirstNameTouched: true,
                                              })
                                            }
                                            error={
                                              this.state.userFirstNameTouched &&
                                              (this.state.userFirstName
                                                .length === 0 ||
                                                !this.isValidName(
                                                  this.state.userFirstName
                                                ))
                                            }
                                            helperText={
                                              this.state.userFirstNameTouched
                                                ? this.invalidNameErrorMessage(
                                                    this.state.userFirstName,
                                                    "First name"
                                                  )
                                                : ""
                                            }
                                            FormHelperTextProps={{
                                              style: { whiteSpace: "nowrap" },
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup>
                                          <TextField
                                            name="userLastName"
                                            value={this.state.userLastName}
                                            placeholder="Last name"
                                            type="text"
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            label="Last Name *"
                                            id="basicpill-userLastName-input"
                                            className={`form-control ${
                                              this.state.step3Error &&
                                              this.state.userLastName === ""
                                                ? "is-invalid-border"
                                                : null
                                            }`}
                                            onChange={this.handleInputChange}
                                            onBlur={() =>
                                              this.setState({
                                                userLastNameTouched: true,
                                              })
                                            }
                                            error={
                                              this.state.userLastNameTouched &&
                                              (this.state.userLastName
                                                .length === 0 ||
                                                !this.isValidName(
                                                  this.state.userLastName
                                                ))
                                            }
                                            helperText={
                                              this.state.userLastNameTouched
                                                ? this.invalidNameErrorMessage(
                                                    this.state.userLastName,
                                                    "Last name"
                                                  )
                                                : ""
                                            }
                                            FormHelperTextProps={{
                                              style: { whiteSpace: "nowrap" },
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <FormGroup>
                                          <TextField
                                            name="userEmail"
                                            value={this.state.userEmail}
                                            placeholder="Email"
                                            type="text"
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            label="Email *"
                                            id="basicpill-userEmail-input"
                                            className={`form-control ${
                                              this.state.step3Error &&
                                              this.state.userEmail === ""
                                                ? "is-invalid-border"
                                                : null
                                            }`}
                                            onChange={this.handleInputChange}
                                            onBlur={() =>
                                              this.setState({
                                                userEmailTouched: true,
                                              })
                                            }
                                            inputProps={{
                                              type: "email",
                                            }}
                                            error={
                                              this.state.userEmailTouched &&
                                              (this.state.userEmail.length ===
                                                0 ||
                                                !this.isValidEmail(
                                                  this.state.userEmail
                                                ))
                                            }
                                            helperText={
                                              this.state.userEmailTouched
                                                ? this.invalidEmailErrorMessage(
                                                    this.state.userEmail
                                                  )
                                                : ""
                                            }
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Tooltip
                                                    title="User credentials will be sent to this email upon submission."
                                                    placement="top"
                                                  >
                                                    <IconButton size="small">
                                                      <InfoIcon fontSize="small" />
                                                    </IconButton>
                                                  </Tooltip>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    {/* <Row>
                                      <Col lg="12">
                                        <div className="custom-control custom-checkbox mb-0">
                                          <Input
                                            type="checkbox"
                                            id="termsAndConditionsCheckbox"
                                            className="custom-control-input"
                                            onChange={this.handleChange}
                                            checked={this.state.checkbox}
                                          />
                                          <TermsOfUse />
                                        </div>
                                      </Col>
                                    </Row> */}
                                  </AvForm>
                                </div>
                              </TabPane>
                            )}

                            <TabPane tabId={this.state.code == 1001 ? 4 : 3}>
                              <div className="row justify-content-center">
                                <Col lg="11">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                    </div>
                                    <div>
                                      <h5>{"Onboarding Done Successfully"}</h5>
                                      <p className="text-muted">
                                        {this.props.awsOnboardingMessage}
                                      </p>
                                      <h5>{"Need Help?"}</h5>
                                      <p className="text-muted">
                                        If you do not receive an email with the
                                        login credentials within the next hour
                                        or for any other questions, please
                                        contact us at{" "}
                                        <a href="mailto:support.cloudcatcher@cloudnovatech.com">
                                          support.cloudcatcher@cloudnovatech.com
                                        </a>
                                        .
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </TabPane>

                            {this.props.awsOnboardingMessage && (
                              <Alert className="mt-3 mb-0" color="success">
                                You will be redirected to Login page in 10
                                Seconds.
                              </Alert>
                            )}
                            {this.props.awsOnboardingError && (
                              <Alert className="mt-3 mb-0" color="danger">
                                {this.props.awsOnboardingError}
                              </Alert>
                            )}
                          </TabContent>
                          {this.state.activeTabProgress !==
                          this.state.lastStepNumber ? (
                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                              <li
                                className={
                                  this.state.activeTabProgress === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                {this.state.activeTabProgress !== 1 ? (
                                  <Button
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="button"
                                    onClick={() => {
                                      this.toggleTabProgress(
                                        this.state.activeTabProgress - 1
                                      );
                                    }}
                                    disabled={
                                      this.state.activeTabProgress ===
                                      this.state.lastStepNumber + 1
                                    }
                                  >
                                    Previous
                                  </Button>
                                ) : null}
                              </li>
                              <li
                                className={
                                  this.state.activeTabProgress ===
                                  this.state.lastStepNumber + 1
                                    ? "next disabled"
                                    : "next"
                                }
                              >
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="button"
                                  onClick={this.handleNext}
                                  disabled={
                                    !this.isCurrentStepValid() ||
                                    this.props.isAwsOnboarding ||
                                    this.props.awsOnboardingMessage
                                  }
                                >
                                  {this.state.activeTabProgress !==
                                  this.state.lastStepNumber - 1
                                    ? "Next"
                                    : `${
                                        this.props.loading
                                          ? "Submitting ..."
                                          : "Submit"
                                      }`}
                                </Button>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                        <div className="mt-5 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="font-weight-medium text-primary"
                            >
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="authentication-bg auth-org-reg"></div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    fulfillmentResponse,
    isAwsOnboarding,
    awsOnboardingMessage,
    awsOnboardingError,
  } = state.awsMarketplace;
  return {
    fulfillmentResponse,
    isAwsOnboarding,
    awsOnboardingMessage,
    awsOnboardingError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    awsOnboard: (awsOnboardPayload) => {
      dispatch(awsOnboard(awsOnboardPayload));
    },
    clearAllMessages: () => {
      dispatch(clearAllAwsMarketplaceMessages());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
);
