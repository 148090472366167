import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  List,
  ListItemText,
  ListItemButton,
  Divider,
  Button,
} from "@mui/material";
import {
  getWafrSummary,
  getWafrQuestionnaire,
  getWafrReport,
} from "../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import SummaryContent from "./components/SummaryContent";
import QuestionsSummary from './components/QuestionsSummary';
import ResourcesSummary from './components/ResourcesSummary';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFTemplate from './components/PDFTemplate';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Reports = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { workloadId } = useParams();
  const [selectedSection, setSelectedSection] = useState("summary");
  const [selectedSubSection, setSelectedSubSection] = useState("Workload Assessment Summary");
  const [selectedPillar, setSelectedPillar] = useState(null);
  const history = useHistory();

  const {
    wafrSummary,
    isGettingWafrSummary,
    getWafrSummaryError,
    wafrQuestionnaire,
    isGettingWafrQuestionnaire,
    getWafrQuestionnaireError,
    wafrReport,
    isGettingWafrReport,
    getWafrReportError,
  } = useSelector((state) => state.wafrQuestionnaire);

  const { orgCode, accCode, accNumber, assessmentName, frameworkName, ownerName, approverName, workloadArn } = location.state || {};

  // Create refs for each pillar section
  const pillarRefs = React.useMemo(() => ({
    'Operational Excellence': React.createRef(),
    'Security': React.createRef(),
    'Reliability': React.createRef(),
    'Performance Efficiency': React.createRef(),
    'Cost Optimization': React.createRef(),
    'Sustainability': React.createRef(),
  }), []);

  const contentRef = useRef(null);

  useEffect(() => {
    if (workloadId && orgCode && accCode) {
      dispatch(getWafrSummary({ workloadId, accNumber }));
      dispatch(getWafrQuestionnaire({ workloadId, orgCode, accCode }));
      dispatch(getWafrReport({ workloadId, accNumber }));
    }
  }, [dispatch, workloadId, orgCode, accCode, accNumber]);

  const isLoading = isGettingWafrSummary || isGettingWafrQuestionnaire || isGettingWafrReport;

  const tableOfContents = {
    summary: {
      title: "Table of Contents",
      subsections: [
        {
          title: "Workload Assessment Summary",
          pillars: []
        },
        {
          title: "Workload Assessment Summary By Questions",
          pillars: Object.keys(wafrQuestionnaire || {}),
          type: 'questions'
        },
        {
          title: "Workload Assessment Summary Details with Resources",
          pillars: Object.keys(wafrReport || {}),
          type: 'resources'
        }
      ]
    }
  };

  const handlePillarClick = (pillar, subsectionTitle, type) => {
    setSelectedSection("summary");
    setSelectedSubSection(subsectionTitle);
    setSelectedPillar({ name: pillar, type: type });
    
    setTimeout(() => {
      pillarRefs[pillar]?.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }, 100);
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    setSelectedSubSection(tableOfContents[section].subsections[0].title);
  };

  const handleSubSectionClick = (subsection) => {
    setSelectedSubSection(subsection);
  };

  // Add individual retry handlers
  const handleRetrySummary = () => {
    dispatch(getWafrSummary({ workloadId, accNumber }));
  };

  const handleRetryQuestionnaire = () => {
    dispatch(getWafrQuestionnaire({ workloadId, orgCode, accCode }));
  };

  const handleRetryReport = () => {
    dispatch(getWafrReport({ workloadId, accNumber }));
  };

  const handleBack = () => {
    history.push('/reports/');
  };

  // Render error state for individual sections
  const renderErrorState = (error, retryHandler, title) => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        p: 3,
        backgroundColor: "#fef8f8",
        border: "1px solid #f44336",
        borderRadius: "8px",
        my: 2
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
      <Typography variant="h6" fontWeight="bold" color="#f44336" sx={{ mb: 1 }}>
        Failed to load {title}
      </Typography>
      <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
        {error}
      </Typography>
      <Button
        variant="outlined"
        startIcon={<RefreshIcon />}
        onClick={retryHandler}
        sx={{
          textTransform: "none",
          fontWeight: "bold",
          borderColor: "#1976d2",
          color: "#1976d2",
          "&:hover": {
            borderColor: "#1565c0",
            backgroundColor: "#e3f2fd",
          },
        }}
      >
        Retry
      </Button>
    </Box>
  );

  const renderContent = () => {
    if (selectedSection === "summary") {
      if (selectedSubSection === "Workload Assessment Summary") {
        return getWafrSummaryError ? (
          renderErrorState(
            getWafrSummaryError, 
            handleRetrySummary, 
            "Summary Data"
          )
        ) : (
          <SummaryContent wafrSummary={wafrSummary} />
        );
      } else if (selectedSubSection === "Workload Assessment Summary By Questions") {
        return getWafrQuestionnaireError ? (
          renderErrorState(
            getWafrQuestionnaireError, 
            handleRetryQuestionnaire, 
            "Questions Data"
          )
        ) : (
          <QuestionsSummary data={wafrQuestionnaire} pillarRefs={pillarRefs} />
        );
      } else if (selectedSubSection === "Workload Assessment Summary Details with Resources") {
        return getWafrReportError === "Flagged resources do not exist" ? (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Workload Assessment Summary Details with Resources
            </Typography>
            <Typography color="text.secondary">
              No resources exist for this assessment.
            </Typography>
          </Box>
        ) : getWafrReportError ? (
          renderErrorState(
            getWafrReportError, 
            handleRetryReport, 
            "Resources Data"
          )
        ) : (
          <ResourcesSummary data={wafrReport} pillarRefs={pillarRefs} />
        );
      }
    }
    return null;
  };

  const renderPDFButton = () => (
    <PDFDownloadLink
      document={
        <PDFTemplate 
          data={{
            wafrSummary,
            wafrQuestionnaire,
            wafrReport,
            assessmentName,
            getWafrReportError,
            accNumber,
            frameworkName,
            ownerName,
            approverName,
            workloadArn
          }}
        />
      }
      fileName={`${assessmentName || 'Workload-Assessment'}-Report.pdf`}
    >
      {({ blob, url, loading, error }) => (
        <Button
          variant="contained"
          startIcon={<FileDownloadIcon />}
          disabled={loading || isLoading || 
            (getWafrSummaryError) || 
            (getWafrQuestionnaireError) || 
            (getWafrReportError && getWafrReportError !== "Flagged resources do not exist")}
          sx={{
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#1565c0'
            }
          }}
        >
          {loading ? 'Generating PDF...' : 'Export PDF'}
        </Button>
      )}
    </PDFDownloadLink>
  );

  const renderHeaderButtons = () => (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{
          textTransform: "none",
          fontWeight: "bold",
          borderColor: "#1976d2",
          color: "#1976d2",
          "&:hover": {
            borderColor: "#1565c0",
            backgroundColor: "#e3f2fd",
          },
        }}
      >
        Back
      </Button>
      {renderPDFButton()}
    </Box>
  );

  return (
    <div className="page-content">
      <Box sx={{ 
        display: "flex",
        height: "calc(100vh - 160px)",
        backgroundColor: "#F9F9F9",
        p: 3,
      }}>
        {/* Left Sidebar - Navigation */}
        <Paper
          elevation={2}
          sx={{
            width: 300,
            overflow: "auto",
            borderRadius: "8px",
            mr: 3,
          }}
        >
          <List component="nav" sx={{ p: 0 }}>
            {Object.entries(tableOfContents).map(([sectionKey, section]) => (
              <React.Fragment key={sectionKey}>
                <ListItemButton
                  onClick={() => handleSectionClick(sectionKey)}
                  sx={{
                    backgroundColor: selectedSection === sectionKey ? "#e3f2fd" : "inherit",
                  }}
                >
                  <ListItemText primary={section.title} />
                </ListItemButton>
                
                {selectedSection === sectionKey && (
                  <List component="div" disablePadding>
                    {section.subsections.map((subsection) => (
                      <React.Fragment key={subsection.title}>
                        <ListItemButton
                          onClick={() => handleSubSectionClick(subsection.title)}
                          sx={{ pl: 4 }}
                        >
                          <ListItemText 
                            primary={subsection.title}
                            primaryTypographyProps={{
                              fontSize: "0.9rem",
                              fontWeight: selectedSubSection === subsection.title ? "bold" : "normal",
                            }}
                          />
                        </ListItemButton>
                        {subsection.pillars.map((pillar) => (
                          <ListItemButton
                            key={`${pillar}-${subsection.type}`}
                            onClick={() => handlePillarClick(pillar, subsection.title, subsection.type)}
                            sx={{
                              pl: 6,
                              py: 0.5,
                              backgroundColor: 
                                selectedPillar?.name === pillar && 
                                selectedPillar?.type === subsection.type 
                                  ? "#f5f5f5" 
                                  : "inherit",
                            }}
                          >
                            <ListItemText
                              primary={pillar}
                              primaryTypographyProps={{
                                fontSize: "0.85rem",
                              }}
                            />
                          </ListItemButton>
                        ))}
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </React.Fragment>
            ))}
          </List>
        </Paper>

        {/* Right Content Area */}
        <Paper
          elevation={2}
          sx={{
            flex: 1,
            overflow: "auto",
            borderRadius: "8px",
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* Header with Export Button */}
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid #e0e0e0",
              backgroundColor: "#f5f5f5",
              position: 'sticky',
              top: 0,
              zIndex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {assessmentName || "Workload Assessment"}
            </Typography>
            {renderHeaderButtons()}
          </Box>

          {/* Content */}
          <Box 
            ref={contentRef}
            sx={{ 
              flex: 1, 
              overflow: 'auto',
              backgroundColor: 'white',
              minHeight: '100%'
            }}
          >
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <CircularProgress />
              </Box>
            ) : (
              renderContent()
            )}
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default Reports;
