import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button, Tooltip } from "@mui/material";

import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { GlobalFilter } from "../../IACManagement/components/GlobalFilter";

import styles from "./../style.module.css";
import loadingGif from "./../../../assets/images/aws/loadingGif.gif";
import { getReleaseDetailsRequest, clearAllReleaseMessages } from "../../../store/actions";

const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "File Name",
    accessor: "fileName",
  }
];

const UrlsTable = (props) => {
  console.log("##### UrlsTable called");
  const releaseNotes = props.releaseNotes;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data: releaseNotes }, useGlobalFilter, useSortBy);
  const { globalFilter } = state;
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  const handleFilenameClick = (rowIndex, fileName) => {
    setCurrentRowIndex(rowIndex);
    props.getReleaseDetails(fileName);
  };

  useEffect(() => {
    if(props.releaseDetails){
      window.open(props.releaseDetails, '_blank');
      props.clearAllReleaseMessages();
    }
  }, [props.releaseDetails]);

  return (
    <div>
      <div>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      <table {...getTableProps()} className={styles.releaseTable}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onMouseEnter={() => setHoveredColumn(column.id)}
                  onMouseLeave={() => setHoveredColumn(null)}
                  style={{ minWidth: "100px", height: "30px" }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownwardIcon sx={{ fontSize: 16 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ fontSize: 16 }} />
                      )
                    ) : column.accessor && hoveredColumn === column.id ? (
                      <ArrowUpwardIcon
                        style={{ color: "gray" }}
                        sx={{ fontSize: 16 }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows &&
            rows?.map((item, index) => {
              prepareRow(item);
              return (
                <React.Fragment key={item.original.id}>
                  <tr {...item.getRowProps()}>
                    <td>{item.original.id}</td>
                    <td>
                      <Tooltip title="Click to download" arrow>
                        <Button
                          style={{
                            position: "relative",
                            textTransform: "unset",
                            textAlign: "left",
                          }}
                          disabled={
                            currentRowIndex === index &&
                            props.isReleaseDetailsGetting
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFilenameClick(index, item.original.fileName);
                          }}
                        >
                          {currentRowIndex === index &&
                            props.isReleaseDetailsGetting && (
                              <img
                                src={loadingGif}
                                height={24}
                                alt="Loading Spinner"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                            )}
                          {item.original.fileName.substring(
                            item.original.fileName.indexOf("/") + 1,
                            item.original.fileName.lastIndexOf(".pdf")
                          )}
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
  } = state.releaseNotes;

  return {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReleaseDetails: (fileName) => {
      dispatch(getReleaseDetailsRequest(fileName));
    },
    clearAllReleaseMessages: () => {
      dispatch(clearAllReleaseMessages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UrlsTable);