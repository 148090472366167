import { useMemo } from "react";
import jwt_decode from "jwt-decode";
import {
  useAccounts,
  useFetchKibanaDashboards,
  useOrganization,
} from "./GetDataFromStore";
import accessToken from "../../../helpers/jwt-token-access/accessToken";

// Custom hook to get accessible user accounts
export const useAccessibleUserAccounts = () => {
  const accounts = useAccounts(); // Always call useAccounts

  const accessibleAccounts = useMemo(() => {
    let username = null;
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      try {
        const decodedToken = jwt_decode(JSON.parse(authUser).idToken);
        username = decodedToken.email;
      } catch (error) {
        console.error("Error decoding token:", error);
        return []; // Return an empty array if there's an error
      }
    }

    if (accounts && username) {
      const filteredAccounts = accounts.filter(
        (x) =>
          !x.accountNumber.includes("INIT00000001") &&
          !x.accountNumber.includes("TEST") &&
          x.accountNumber.length === 12
      );

      return filteredAccounts.filter(
        (acct) =>
          acct.status === "ACTIVE" &&
          acct.listusr?.some((user) => user.userName === username)
      );
    }

    return []; // Return an empty array if conditions are not met
  }, [accounts]);

  return accessibleAccounts;
};

// Custom hook to check if user has active accounts
export const useHasActiveUserAccounts = () => {
  const accessibleAccounts = useAccessibleUserAccounts();
  return useMemo(() => accessibleAccounts.length > 0, [accessibleAccounts]);
};

// account with the oldest activation_date
export const useOldestActivatedAccount = () => {
  const accessibleAccounts = useAccessibleUserAccounts();

  const oldestAccount = useMemo(() => {
    if (!accessibleAccounts || accessibleAccounts.length === 0) {
      return null; // Return null if there are no accessible accounts
    }

    // Filter out accounts with null or undefined activation_date
    const accountsWithActivationDate = accessibleAccounts.filter(
      (account) => account.activation_date
    );

    if (accountsWithActivationDate.length === 0) {
      return null; // Return null if no accounts have an activation_date
    }

    // Sort accounts by activation_date in ascending order and return the first one
    return accountsWithActivationDate.reduce((oldest, current) => {
      const oldestDate = new Date(oldest.activation_date);
      const currentDate = new Date(current.activation_date);
      return currentDate < oldestDate ? current : oldest;
    });
  }, [accessibleAccounts]);

  return oldestAccount;
};

// Custom hook to check if Cost Insight is enabled
export const useIsCostInsightEnabledForOneUserAccount = () => {
  const accessibleAccounts = useAccessibleUserAccounts();

  return useMemo(() => {
    return accessibleAccounts.some((account) =>
      account?.subscription?.addons?.some(
        (addon) => addon.name === "Cost Insight" && addon.enabled
      )
    );
  }, [accessibleAccounts]);
};

// Custom hook to check if Cost Optimization is enabled
export const useIsCostOptimizationEnabledForOneUserAccount = () => {
  const accessibleAccounts = useAccessibleUserAccounts();

  return useMemo(() => {
    return accessibleAccounts.some((account) =>
      account?.subscription?.addons?.some(
        (addon) => addon.name === "Cost Optimization" && addon.enabled
      )
    );
  }, [accessibleAccounts]);
};

// Custom hook to check if Event Insight is enabled
export const useIsEventInsightEnabledForOneUserAccount = () => {
  const accessibleAccounts = useAccessibleUserAccounts();

  return useMemo(() => {
    return accessibleAccounts.some((account) =>
      account?.subscription?.addons?.some(
        (addon) => addon.name === "Event Insight" && addon.enabled
      )
    );
  }, [accessibleAccounts]);
};

function calculateRelativeTime(lastRunDate) {
  if (!lastRunDate) return "now-30d/d"; // Default fallback

  const lastRun = new Date(lastRunDate.replace(" ", "T") + ":00Z"); // Ensure ISO format
  const now = new Date();

  // Calculate difference in milliseconds and convert to days
  const diffDays = Math.round((now - lastRun) / (1000 * 60 * 60 * 24));

  // Ensure minimum 1-day difference
  return `now-${diffDays}d/d`;
}

// Get Kibana Dashboard URL
export const useCreateKibanaDashboardURL = (
  dashboardKey,
  params = undefined
) => {
  console.log("🚀 ~ useCreateKibanaDashboardURL ~ dashboardKey:", dashboardKey);
  console.log("Params :: ", params);

  const { dashboards, isLoading, errorMessage } = useFetchKibanaDashboards();

  const dashboardId = useMemo(() => {
    if (!dashboards || isLoading || errorMessage) {
      return null;
    }
    // Find the specific dashboard ID using the provided dashboardKey
    return dashboards.find((dashboard) => dashboard.key === dashboardKey)
      ?.value;
  }, [dashboards, isLoading, errorMessage, dashboardKey]);

  console.log("🚀 ~ dashboardId ~ dashboardId:", dashboardId);

  const organization = useOrganization();
  console.log("🚀 ~ useCreateKibanaDashboardURL ~ organization:", organization);

  const dashboardURL = useMemo(() => {
    if (!dashboardId) {
      return null;
    }

    const token = accessToken();
    const VIEW_URL = process.env.REACT_APP_ELASTICSERACH;
    const SPACE_URL = organization.orgcode.toLowerCase();

    const QUERY_PARAMS = `?authorization=${token}`;
    let URL_SUFFIX = process.env.REACT_APP_ELASTICSERACH_URL_SUFFIX;

    if (
      dashboardKey === "Event_Insight_New" ||
      dashboardKey === "MItre_event Insight" ||
      dashboardKey === "Landing Page" ||
      dashboardKey === "Resource Dashboard" ||
      dashboardKey === "Compliance" ||
      dashboardKey === "Asset Explorer" ||
      dashboardKey === "Resource Explorer_2" ||
      dashboardKey === "Security_2"
    ) {
      // URL_SUFFIX = process.env.REACT_APP_ELASTICSERACH_EVENT_INSIGHT_URL_SUFFIX;
      URL_SUFFIX =
        "?embed=true&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(timeRestore:!t,viewMode:view)&show-query-input=true&show-time-filter=true";
    } else if (
      dashboardKey === "CostOptimizationhub" ||
      dashboardKey === "Hub Details" ||
      dashboardKey === "Other Opportunities"
    ) {
      URL_SUFFIX =
        process.env.REACT_APP_ELASTICSERACH_COST_OPTIMIZATION_URL_SUFFIX;
    } else if (
      dashboardKey === "cur_2_dashboard" ||
      dashboardKey === "CUR_2_insight_Allocation" ||
      dashboardKey === "RI/SP Dashboard" ||
      dashboardKey === "Data Transfer"
    ) {
      URL_SUFFIX = process.env.REACT_APP_ELASTICSERACH_COST_INSIGHT_URL_SUFFIX;
    } else if (dashboardKey === "App Tagging") {
      URL_SUFFIX =
        "?embed=true&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-1d/d,to:now-1d/d))&_a=(timeRestore:!t,viewMode:view)&show-query-input=true&show-time-filter=true";
    } else if (
      dashboardKey === "Compliance_Trend_Dashboard" ||
      dashboardKey === "Security_Trend_Dashboard"
    ) {
      URL_SUFFIX =
        "?embed=true&_g=(refreshInterval:(pause:!t,value:0),time:(from:now/y,to:now))&_a=(timeRestore:!t,viewMode:view)&show-query-input=true&show-time-filter=true";
    } else if (dashboardKey === "cis_waf") {
      console.log("Account Number in Cis_waf :: ", params);
      const fromDate = calculateRelativeTime(params.lastRunDate);

      URL_SUFFIX = `?embed=true&_g=(refreshInterval:(pause:!t,value:60000),time:(from:${fromDate},to:now))&_a=(filters:!((meta:(key:'accountNumber.keyword',params:(query:'${params.accountNumber}'),type:'phrase'),query:(match_phrase:(accountNumber.keyword:'${params.accountNumber}')))),timeRestore:!t,viewMode:view)&show-query-input=true&show-time-filter=true`;
    } else if (
      dashboardKey === "cis_waf_Report" ||
      dashboardKey === "cis_waf_auditor"
    ) {
      URL_SUFFIX =
        "?embed=true&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-1d/d,to:now))&_a=(timeRestore:!t,viewMode:view)&show-query-input=true&show-time-filter=true";
    } else {
      URL_SUFFIX = process.env.REACT_APP_ELASTICSERACH_URL_SUFFIX;
    }

    const VIEW_DASHBOARD_URL = `${process.env.REACT_APP_ELASTICSEARCH_VIEW_DASHBOARD_URL}${dashboardId}`;

    return `${VIEW_URL}/s/${SPACE_URL}/app/dashboards${QUERY_PARAMS}${VIEW_DASHBOARD_URL}${URL_SUFFIX}`;
  }, [dashboardId]);

  console.log("🚀 ~ dashboardURL ~ dashboardURL:", dashboardURL);

  return useMemo(
    () => ({ dashboardURL, isLoading, errorMessage }),
    [dashboardURL, isLoading, errorMessage]
  );
};
