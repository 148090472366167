import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  LinearProgress,
  Chip,
  Tooltip,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getAssessmentList } from "../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import { useOrganization } from "../../components/Common/HelperComponents/GetDataFromStore";
import { useHistory } from "react-router-dom";
import { formatUtcDate } from "../../helpers/formatUtcDate";

const WafrReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organization = useOrganization();

  console.log("~~~organization : ", organization);

  // Selectors to access data from the Redux store
  const { assessmentList, isGettingAssessmentList, getAssessmentListError } =
    useSelector((state) => state.wafrQuestionnaire);

  // Add state for column visibility
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const saved = localStorage.getItem("wafrReportGridColumns");
    return saved
      ? JSON.parse(saved)
      : {
          "Assessment Name": true,
          "Account Number": true,
          "Progress percentage": true,
          "Assessment Approver": true,
          "Assessment Owner": true,
          Status: true,
          "Framework(s)": false,
          "Workload ID": false,
          lastModifiedDate: false,
          lastModifiedBy: false,
          createdDate: false,
          createdBy: false,
          PrimaryRegion: false,
          Regions: false,
        };
  });

  // Save column visibility to localStorage when it changes
  useEffect(() => {
    if (columnVisibility) {
      localStorage.setItem(
        "wafrReportGridColumns",
        JSON.stringify(columnVisibility)
      );
    }
  }, [columnVisibility]);

  // Fetch the assessment list when the component mounts
  useEffect(() => {
    dispatch(getAssessmentList());
  }, [dispatch]);

  const handleViewClick = (rowData) => {
    console.log("Navigating to workload:", rowData["Workload ID"]); // Debug log
    history.push({
      pathname: `/reports/${rowData["Workload ID"]}`,
      state: {
        workloadId: rowData["Workload ID"],
        orgCode: rowData["OrgCode"],
        accCode: rowData["Account Code"],
        accNumber: rowData["Account Number"],
        assessmentName: rowData["Assessment Name"],
        frameworkName: rowData["Framework(s)"],
        ownerName: rowData["Assessment Owner"],
        approverName: rowData["Assessment Approver"],
        workloadArn: rowData["WorkloadArn"],
      },
    });
  };

  // Define DataGrid Columns
  const columns = [
    {
      field: "Assessment Name",
      headerName: "Assessment Name",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Account Number",
      headerName: "Account Number",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Progress percentage",
      headerName: "Progress",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          sx={{ py: 1 }}
        >
          <Box sx={{ flex: 1, mr: 1, maxWidth: "80%" }}>
            <LinearProgress
              variant="determinate"
              value={params.value}
              sx={{
                width: "100%",
                height: 8,
                borderRadius: 4,
                backgroundColor: "#e0e0e0",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#1976d2",
                },
              }}
            />
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "500",
              color: "#555",
              minWidth: "35px",
            }}
          >
            {params.value}%
          </Typography>
        </Box>
      ),
    },
    {
      field: "Assessment Approver",
      headerName: "Assessment Approver",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Assessment Owner",
      headerName: "Assessment Owner",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Framework(s)",
      headerName: "Framework(s)",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 200,
      valueGetter: (params) => formatUtcDate(params),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lastModifiedDate",
      headerName: "Last Modified Date",
      width: 200,
      valueGetter: (params) => formatUtcDate(params),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lastModifiedBy",
      headerName: "Last Modified By",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Workload ID",
      headerName: "Workload ID",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "WorkloadArn",
      headerName: "Workload Arn",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          {params.value === "COMPLETED" ? (
            <Chip
              label="Completed"
              size="small"
              sx={{
                backgroundColor: "#e8f5e9",
                color: "#2e7d32",
                border: "1px solid #a5d6a7",
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  px: 1,
                },
              }}
            />
          ) : (
            <Chip
              label="In Progress"
              size="small"
              sx={{
                backgroundColor: "#fff3e0",
                color: "#ed6c02",
                border: "1px solid #ffb74d",
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  px: 1,
                },
              }}
            />
          )}
        </Box>
      ),
    },
    {
      field: "PrimaryRegion",
      headerName: "Primary Region",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.value ? (
          <Chip
            label={params.value}
            size="small"
            sx={{
              bgcolor: "#E3F2FD",
              borderColor: "#1976D2",
              "& .MuiChip-label": {
                color: "#1976D2",
                fontWeight: 500,
              },
            }}
          />
        ) : (
          "-"
        ),
    },
    {
      field: "Regions",
      headerName: "Regions",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.value) return "-";

        const regions = params.value.split(",").filter(Boolean);
        const isCollapsed = params.colDef.width < 250; // Adjust this threshold as needed

        return (
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              py: 0.5,
            }}
          >
            {isCollapsed ? (
              <Tooltip
                title={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      maxWidth: 300,
                      maxHeight: 200,
                      overflowY: "auto",
                      p: 2,
                      backdropFilter: "blur(8px)",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    {regions.map((region, index) => (
                      <Chip
                        key={index}
                        label={region.trim()}
                        size="small"
                        sx={{
                          backgroundColor: "#e3f2fd",
                          color: "#1976d2",
                          fontSize: "0.75rem",
                          height: "24px",
                          maxWidth: "100%",
                          ".MuiChip-label": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                    ))}
                  </Box>
                }
                arrow
                placement="top"
              >
                <Chip
                  label={`${regions.length} region${
                    regions.length !== 1 ? "s" : ""
                  }`}
                  size="small"
                  sx={{
                    backgroundColor: "#e3f2fd",
                    color: "#1976d2",
                    fontSize: "0.75rem",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            ) : (
              regions.map((region, index) => (
                <Chip
                  key={index}
                  label={region.trim()}
                  size="small"
                  sx={{
                    backgroundColor: "#e3f2fd",
                    color: "#1976d2",
                    fontSize: "0.75rem",
                    height: "24px",
                    maxWidth: "95%",
                    ".MuiChip-label": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              ))
            )}
          </Box>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const row = params.row;
        const rowId = params.row.id; // Unique ID for the row

        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="8px"
          >
            {/* View Button */}
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleViewClick(row)}
              sx={{
                textTransform: "none",
                fontWeight: 500,
                borderRadius: "20px",
                padding: "2px 12px",
                borderColor: "#1976d2",
                color: "#1976d2",
                "&:hover": {
                  backgroundColor: "#E3F2FD",
                  borderColor: "#1565c0",
                },
              }}
            >
              View
            </Button>
          </Box>
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          p: 2,
          borderBottom: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Left side - Search */}
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput.split(",").map((value) => value.trim())
          }
          placeholder="Search Assessments"
          sx={{
            width: 250,
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              backgroundColor: "#F1F1F1",
              height: 36,
            },
          }}
          debounceMs={300}
        />

        {/* Right side - Built-in Columns Button */}
        <Box>
          <GridToolbarColumnsButton
            sx={{
              textTransform: "none",
              color: "#1976d2",
              border: "1px solid #1976d2",
              borderRadius: "4px",
              height: 36,
              "&:hover": {
                backgroundColor: "#e3f2fd",
                borderColor: "#1565c0",
              },
              "& .MuiSvgIcon-root": {
                marginRight: 1,
              },
            }}
          />
        </Box>
      </GridToolbarContainer>
    );
  }

  return (
    <Box
      sx={{
        padding: "1.5rem 2rem",
        mt: "5rem",
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6" fontWeight="bold" color="#333">
          Reports{" "}
        </Typography>
      </Box>

      {/* DataGrid */}
      {isGettingAssessmentList ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : getAssessmentListError ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "50vh",
            backgroundColor: "#fef8f8",
            border: "1px solid #f44336",
            borderRadius: "8px",
            padding: "2rem",
            textAlign: "center",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#f44336"
            sx={{ mb: 1 }}
          >
            Something Went Wrong
          </Typography>
          <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
            {getAssessmentListError}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={() => dispatch(getAssessmentList())}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#1976d2",
              color: "#1976d2",
              "&:hover": {
                borderColor: "#1565c0",
                backgroundColor: "#e3f2fd",
              },
            }}
          >
            Retry
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <DataGrid
            rows={
              assessmentList
                ?.filter((assessment) => assessment.Status === "COMPLETED")
                ?.map((row, index) => ({ id: index, ...row })) || []
            }
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: columnVisibility,
              },
            }}
            onColumnVisibilityModelChange={(newModel) => {
              setColumnVisibility(newModel);
            }}
            loading={isGettingAssessmentList}
            rowHeight={40}
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              // height: "calc(100vh - 200px)",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold", // Make individual header title bold
              },
              "& .MuiDataGrid-cell": {
                py: 0.5,
                fontSize: "14px",
                fontFamily: "Arial, sans-serif",
                textAlign: "center",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#F5F5F5",
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#F5F5F5",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default WafrReport;
