import React from "react";
import { Box, Typography } from "@mui/material";
import RiskReasonDisplay from "./RiskReasonDisplay";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getFormattedQuestion } from "./questionService";

const SectionQuestionsPanel = ({ 
  sectionQuestionList, 
  selectedQuestion, 
  setSelectedQuestion 
}) => {
  return (
    <Box sx={{ 
      overflowY: "auto",
      height: "calc(100vh - 400px)", // Ensure all questions are visible
      pr: 1 // Add slight padding for scrollbar
    }}>
      {sectionQuestionList.map((que, index) => {
        const isSelected = selectedQuestion === index;
        
        return (
          <Box
            key={index}
            sx={{
              backgroundColor: isSelected ? 'primary.lighter' : 'background.paper',
              padding: "0.5rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "flex-start",
              border: 1,
              borderColor: isSelected ? 'primary.light' : 'divider',
              borderRadius: 1,
              mb: 0.5,
              transition: 'all 0.2s ease-in-out',
              "&:hover": { 
                backgroundColor: isSelected ? 'primary.lighter' : 'grey.50',
                borderColor: isSelected ? 'primary.main' : 'grey.400'
              }
            }}
            onClick={() => setSelectedQuestion(index)}
          >
            <Box
              sx={{
                width: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {que.risk !== "UNANSWERED" 
                ? (<CheckBoxIcon sx={{ fontSize: "1rem", color: "green" }} />)
                : (<CheckBoxOutlineBlankIcon sx={{ fontSize: "1rem", color: "green" }} />)
              }
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography 
                component="p" 
                fontSize="0.75rem"
                fontWeight={isSelected ? 600 : 400}
                color={isSelected ? 'primary.dark' : 'text.primary'}
              >
                {getFormattedQuestion(que.questionTitle)}
              </Typography>
              {/* Risk and Reason */}
              <Typography 
                component="p" 
                fontSize="0.75rem"
                color="text.secondary"
              >
                <RiskReasonDisplay label="Risk" value={que.risk} type="risk" />
                {que.risk && que.reason ? " | ": null}
                <RiskReasonDisplay label="Reason" value={que.reason} type="reason" />
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SectionQuestionsPanel;
