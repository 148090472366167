import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Modal,
  Divider,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CompleteAssessmentDialog = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  error,
  type = "complete",
  assessmentName
}) => {
  const dialogContent = {
    complete: {
      title: assessmentName ? `Complete Assessment: ${assessmentName}` : "Complete Assessment",
      message: "Once Completed, the assessment will be in view only mode. No further updates can be made. You can still download the report. Do you want to continue?",
      confirmText: "Confirm & Complete",
      loadingText: "Completing..."
    },
    delete: {
      title: assessmentName ? `Delete Assessment: ${assessmentName}` : "Delete Assessment",
      message: "Are you sure you want to delete this assessment? This action cannot be undone.",
      confirmText: "Confirm & Delete",
      loadingText: "Deleting..."
    }
  };

  const content = dialogContent[type];

  return (
    <Modal
      open={open}
      onClose={!isLoading ? onClose : undefined}
      aria-labelledby="assessment-dialog"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 0,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F5F5F5",
            padding: "16px 24px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Typography 
            variant="h6" 
            fontWeight="bold"
            sx={{ 
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            {content.title}
          </Typography>
          <IconButton 
            onClick={onClose} 
            disabled={isLoading}
            sx={{ ml: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        {/* Content */}
        <Box sx={{ padding: "24px" }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            {content.message}
          </Typography>

          {error && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}

          {/* Action Buttons */}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button
              variant="outlined"
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color={type === 'delete' ? "error" : "primary"}
              onClick={onConfirm}
              disabled={isLoading}
              sx={{ minWidth: 150 }}
            >
              {isLoading ? (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <CircularProgress size={20} color="inherit" />
                  <span>{content.loadingText}</span>
                </Box>
              ) : (
                content.confirmText
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CompleteAssessmentDialog;
