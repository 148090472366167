import {
  Tab,
  Tabs,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Stack,
  InputLabel,
  LinearProgress,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const SectionTabs = ({
  sections,
  selectedTab,
  handleTabChange,
  questionnaires,
  handleFilterChange,
}) => {
  const [activeFilters, setActiveFilters] = useState([]);

  // Calculate metrics for all sections using the original questionnaires data
  const calculateMetrics = () => {
    const metrics = {
      total: 0,
      automated: 0,
      manual: 0,
      answered: 0,
      unanswered: 0,
      high: 0,
      medium: 0,
      none: 0,
      notApplicable: 0,
    };

    Object.values(questionnaires || {}).forEach((questions) => {
      questions.forEach((question) => {
        if (question.hidden) return;

        metrics.total++;

        // Count automated vs manual
        const isAutomated = question.choices?.some(
          (choice) => choice.automated === true
        );
        if (isAutomated) metrics.automated++;
        else metrics.manual++;

        // Count by answer status
        if (!question.applicable) {
          metrics.notApplicable++;
        } else if (question.risk && question.risk !== "UNANSWERED") {
          metrics.answered++;
          if (question.risk === "HIGH") metrics.high++;
          else if (question.risk === "MEDIUM") metrics.medium++;
          else if (question.risk === "NONE") metrics.none++;
        } else {
          metrics.unanswered++;
        }
      });
    });

    return metrics;
  };

  // Calculate metrics for a specific section using original data
  const getSectionMetrics = (sectionName) => {
    if (!questionnaires || !questionnaires[sectionName])
      return { total: 0, answered: 0 };

    const questions = questionnaires[sectionName].filter((q) => !q.hidden);
    const total = questions.length;
    const answered = questions.filter(
      (q) => q.risk && q.risk !== "UNANSWERED"
    ).length;
    const percentage = total > 0 ? Math.round((answered / total) * 100) : 0;

    return { total, answered, percentage };
  };

  const metrics = calculateMetrics();

  const handleChipClick = (filterType) => {
    if (filterType === "total") return;

    let newFilters = [...activeFilters];

    // If clicking an already active filter, just remove it
    if (activeFilters.includes(filterType)) {
      newFilters = newFilters.filter((f) => f !== filterType);
    } else {
      // Handle mutual exclusivity only when adding new filter
      if (["automated", "manual"].includes(filterType)) {
        // Remove other type filters
        newFilters = newFilters.filter(
          (f) => !["automated", "manual"].includes(f)
        );
      } else if (
        ["answered", "unanswered", "not_applicable"].includes(filterType)
      ) {
        // Remove other status filters
        newFilters = newFilters.filter(
          (f) => !["answered", "unanswered", "not_applicable"].includes(f)
        );
      }
      // Add the new filter
      newFilters.push(filterType);
    }

    setActiveFilters(newFilters);
    handleFilterChange(newFilters);
  };

  const FilterChip = ({
    label,
    count,
    type,
    color,
    sx,
    variant = "outlined",
    disabled = false,
  }) => {
    const isActive = activeFilters.includes(type);
  
    return (
      <Chip
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography variant="caption">{label}:</Typography>
            <Typography variant="caption" fontWeight={600}>
              {count}
            </Typography>
            {!disabled &&
              (isActive ? (
                <RemoveIcon sx={{ fontSize: 16, ml: 0.5 }} />
              ) : (
                <AddIcon sx={{ fontSize: 16, ml: 0.5 }} />
              ))}
          </Box>
        }
        onClick={() => !disabled && handleChipClick(type)}
        size="small"
        color={color}
        variant={isActive ? "filled" : variant}
        sx={{
          height: "24px",
          backgroundColor: isActive
            ? "rgba(0, 0, 0, 0.08)"
            : variant === "filled"
            ? "rgba(0, 0, 0, 0.04)"
            : undefined,
          // borderColor: isActive ? undefined : "rgba(0,0,0,0.23)",
          "& .MuiChip-label": {
            color: isActive ? "text.primary" : "text.secondary",
          },
          "&:hover": {
            backgroundColor: disabled
              ? "rgba(0, 0, 0, 0.04)"
              : isActive
              ? "rgba(0, 0, 0, 0.12)"
              : "rgba(0, 0, 0, 0.08)",
          },
          cursor: disabled ? "default" : "pointer",
          ...sx, // Merge external styles here
        }}
      />
    );
  };  

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          mb: 2,
          p: 2,
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
          border: "1px solid #e9ecef",
        }}
      >
        {/* All Metrics in one line */}
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            flexWrap: "wrap",
            gap: "8px",
            "& > *": { margin: "0 !important" },
          }}
        >
          <FilterChip
            label="Total"
            count={metrics.total}
            type="total"
            color="default"
            variant="filled"
            disabled={true}
          />

          {/* Divider */}
          <Typography color="text.secondary" sx={{ mx: 1 }}>
            |
          </Typography>

          {/* Type Filters */}
          <FilterChip
            label="Automated"
            count={metrics.automated}
            type="automated"
            color="default"
            variant="filled"
          />
          <FilterChip
            label="Manual"
            count={metrics.manual}
            type="manual"
            color="default"
            variant="filled"
          />

          {/* Divider */}
          <Typography color="text.secondary" sx={{ mx: 1 }}>
            |
          </Typography>

          {/* Status Filters */}
          <FilterChip
            label="Answered"
            count={metrics.answered}
            type="answered"
            color="default"
          />
          <FilterChip
            label="Unanswered"
            count={metrics.unanswered}
            type="unanswered"
            color="default"
          />
          <FilterChip
            label="Not Applicable"
            count={metrics.notApplicable}
            type="not_applicable"
            color="default"
          />

          {/* Divider */}
          <Typography color="text.secondary" sx={{ mx: 1 }}>
            |
          </Typography>

          {/* Risk Filters */}
          <FilterChip
            label="High Risk"
            count={metrics.high}
            type="high"
            color="default"
            sx={{
              border: "1px solid #f44336", // error.light in hex
              "&:hover": { borderColor: "#d32f2f" }, // error.main in hex
            }}
          />
          <FilterChip
            label="Medium Risk"
            count={metrics.medium}
            type="medium"
            color="default"
            sx={{
              border: "1px solid #ff9800", 
              "&:hover": { borderColor: "#f57c00" }, 
            }}
          />
          <FilterChip
            label="No Improvements Needed"
            count={metrics.none}
            type="none"
            color="default"
            sx={{
              border: "1px solid #66bb6a", 
              "&:hover": { borderColor: "#43a047" }, 
            }}
          />
        </Stack>
      </Box>

      {/* Tabs with reduced height */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{
          minHeight: "40px",
          "& .MuiTabs-flexContainer": {
            gap: 1.5,
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
        }}
      >
        {sections.map((tab) => {
          const sectionMetrics = getSectionMetrics(tab);
          const isSelected = selectedTab === tab;

          return (
            <Tab
              key={tab}
              label={
                <Box
                  sx={{
                    width: "100%",
                    py: 1,
                    px: 2,
                    borderRadius: 2,
                    backgroundColor: isSelected
                      ? "primary.lighter"
                      : "background.paper",
                    border: 1,
                    borderColor: isSelected ? "primary.light" : "divider",
                    transition: "all 0.2s ease-in-out",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      color={isSelected ? "primary.dark" : "text.primary"}
                    >
                      {tab}
                    </Typography>

                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 0.75,
                        }}
                      >
                        <Typography
                          variant="caption"
                          fontWeight={600}
                          color="text.secondary"
                          sx={{ fontSize: "0.8rem" }}
                        >
                          {sectionMetrics.percentage}%
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ fontSize: "0.75rem" }}
                        >
                          {sectionMetrics.answered}/{sectionMetrics.total}{" "}
                          questions addressed
                        </Typography>
                      </Box>

                      <LinearProgress
                        variant="determinate"
                        value={sectionMetrics.percentage}
                        sx={{
                          height: 6,
                          borderRadius: 3,
                          backgroundColor: "grey.100",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "primary.light",
                            borderRadius: 3,
                            transition: "all 0.3s ease-in-out",
                          },
                        }}
                      />
                    </Box>
                  </Stack>
                </Box>
              }
              value={tab}
              sx={{
                textTransform: "none",
                minHeight: "40px",
                flex: 1,
                padding: 0,
                "&.MuiTab-root": {
                  borderRadius: 2,
                  minHeight: "unset",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                  "& .MuiBox-root": {
                    borderColor: isSelected ? "primary.main" : "grey.400",
                    backgroundColor: isSelected ? "primary.lighter" : "grey.50",
                  },
                },
                "&.Mui-selected": {
                  color: "primary.main",
                },
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default SectionTabs;
