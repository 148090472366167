import { fork, put, all, call, takeLatest } from "redux-saga/effects";

import {
  getWafrQuestionnaireSuccessful,
  getWafrQuestionnaireFailure,
  clearAllWafrQuestionnaireMessagesSuccessful,
  getAssessmentListSuccessful,
  getAssessmentListFailure,
  updateWafrAnswerSuccessful,
  updateWafrAnswerFailure,
  postWafrAssessmentSuccessful,
  postWafrAssessmentFailure,
  postAutomatedChecksSuccessful,
  postAutomatedChecksFailure,
  getFlaggedDetailsSuccessful,
  getFlaggedDetailsFailure,
  postCompleteAssessmentSuccessful,
  postCompleteAssessmentFailure,
  getWafrSummarySuccessful,
  getWafrSummaryFailure,
  getWafrReportSuccessful,
  getWafrReportFailure,
  getMilestoneListSuccessful,
  getMilestoneListFailure,
  getMilestoneDetailsSuccessful,
  getMilestoneDetailsFailure,
  createMilestoneSuccessful,
  createMilestoneFailure,
  deleteAssessment,
  deleteAssessmentSuccessful,
  deleteAssessmentFailure,
  getWafrAttachmentsUrlSuccessful,
  getWafrAttachmentsUrlFailure,
  listWafrAttachmentsSuccessful,
  listWafrAttachmentsFailure,
  downloadWafrAttachmentSuccessful,
  downloadWafrAttachmentFailure,
  getWafrStakeholdersSuccessful,
  getWafrStakeholdersFailure,
  putWafrStakeholdersSuccessful,
  putWafrStakeholdersFailure,
} from "./wafrQuestionnaireSlice";

import {
  getWafrQuestionnaire as getWafrQuestionnaireApi,
  getWafrAssessmentList as getWafrAssessmentListApi,
  updateWafrAnswer as updateWafrAnswerApi,
  postWafrAssessment as postWafrAssessmentApi,
  postAutomatedChecks as postAutomatedChecksApi,
  getFlaggedDetails as getFlaggedDetailsApi,
  postCompleteAssessment as postCompleteAssessmentApi,
  getWafrSummary as getWafrSummaryApi,
  getWafrReport as getWafrReportApi,
  getMilestoneList as getMilestoneListApi,
  getMilestoneDetails as getMilestoneDetailsApi,
  postCreateMileStone as createMilestoneAPI,
  deleteWafrAssessment as deleteWafrAssessmentApi,
  getWafrAttachmentsUrl as getWafrAttachmentsUrlApi,
  listWafrAttachments as listWafrAttachmentsApi,
  downloadWafrAttachments as downloadWafrAttachmentsApi,
  getWafrStakeholders as getWafrStakeholdersApi,
  putWafrStakeholders as putWafrStakeholdersApi,
} from "../../helpers/auth_aws_helper";

function* getWafrQuestionnaireSaga({ payload: getWafrQuestionnairePayload }) {
  try {
    const response = yield call(
      getWafrQuestionnaireApi,
      getWafrQuestionnairePayload
    );
    console.log("*getWafrQuestionnaireApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(getWafrQuestionnaireSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching questionnaire.";
      yield put(getWafrQuestionnaireFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getWafrQuestionnaireFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        getWafrQuestionnaireFailure({ message: "No response received" })
      );
    } else {
      yield put(getWafrQuestionnaireFailure({ message: error?.message }));
    }
  }
}

// Saga for fetching Assessment List
function* getAssessmentListSaga() {
  try {
    const response = yield call(getWafrAssessmentListApi);
    console.log("*getWafrAssessmentListApi() response: ", response);

    // Log structure of response for debugging
    console.log("Response status: ", response.status);
    console.log("Response data: ", response.data);

    if (response && response.status === 200) {
      // Extract and transform the response data
      const rawData = response.data;
      const transformedData = Object.keys(rawData)
        .filter((key) => key !== "status") // Exclude the "status" key
        .map((key) => ({
          id: key, // Use the numeric key as the ID
          ...rawData[key],
        }));
      console.log(
        "🚀 ~ function*getAssessmentListSaga ~ transformedData:",
        transformedData
      );
      // Dispatch the successful action with the transformed data
      yield put(getAssessmentListSuccessful(transformedData));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching assessment list.";
      yield put(getAssessmentListFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getAssessmentListFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getAssessmentListFailure({ message: "No response received" }));
    } else {
      yield put(getAssessmentListFailure({ message: error?.message }));
    }
  }
}

function* updateWafrAnswerSaga({ payload: updateWafrAnswerPayload }) {
  try {
    const response = yield call(updateWafrAnswerApi, updateWafrAnswerPayload);
    console.log("*updateWafrAnswerApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(updateWafrAnswerSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while updating answer.";
      yield put(updateWafrAnswerFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        updateWafrAnswerFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(updateWafrAnswerFailure({ message: "No response received" }));
    } else {
      yield put(updateWafrAnswerFailure({ message: error?.message }));
    }
  }
}

function* postWafrAssessmentSaga({ payload: postWafrAssessmentPayload }) {
  try {
    const response = yield call(
      postWafrAssessmentApi,
      postWafrAssessmentPayload
    );
    console.log("*postWafrAssessmentApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      console.log("**postWafrAssessmentApi() response: ", response);
      yield put(postWafrAssessmentSuccessful(response.data));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while creating assessment.";
      yield put(postWafrAssessmentFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        postWafrAssessmentFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(postWafrAssessmentFailure({ message: "No response received" }));
    } else {
      yield put(postWafrAssessmentFailure({ message: error?.message }));
    }
  }
}

function* postAutomatedChecksSaga({ payload: postAutomatedChecksPayload }) {
  try {
    const response = yield call(
      postAutomatedChecksApi,
      postAutomatedChecksPayload
    );
    console.log("*postAutomatedChecksApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(postAutomatedChecksSuccessful(response.data));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while running automated checks.";
      yield put(postAutomatedChecksFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        postAutomatedChecksFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        postAutomatedChecksFailure({ message: "No response received" })
      );
    } else {
      yield put(postAutomatedChecksFailure({ message: error?.message }));
    }
  }
}

function* postCompleteAssessmentSaga({
  payload: postCompleteAssessmentPayload,
}) {
  try {
    const response = yield call(
      postCompleteAssessmentApi,
      postCompleteAssessmentPayload
    );
    console.log("*postCompleteAssessmentApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(postCompleteAssessmentSuccessful(response.data));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while posting complete assessment.";
      yield put(postCompleteAssessmentFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        postCompleteAssessmentFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        postCompleteAssessmentFailure({ message: "No response received" })
      );
    } else {
      yield put(postCompleteAssessmentFailure({ message: error?.message }));
    }
  }
}

// Saga for fetching flagged details
function* getFlaggedDetailsSaga({ payload: getFlaggedDetailsPayload }) {
  try {
    const response = yield call(getFlaggedDetailsApi, getFlaggedDetailsPayload);
    console.log("*getFlaggedDetailsApi() response: ", response);

    if (response && response.status === 200) {
      // Remove the status field to avoid redundant storage
      const { status, ...data } = response;
      console.log("Extracted data (without status):", data);
      yield put(getFlaggedDetailsSuccessful({ data }));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching flagged details.";
      yield put(getFlaggedDetailsFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getFlaggedDetailsFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getFlaggedDetailsFailure({ message: "No response received" }));
    } else {
      yield put(getFlaggedDetailsFailure({ message: error?.message }));
    }
  }
}

function* getWafrSummarySaga({ payload: getWafrSummaryPayload }) {
  try {
    const response = yield call(getWafrSummaryApi, getWafrSummaryPayload);
    console.log("*getWafrSummaryApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(getWafrSummarySuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching WAFR summary.";
      yield put(getWafrSummaryFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getWafrSummaryFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getWafrSummaryFailure({ message: "No response received" }));
    } else {
      yield put(getWafrSummaryFailure({ message: error?.message }));
    }
  }
}

function* getWafrReportSaga({ payload: getWafrReportPayload }) {
  try {
    const response = yield call(getWafrReportApi, getWafrReportPayload);
    console.log("*getWafrReportApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(getWafrReportSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching WAFR report.";
      yield put(getWafrReportFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getWafrReportFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getWafrReportFailure({ message: "No response received" }));
    } else {
      yield put(getWafrReportFailure({ message: error?.message }));
    }
  }
}

function* getMilestoneListSaga({ payload: getMilestoneListPayload }) {
  try {
    const response = yield call(getMilestoneListApi, getMilestoneListPayload);
    console.log("*getMilestoneListApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(getMilestoneListSuccessful(response.data));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching milestone list.";
      yield put(getMilestoneListFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getMilestoneListFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getMilestoneListFailure({ message: "No response received" }));
    } else {
      yield put(getMilestoneListFailure({ message: error?.message }));
    }
  }
}

function* getMilestoneDetailsSaga({ payload: getMilestoneDetailsPayload }) {
  try {
    const response = yield call(
      getMilestoneDetailsApi,
      getMilestoneDetailsPayload
    );
    console.log("*getMilestoneDetailsApi() response: ", response);

    if (response && response.status === 200) {
      // Remove the status field to avoid redundant storage
      delete response.status;
      console.log("Extracted data (without status):", response);
      yield put(getMilestoneDetailsSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching milestone details.";
      yield put(getMilestoneDetailsFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getMilestoneDetailsFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        getMilestoneDetailsFailure({ message: "No response received" })
      );
    } else {
      yield put(getMilestoneDetailsFailure({ message: error?.message }));
    }
  }
}

function* createMilestoneSaga({ payload: createMilestonePayload }) {
  try {
    const response = yield call(createMilestoneAPI, createMilestonePayload);
    console.log("*createMilestoneAPI() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(createMilestoneSuccessful(response.data));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while creating milestone.";
      yield put(createMilestoneFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        createMilestoneFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(createMilestoneFailure({ message: "No response received" }));
    } else {
      yield put(createMilestoneFailure({ message: error?.message }));
    }
  }
}

function* deleteWafrAssessmentSaga({ payload: deleteWafrAssessmentPayload }) {
  try {
    const response = yield call(deleteWafrAssessmentApi, deleteWafrAssessmentPayload);
    console.log("*deleteWafrAssessmentApi() response: ", response);

    console.log("*deleteWafrAssessmentApi() response status:", response.status);
    console.log("*deleteWafrAssessmentApi() response data:", response.data);

    if (response && response.status === 200) {
      delete response.status; // Remove the status field if not needed
      yield put(deleteAssessmentSuccessful(response.data)); // Dispatch success action
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while deleting the assessment.";
      yield put(deleteAssessmentFailure({ message })); // Dispatch failure action
    }
  } catch (error) {
    if (error.response) {
      yield put(
        deleteAssessmentFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(deleteAssessmentFailure({ message: "No response received" }));
    } else {
      yield put(deleteAssessmentFailure({ message: error?.message }));
    }
  }
}

function* clearAllWafrQuestionnaireMessagesSaga() {
  yield put(clearAllWafrQuestionnaireMessagesSuccessful());
}

function* watchAutomatedChecks() {
  yield takeLatest(
    "wafrQuestionnaire/postAutomatedChecks",
    postAutomatedChecksSaga
  );
}

function* watchCompleteAssessment() {
  yield takeLatest(
    "wafrQuestionnaire/postCompleteAssessment",
    postCompleteAssessmentSaga
  );
}

// Watcher for getFlaggedDetails
function* watchFlaggedDetails() {
  yield takeLatest(
    "wafrQuestionnaire/getFlaggedDetails",
    getFlaggedDetailsSaga
  );
}

function* watchWafrQuestionnaire() {
  yield takeLatest(
    "wafrQuestionnaire/getWafrQuestionnaire",
    getWafrQuestionnaireSaga
  );
  yield takeLatest(
    "wafrQuestionnaire/clearAllWafrQuestionnaireMessages",
    clearAllWafrQuestionnaireMessagesSaga
  );
}

function* watchWafrAnswer() {
  yield takeLatest("wafrQuestionnaire/updateWafrAnswer", updateWafrAnswerSaga);
}

function* watchWafrAssessment() {
  yield takeLatest(
    "wafrQuestionnaire/postWafrAssessment",
    postWafrAssessmentSaga
  );
}

function* clearAllAssessmentMessagesSaga() {
  yield put({ type: "wafrQuestionnaire/clearAllAssessmentMessages" });
}

function* watchAssessmentList() {
  yield takeLatest(
    "wafrQuestionnaire/getAssessmentList",
    getAssessmentListSaga
  );
  yield takeLatest(
    "wafrQuestionnaire/clearAllAssessmentMessages",
    clearAllAssessmentMessagesSaga
  );
}

function* watchWafrSummary() {
  yield takeLatest("wafrQuestionnaire/getWafrSummary", getWafrSummarySaga);
}

function* watchWafrReport() {
  yield takeLatest("wafrQuestionnaire/getWafrReport", getWafrReportSaga);
}

function* watchMilestoneList() {
  yield takeLatest("wafrQuestionnaire/getMilestoneList", getMilestoneListSaga);
}

function* watchMilestoneDetails() {
  yield takeLatest(
    "wafrQuestionnaire/getMilestoneDetails",
    getMilestoneDetailsSaga
  );
}

// Add watcher function for createMilestone
function* watchCreateMilestone() {
  yield takeLatest("wafrQuestionnaire/createMilestone", createMilestoneSaga);
}

function* watchDeleteAssessment() {
  yield takeLatest("wafrQuestionnaire/deleteAssessment", deleteWafrAssessmentSaga);
}

// Add new sagas for WAFR Attachments
function* getWafrAttachmentsUrlSaga({ payload: getWafrAttachmentsUrlPayload }) {
  try {
    const response = yield call(getWafrAttachmentsUrlApi, getWafrAttachmentsUrlPayload);
    console.log("*getWafrAttachmentsUrlApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(getWafrAttachmentsUrlSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while getting attachment URL.";
      yield put(getWafrAttachmentsUrlFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getWafrAttachmentsUrlFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getWafrAttachmentsUrlFailure({ message: "No response received" }));
    } else {
      yield put(getWafrAttachmentsUrlFailure({ message: error?.message }));
    }
  }
}

function* listWafrAttachmentsSaga({ payload: listWafrAttachmentsPayload }) {
  try {
    const response = yield call(listWafrAttachmentsApi, listWafrAttachmentsPayload);
    console.log("*listWafrAttachmentsApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(listWafrAttachmentsSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while listing attachments.";
      yield put(listWafrAttachmentsFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        listWafrAttachmentsFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(listWafrAttachmentsFailure({ message: "No response received" }));
    } else {
      yield put(listWafrAttachmentsFailure({ message: error?.message }));
    }
  }
}

function* downloadWafrAttachmentSaga({ payload: downloadWafrAttachmentPayload }) {
  try {
    const response = yield call(downloadWafrAttachmentsApi, downloadWafrAttachmentPayload);
    console.log("*downloadWafrAttachmentsApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(downloadWafrAttachmentSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while getting download URL.";
      yield put(downloadWafrAttachmentFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        downloadWafrAttachmentFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(downloadWafrAttachmentFailure({ message: "No response received" }));
    } else {
      yield put(downloadWafrAttachmentFailure({ message: error?.message }));
    }
  }
}

// Add watchers for new sagas
function* watchWafrAttachments() {
  yield takeLatest("wafrQuestionnaire/getWafrAttachmentsUrl", getWafrAttachmentsUrlSaga);
  yield takeLatest("wafrQuestionnaire/listWafrAttachments", listWafrAttachmentsSaga);
  yield takeLatest("wafrQuestionnaire/downloadWafrAttachment", downloadWafrAttachmentSaga);
}

// Add new saga functions
function* getWafrStakeholdersSaga({ payload: getWafrStakeholdersPayload }) {
  try {
    const response = yield call(getWafrStakeholdersApi, getWafrStakeholdersPayload);
    console.log("*getWafrStakeholdersApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(getWafrStakeholdersSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching stakeholders.";
      yield put(getWafrStakeholdersFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getWafrStakeholdersFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getWafrStakeholdersFailure({ message: "No response received" }));
    } else {
      yield put(getWafrStakeholdersFailure({ message: error?.message }));
    }
  }
}

function* putWafrStakeholdersSaga({ payload: putWafrStakeholdersPayload }) {
  try {
    const response = yield call(putWafrStakeholdersApi, putWafrStakeholdersPayload);
    console.log("*putWafrStakeholdersApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(putWafrStakeholdersSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while updating stakeholder.";
      yield put(putWafrStakeholdersFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        putWafrStakeholdersFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(putWafrStakeholdersFailure({ message: "No response received" }));
    } else {
      yield put(putWafrStakeholdersFailure({ message: error?.message }));
    }
  }
}

// Add watcher functions
function* watchWafrStakeholders() {
  yield takeLatest("wafrQuestionnaire/getWafrStakeholders", getWafrStakeholdersSaga);
  yield takeLatest("wafrQuestionnaire/putWafrStakeholders", putWafrStakeholdersSaga);
}

function* watchWafrQuestionnaireSaga() {
  yield all([
    fork(watchWafrQuestionnaire),
    fork(watchAssessmentList),
    fork(watchWafrAnswer),
    fork(watchWafrAssessment),
    fork(watchAutomatedChecks),
    fork(watchFlaggedDetails),
    fork(watchCompleteAssessment),
    fork(watchWafrSummary),
    fork(watchWafrReport),
    fork(watchMilestoneList),
    fork(watchMilestoneDetails),
    fork(watchCreateMilestone),
    fork(watchDeleteAssessment),
    fork(watchWafrAttachments),
    fork(watchWafrStakeholders),
  ]);
}

export default watchWafrQuestionnaireSaga;
