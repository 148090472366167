import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  FormGroup,
  Container,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Creatable from "react-select/creatable";
import awsLogo from "../../assets/images/aws_logo.png";
import { connect } from "react-redux";
import {
  updateOrgAccount,
  resetOrgAccountStatus,
  getListOfOrgsAndAccts,
  getAwsAcc,
} from "../../store/actions";
import {
  postAccountConfiguration,
  clearAllAccountConfigurationMessages,
} from "../../store/accountConfiguration/accountConfigurationSlice";

import { countryList, getRegionCode } from "../../utilities/countries";
import { withStyles } from "@material-ui/core/styles";
import { Snackbar } from "@mui/material";
import { Alert } from "@material-ui/lab";

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { validateAddressWithAPI } from '../../helpers/addressValidation';

const styles = (theme) => ({
  enabledTab: {
    // Add styles for enabled tab here
    color: theme.palette.primary.main,
  },
  disabledTab: {
    // Add styles for disabled tab here
    color: theme.palette.grey[400],
  },
});

export const getAddonsArray = (licenseData) => {
  console.log("licenseData in getAddonsArray", licenseData);
  let addonsArray = [];
  let addonsArrayTemp = [];
  if (licenseData) {
    addonsArrayTemp = licenseData.find(
      (licenseInfo) => licenseInfo.Name === "Basic"
    )["Add ons"];
    if (addonsArrayTemp) {
      addonsArray = addonsArrayTemp.map((addons) => ({
        name: addons.Name,
        enabled: false,
      }));
    }
  }

  return addonsArray;
};

class UpdateAccount extends React.PureComponent {
  constructor(props) {
    console.log("props.selectedAccount = ", props.selectedAccount);
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Update an account", link: "#" },
      ],
      activeTab: 1,
      orgId: `${props.selectedAccount?.orgId}`,
      orgName: "",
      accountNumber: `${props.selectedAccount?.accountNumber}`,
      accountName: `${props.selectedAccount?.accountName}`,
      accountCode: `${props.selectedAccount?.accountCode}`,
      urlLink: `${props.selectedAccount?.urlLink}`,
      location: `${props.selectedAccount?.location}`,
      accounttype: `${props.selectedAccount?.accounttype}`,
      address: `${props.selectedAccount?.address}`,
      city: `${props.selectedAccount?.city}`,
      state: `${props.selectedAccount?.state}`,
      zip: `${props.selectedAccount?.zip}`,
      country: `${props.selectedAccount?.country}`,
      phonenumber: `${props.selectedAccount?.phonenumber}`,
      phonenumberError: null,
      email: `${props.selectedAccount?.email}`,
      currentStepError: false,
      //Convert user selection of resource groups (array of objects) to array of strings
      accountResourceGroups:
        this.props.selectedAccount?.accountResourceGroups.map(
          (rg) => rg.resourcename
        ),
      selectedOption: this.props.selectedAccount?.accountResourceGroups.map(
        (rg) => {
          return { value: rg.resourcename, label: rg.resourcename };
        }
      ),
      defaultOptions: [
        { value: "H.R.", label: "H.R." },
        { value: "Finance", label: "Finance" },
        { value: "Engineering", label: "Engineering" },
        { value: "Unassigned", label: "Unassigned" },
      ],
      roleName: `${props.selectedAccount?.iamrole}`,
      externalId: `${props.selectedAccount}`
        ? `${props.selectedAccount.externalId}`
        : "",

      updateAccountError: false, // to display Snackbar
      addressValidationStatus: null,
      addressValidationMessage: '',
    };
    this.linkTextAreaRef = React.createRef();
  }

  closeAcctSuccesmsg = () => {
    this.props.updateData(this.props.accounts);
    this.props.closeModal();
    this.props.resetOrgAccountStatus();
    // this.props.clearAllOrgAccountMessages();
  };

  closeModal = () => {
    this.props.closeModal();
  };

  componentDidMount() {
    console.log("--=== componentDidMount in orgAccount", this.props);
    if (this.props.organizations === null || this.props.accounts === null) {
      console.log(
        "--=== componentDidMount, calling this.props.getListOfOrgsAndAccts()"
      );
      this.props.getListOfOrgsAndAccts();
    }
    if (this.props.aws === null) {
      console.log("--=== componentDidMount, calling this.props.getAwsAcc()");
      this.props.getAwsAcc();
    }
  }

  componentDidUpdate(prevProps) {
    console.log("~~~~~componentDidUpdate");

    if (
      this.props.postAccountConfigurationError &&
      this.props.postAccountConfigurationError !==
        prevProps.postAccountConfigurationError
    ) {
      this.setState({ updateAccountError: true });
      setTimeout(() => this.setState({ updateAccountError: false }), 5000);
    }
  }

  handlePhoneChange = (value) => {
    this.setState({ phonenumber: value });
  };

  handlePhoneBlur = () => {
    let isInvalidResult;
    if (!this.state.phonenumber) {
      isInvalidResult = true;
    } else {
      isInvalidResult = !isPossiblePhoneNumber(this.state.phonenumber);
    }
    if (isInvalidResult) {
      this.setState({ phonenumberError: "Please enter a valid phone number" });
    } else {
      this.setState({ phonenumberError: null });
    }
  };

  handleInputChange = (e) => {
    if (e.target.name === "phonenumber") {
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleMultiSelectChange = (selectedOption) => {
    //
    // Create list of resource groups selected for the account
    //
    if (selectedOption === null) {
      return;
    }
    this.setState(selectedOption);
    this.state.accountResourceGroups.splice(
      0,
      this.state.accountResourceGroups.length
    );

    selectedOption.map((rg) => {
      if (!this.state.accountResourceGroups.includes(rg.value)) {
        this.state.accountResourceGroups.push(rg.value);
      }
    });
    console.log(this.state.accountResourceGroups);
  };

  handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      return;
    }
    this.setState(selectedOption);
    this.state.accountResourceGroups.length = 0;
    this.state.accountResourceGroups.push(selectedOption.value);
  };
  handleSubmit = () => {
    const resourceGroups = this.state.accountResourceGroups[0];

    let accountUpdateRequest = {
      accountNumber: this.state.accountNumber,
      address: this.state.address,
      city: this.state.city,
      country: this.state.country,
      email: this.state.email,
      phoneNumber: this.state.phonenumber,
      zip: this.state.zip,
      resourceGroup: resourceGroups,
      accountName: this.state.accountName,
    };

    console.log("accountUpdateRequest = ", accountUpdateRequest);

    //Call Saga for account update
    this.props.postAccountConfiguration(accountUpdateRequest);
  };

  handleCurrentStep = () => {
    const {
      accountNumber,
      accountName,
      location,
      accounttype,
      address,
      city,
      zip,
      country,
      email,
      roleName,
    } = this.state;
    if (
      accountNumber !== "" &&
      accountName !== "" &&
      location !== "" &&
      accounttype !== "" &&
      address !== "" &&
      city !== "" &&
      zip !== "" &&
      country !== "" &&
      email !== "" &&
      roleName !== ""
    ) {
      this.setState({ currentStepError: false });
      return true;
    } else {
      this.setState({ currentStepError: true });
    }
  };

  isInvalid = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    let isPhoneNumberInvalid;
    if (!this.state.phonenumber) {
      isPhoneNumberInvalid = true;
    } else {
      isPhoneNumberInvalid = !isPossiblePhoneNumber(this.state.phonenumber);
    }
    const isInvalidItems = [
      {
        field: "location",
        isInvalid: this.state.location === "" ? true : false,
      },
      {
        field: "email",
        isInvalid:
          (this.state.email && this.state.email.length === 0) ||
          !regex.test(String(this.state.email).toLowerCase()),
      },
      {
        field: "accountNumber",
        isInvalid:
          this.state.accountNumber.length !== 12 ||
          !/^[0-9]+$/.test(this.state.accountNumber)
            ? true
            : false,
      },
      {
        field: "accountName",
        isInvalid: this.state.accountName.length === 0 ? true : false,
      },
      {
        field: "phonenumber",
        value: this.state.phonenumber,
        isInvalid: isPhoneNumberInvalid,
      },
      {
        field: "address",
        isInvalid: this.state.address.length === 0 ? true : false,
      },
      {
        field: "city",
        isInvalid:
          this.state.city.length === 0 || !/^[A-Za-z]+$/.test(this.state.city)
            ? true
            : false,
      },
      // {
      //   field: "zip",
      //   value: this.state.zip,
      //   isInvalid:
      //     this.state.zip.length !== 5 ||
      //     !/^[0-9a-zA-Z]+$/.test(this.state.userZipcode)
      //       ? true
      //       : false,
      // },
      { field: "accountResourceGroups", isInvalid: false },
      {
        field: "roleName",
        isInvalid: this.state.roleName.length === 0 ? true : false,
      },
    ];
    const resp = isInvalidItems.filter((item) => item.isInvalid);

    return resp.length > 0;
  };

  validateAddress = async () => {
    const { address, country } = this.state;
    if (!address.trim() || country === "Select Country") return;

    this.setState({ 
      addressValidationStatus: null,
      addressValidationMessage: 'Validating address...'
    });

    const regionCode = getRegionCode(country);
    if (!regionCode) {
      this.setState({
        addressValidationStatus: 'invalid',
        addressValidationMessage: 'Please select a valid country first'
      });
      return;
    }

    try {
      const result = await validateAddressWithAPI(
        address, 
        regionCode, 
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      );

      this.setState({
        addressValidationStatus: result.isValid ? 'valid' : 'invalid',
        addressValidationMessage: result.message,
        city: result.city,
        state: result.state,
        zip: result.zip,
        address: result.formattedAddress || address
      });
    } catch (error) {
      this.setState({
        addressValidationStatus: 'invalid',
        addressValidationMessage: 'Failed to validate address. Please try again.'
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid={true}>
            <Row>
              <Col sm="12" md="12">
                <Card>
                  <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                      <AvForm className="form-horizontal">
                        <div className="d-flex">
                          <label>
                            <input
                              className="radio-img"
                              type="radio"
                              name="accounttype"
                              value="AWS"
                              onChange={this.handleInputChange}
                              checked={this.state.accounttype === "AWS"}
                            />
                            <img
                              src={awsLogo}
                              height="40"
                              alt="AWS account type"
                            />
                          </label>
                          <FormGroup className="mb-3 w-100">
                            <AvField
                              type="select"
                              name="orgName"
                              value={this.state.orgname}
                              onChange={this.handleInputChange}
                              disabled
                            >
                              {this.props.organizations
                                ? this.props.organizations.map((org) => {
                                    return (
                                      <option key={org.orgId}>
                                        {org.orgname}
                                      </option>
                                    );
                                  })
                                : null}
                            </AvField>
                          </FormGroup>
                        </div>
                        <Row>
                          <Col lg="12">
                            <FormGroup className="mb-3">
                              <AvField
                                name="accountNumber"
                                value={this.state.accountNumber}
                                placeholder="Account Number*"
                                type="text"
                                className={`form-control ${
                                  this.state.currentStepError &&
                                  this.state.accountNumber === ""
                                    ? "is-invalid-border"
                                    : null
                                }`}
                                onChange={this.handleInputChange}
                                errorMessage="Enter account number"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Account number is required",
                                  },
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage:
                                      "Account number must include only numbers",
                                  },
                                  minLength: {
                                    value: 12,
                                    errorMessage:
                                      "Account number must be minimum 12 digits",
                                  },
                                  maxLength: {
                                    value: 12,
                                    errorMessage:
                                      "Account number cannot be more than 12 digits",
                                  },
                                }}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup className="mb-3">
                              <AvField
                                name="accountName"
                                value={this.state.accountName}
                                placeholder="Account Name*"
                                type="text"
                                errorMessage="Enter account name"
                                className={`form-control ${
                                  this.state.currentStepError &&
                                  this.state.accountName === ""
                                    ? "is-invalid-border"
                                    : null
                                }`}
                                onChange={this.handleInputChange}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Account name is required",
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup className="mb-3">
                              <AvField
                                type="select"
                                name="country"
                                value={this.state.country}
                                onChange={(e) => {
                                  this.setState({
                                    country: e.target.value,
                                    address: '',
                                    addressValidationStatus: null,
                                    addressValidationMessage: ''
                                  });
                                }}
                                className="form-control"
                              >
                                {countryList.map((country, index) => (
                                  <option key={index} value={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col lg="8">
                            <FormGroup className="mb-3">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.addressValidationStatus === 'invalid' ? 'is-invalid' : ''
                                  } ${
                                    this.state.addressValidationStatus === 'valid' ? 'is-valid' : ''
                                  }`}
                                  placeholder={this.state.country === "Select Country" 
                                    ? "Please select a country first" 
                                    : "Enter complete address*"
                                  }
                                  value={this.state.address}
                                  onChange={(e) => {
                                    this.setState({
                                      address: e.target.value,
                                      addressValidationStatus: null,
                                      addressValidationMessage: ''
                                    });
                                  }}
                                  onBlur={this.validateAddress}
                                  disabled={this.state.country === "Select Country"}
                                />
                                {this.state.addressValidationStatus === 'valid' && (
                                  <i 
                                    className="mdi mdi-check-circle text-success" 
                                    style={{
                                      position: 'absolute',
                                      right: '10px',
                                      top: '50%',
                                      transform: 'translateY(-50%)'
                                    }}
                                  />
                                )}
                                {this.state.addressValidationMessage && (
                                  <div 
                                    className={`text-${this.state.addressValidationStatus === 'valid' ? 'success' : 'danger'} mt-1 small`}
                                  >
                                    {this.state.addressValidationMessage}
                                  </div>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <PhoneInput
                                type="text"
                                className={
                                  !this.state.phonenumberError
                                    ? "phone-input"
                                    : "phone-input invalid"
                                }
                                id="basicpill-orgPhone-input"
                                name="phonenumber"
                                aria-describedby="Phone Number"
                                placeholder="Phone Number*"
                                value={this.state.phonenumber}
                                maxLength={16}
                                onChange={this.handlePhoneChange}
                                onBlur={this.handlePhoneBlur}
                                required
                              />
                              {this.state.phonenumberError ? (
                                <div
                                  className="text-danger"
                                  style={{
                                    fontSize: "80%",
                                    marginTop: "0.25rem",
                                  }}
                                >
                                  {this.state.phonenumberError}
                                </div>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <AvField
                                name="email"
                                value={this.state.email}
                                placeholder="Email*"
                                type="text"
                                errorMessage="Enter valid email address."
                                className={`form-control ${
                                  this.state.currentStepError &&
                                  this.state.email === ""
                                    ? "is-invalid-border"
                                    : null
                                }`}
                                onChange={this.handleInputChange}
                                validate={{
                                  email: true,
                                  required: true,
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                            <label htmlFor="resourcegroupfield">Resource Group</label>
                              <Creatable
                                name="rgs"
                                id='resourcegroupfield'
                                onChange={this.handleSelectChange}
                                formatCreateLabel={(inputValue) =>
                                  `Create ResourceGroup "${inputValue}"`
                                }
                                placeholder={"Select/create a resource group"}
                                defaultValue={this.state.selectedOption}
                                options={
                                  this.props.organizations &&
                                  this.props.organizations.length !== 0
                                    ? this.props.organizations[0].resourceGroups.map(
                                        (rg) => {
                                          return {
                                            value: rg.resourcename,
                                            label: rg.resourcename,
                                          };
                                        }
                                      )
                                    : null
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>

                    <Snackbar
                      open={this.state.updateAccountError}
                      autoHideDuration={5000}
                    >
                      <Alert severity="error">
                        Error while updating the account.{" "}
                        {this.props.postAccountConfigurationError}
                      </Alert>
                    </Snackbar>

                    <div
                      className="row"
                      style={{
                        marginTop: "30px",
                        gap: "10px",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <button
                        type="button"
                        className="btn w-lg btn-secondary"
                        onClick={this.closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn w-lg btn-primary text-light"
                        disabled={
                          this.isInvalid() ||
                          this.props.isPostingAccountConfiguration
                        }
                        onClick={() => {
                          if (this.handleCurrentStep()) {
                            this.handleSubmit();
                          }
                        }}
                      >
                        {this.props.isPostingAccountConfiguration
                          ? "Loading ..."
                          : "Submit"}
                      </button>
                    </div>

                    <>
                      <Modal
                        isOpen={
                          this.props.postAccountConfigurationMessage !== null
                        }
                        centered
                      >
                        {console.log("Update Account Message ::", this.props.postAccountConfigurationMessage)}
                        <ModalBody>Account Sucessfully Updated</ModalBody>
                        <ModalFooter>
                          <Button onClick={this.closeAcctSuccesmsg}>
                            Close
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { accounts, organizations, reg_acct_error, account, loading, message } =
    state.orgAccount;
  const {
    pricingInfo,
    error: pricingInfoApiError,
    loading: pricingInfoApiLoading,
  } = state.pricingInfo;
  const {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
  } = state.releaseNotes;

  const {
    isPostingAccountConfiguration,
    postAccountConfigurationMessage,
    postAccountConfigurationError,
  } = state.accountConfiguration;

  return {
    accounts,
    organizations,
    reg_acct_error,
    account,
    loading,
    message,
    pricingInfo,
    pricingInfoApiError,
    pricingInfoApiLoading,
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
    isPostingAccountConfiguration,
    postAccountConfigurationMessage,
    postAccountConfigurationError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListOfOrgsAndAccts: () => {
      dispatch(getListOfOrgsAndAccts());
    },
    updateOrgAccount: (data) => {
      dispatch(updateOrgAccount(data));
    },
    resetOrgAccountStatus: () => {
      dispatch(resetOrgAccountStatus(null));
    },
    getAwsAcc: () => {
      dispatch(getAwsAcc());
    },
    postAccountConfiguration: (accountConfigurationPayload) => {
      dispatch(postAccountConfiguration(accountConfigurationPayload));
    },
    clearAllAccountConfigurationMessages: () => {
      dispatch(clearAllAccountConfigurationMessages());
    },
  };
};

export default withStyles(styles)(
  connect(mapStatetoProps, mapDispatchToProps)(UpdateAccount)
);
