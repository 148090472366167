export const validateAddressWithAPI = async (address, regionCode, apiKey) => {
  try {
    const response = await fetch(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${apiKey}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: {
            regionCode,
            addressLines: [address]
          }
        })
      }
    );

    if (!response.ok) {
      throw new Error('Address validation failed');
    }

    const data = await response.json();
    
    const isValidGranularity = data.result?.verdict?.validationGranularity === 'PREMISE' || 
                              data.result?.verdict?.validationGranularity === 'SUB_PREMISE';
    
    // Extract address components
    const addressComponents = data.result?.address?.addressComponents || [];
    const extractedData = {
      city: addressComponents.find(comp => comp.componentType === 'locality')?.componentName?.text || '',
      state: addressComponents.find(comp => comp.componentType === 'administrative_area_level_1')?.componentName?.text || '',
      zip: addressComponents.find(comp => comp.componentType === 'postal_code')?.componentName?.text || '',
      formattedAddress: data.result?.address?.formattedAddress || address
    };

    const validationResult = {
      isValid: data.result?.verdict?.addressComplete === true || isValidGranularity,
      message: data.result?.verdict?.addressComplete || isValidGranularity
        ? 'Address validated successfully'
        : 'Please enter a complete address',
      details: data.result,
      ...extractedData
    };

    return validationResult;

  } catch (error) {
    return {
      isValid: false,
      message: 'Failed to validate address. Please try again.',
      error,
      city: '',
      state: '',
      zip: ''
    };
  }
}; 