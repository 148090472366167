import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Paper,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DataGrid } from "@mui/x-data-grid";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { getMilestoneList } from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";

const MilestonesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    workloadId,
    orgCode,
    accCode,
    accNumber,
    assessmentName,
    frameworkName,
    ownerName,
    approverName,
  } = location.state || {};

  const { isGettingMilestoneList, milestoneList, getMilestoneListError } =
    useSelector((state) => state.wafrQuestionnaire);

  console.log("🚀 ~ MilestonesPage ~ milestoneList:", milestoneList);

  useEffect(() => {
    if (workloadId && accCode) {
      dispatch(getMilestoneList({ workloadId, accCode }));
    }
  }, [dispatch, workloadId, accCode]);

  const rows = React.useMemo(() => {
    if (!milestoneList) return [];
    return milestoneList.map((milestone) => ({
      id: `${milestone.workloadId}-${milestone.MilestoneNumber}`,
      MilestoneNumber: milestone.MilestoneNumber,
      MilestoneName: milestone.MilestoneName,
      accountNumber: milestone.accountNumber,
      accountCode: milestone.accountCode,
      orgCode: milestone.orgCode,
      createdBy: milestone.createdBy,
      createdDate: milestone.createdDate,
      lastModifiedBy: milestone.lastModifiedBy,
      lastModifiedDate: milestone.lastModifiedDate,
    }));
  }, [milestoneList]);

  const columns = [
    {
      field: "MilestoneNumber",
      headerName: "Milestone",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "MilestoneName",
      headerName: "Milestone Name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "accountCode",
      headerName: "Account Code",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "orgCode",
      headerName: "Org Code",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => new Date(params.row.createdDate).toDateString(),
    },
    {
      field: "lastModifiedBy",
      headerName: "Last Modified By",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastModifiedDate",
      headerName: "Last Modified Date",
      width: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        new Date(params.row.lastModifiedDate).toDateString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            history.push(`/workload-assessment/milestonesSummary`, {
              workloadId: workloadId,
              orgCode: orgCode,
              accCode: accCode,
              accNumber: accNumber,
              assessmentName: assessmentName,
              frameworkName: frameworkName,
              ownerName: ownerName,
              approverName: approverName,
              milestoneName: params.row.MilestoneName,
              milestoneNumber: params.row.MilestoneNumber,
            })
          }
          sx={{
            textTransform: "none",
            fontWeight: 500,
            borderRadius: "20px",
            padding: "2px 12px",
            borderColor: "#1976d2",
            color: "#1976d2",
            "&:hover": {
              backgroundColor: "#E3F2FD",
              borderColor: "#1565c0",
            },
          }}
        >
          View
        </Button>
      ),
    },
  ];

  console.log("🚀 ~ MilestonesPage ~ processed rows:", rows);

  // Custom Toolbar Component
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          p: 2,
          borderBottom: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <GridToolbarColumnsButton
          sx={{
            textTransform: "none",
            color: "#1976d2",
            border: "1px solid #1976d2",
            borderRadius: "4px",
            height: 36,
            "&:hover": {
              backgroundColor: "#e3f2fd",
              borderColor: "#1565c0",
            },
            "& .MuiSvgIcon-root": {
              marginRight: 1,
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <Box
        sx={{
          padding: "1.5rem 2rem",
          mt: "5rem",
          backgroundColor: "#F9F9F9",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header */}
        <Paper
          elevation={2}
          sx={{
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            marginBottom: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#f9fafe",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", color: "#2a3c50" }}
            >
              Milestones
            </Typography>
            <Typography variant="body2" sx={{ color: "#6b7280" }}>
              | Assessment: {assessmentName || "N/A"}
            </Typography>
          </Box>

          <Button
            onClick={() => history.goBack()}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#1976d2",
              color: "#1976d2",
              "&:hover": {
                borderColor: "#1565c0",
                backgroundColor: "#e3f2fd",
              },
            }}
          >
            Back
          </Button>
        </Paper>

        {/* Content */}
        {isGettingMilestoneList ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Box>
        ) : getMilestoneListError ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: "50vh",
              backgroundColor: "#fef8f8",
              border: "1px solid #f44336",
              borderRadius: "8px",
              padding: "2rem",
              textAlign: "center",
            }}
          >
            <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
            <Typography
              variant="h6"
              fontWeight="bold"
              color="#f44336"
              sx={{ mb: 1 }}
            >
              Something Went Wrong
            </Typography>
            <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
              {getMilestoneListError}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() =>
                dispatch(getMilestoneList({ workloadId, accCode }))
              }
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderColor: "#1976d2",
                color: "#1976d2",
                "&:hover": {
                  borderColor: "#1565c0",
                  backgroundColor: "#e3f2fd",
                },
              }}
            >
              Retry
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              overflow: "hidden",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              disableSelectionOnClick
              hideFooterSelectedRowCount
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: () => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography>No rows</Typography>
                  </Box>
                ),
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    accountNumber: false,
                    accountCode: false,
                    orgCode: false,
                  },
                },
              }}
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-cell": {
                  py: 0.5,
                  fontSize: "14px",
                  fontFamily: "Arial, sans-serif",
                  textAlign: "center",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F5F5F5",
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#333",
                  position: "relative",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "1px solid #e0e0e0",
                  backgroundColor: "#F5F5F5",
                },
              }}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default MilestonesPage;
