import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getFlaggedDetails,
  clearAllWafrQuestionnaireMessages,
} from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";

const IssuesModal = ({ open, onClose, choiceId, choiceTitle, accNumber }) => {
  const dispatch = useDispatch();
  const { workloadId } = useParams();
  const [expandedIndex, setExpandedIndex] = useState(null);

  // Access the flagged details state from Redux
  const { isGettingFlaggedDetails, getFlaggedDetailsError, flaggedDetails } =
    useSelector((state) => state.wafrQuestionnaire);

  // Convert flaggedDetails to an array if it's an object
  const detailsArray =
    flaggedDetails && !Array.isArray(flaggedDetails)
      ? Object.values(flaggedDetails)
      : flaggedDetails;

  // Fetch flagged details when the modal opens
  useEffect(() => {
    if (open && choiceId && accNumber && workloadId) {
      dispatch(
        getFlaggedDetails({ accountNumber: accNumber, choiceId, workloadId })
      );
    }
  }, [open, choiceId, accNumber, workloadId, dispatch]);

  // Clear messages and close modal
  const handleClose = () => {
    dispatch(clearAllWafrQuestionnaireMessages());
    onClose();
  };

  // Toggle expand/collapse for rows
  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="issues-modal-title"
      aria-describedby="issues-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxHeight: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F5F5F5",
            padding: "16px 24px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Typography
            id="issues-modal-title"
            variant="h6"
            fontWeight="bold"
            sx={{
              maxWidth: "90%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {choiceTitle}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        {/* Content */}
        <Box
          sx={{ padding: "16px 24px", overflowY: "auto", maxHeight: "80vh" }}
        >
          {isGettingFlaggedDetails ? (
            <Typography>Loading...</Typography>
          ) : getFlaggedDetailsError ? (
            <Typography color="error">
              Error: {getFlaggedDetailsError}
            </Typography>
          ) : detailsArray && detailsArray.length > 0 ? (
            detailsArray.map((detail, index) => (
              <Box key={index} sx={{ marginBottom: "24px" }}>
                {/* Title */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    backgroundColor: "#F5F5F5",
                    padding: "12px 16px",
                    borderRadius: "8px",
                  }}
                  onClick={() => toggleExpand(index)}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {detail.name}
                  </Typography>
                  {expandedIndex === index ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </Box>

                {/* Description and DataGrid */}
                <Collapse
                  in={expandedIndex === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ marginTop: "16px" }}>
                    {/* Description Box */}
                    <Box
                      sx={{
                        backgroundColor: "#FAFAFA",
                        padding: "16px",
                        borderRadius: "8px",
                        marginBottom: "16px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          "& h4": { margin: "16px 0", fontWeight: "bold" },
                          "& a": {
                            color: "#1976d2",
                            textDecoration: "none",
                            "&:hover": { textDecoration: "underline" },
                          },
                          lineHeight: 1.6,
                        }}
                        dangerouslySetInnerHTML={{ __html: detail.description }}
                      />
                    </Box>

                    {/* DataGrid Box */}
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        padding: "16px",
                        borderRadius: "8px",
                        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                      }}
                    >
                      {detail.resourceDetails &&
                      detail.resourceDetails.length > 0 ? (
                        <TableContainer
                          sx={{
                            maxHeight: "40vh", // Limit the height of the scrollable table area
                            overflowY: "auto", // Allow vertical scrolling
                            borderRadius: "8px",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                {Object.keys(detail.resourceDetails[0]).map(
                                  (key) => (
                                    <TableCell
                                      key={key}
                                      sx={{
                                        fontWeight: "bold",
                                        position: "sticky",
                                        top: 0,
                                        backgroundColor:
                                          "rgba(245, 245, 245, 0.95)",
                                        color: "#000000",
                                        zIndex: 10,
                                        borderBottom: "2px solid #E0E0E0",
                                        padding: "8px 16px",
                                        boxShadow:
                                          "0 2px 2px rgba(0, 0, 0, 0.1)",
                                        backdropFilter: "blur(5px)", // Optional
                                      }}
                                    >
                                      {key}
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {detail.resourceDetails.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                  {Object.keys(row).map((key) => (
                                    <TableCell
                                      key={key}
                                      sx={{
                                        padding: "8px 16px", // Consistent padding for table rows
                                      }}
                                    >
                                      {row[key]}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography>No resource details available.</Typography>
                      )}
                    </Box>
                  </Box>
                </Collapse>
              </Box>
            ))
          ) : (
            <Typography>All the scanned resources are in compliance.</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default IssuesModal;
