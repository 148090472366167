import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Checkbox,
  Tooltip,
  Chip,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import awsRegions from "aws-regions";
import { useDispatch, useSelector } from "react-redux";
import { postWafrAssessment } from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import { getUsername } from "../../../helpers/jwt-token-access/userRole";
import InfoIcon from "@mui/icons-material/Info";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

const CreateAssessmentModal = ({ open, onClose, accounts, users, orgCode }) => {
  const dispatch = useDispatch();
  const { isPostingWafrAssessment } = useSelector(
    (state) => state.wafrQuestionnaire
  );

  const loggedinUserEmail = getUsername()?.toLowerCase();
  // const loggedinUserEmailDomain = loggedinUserEmail?.split("@")[1];

  // const mspUsers = users
  //   ?.filter((user) => {
  //     if (user?.username) {
  //       const userDomain = user.username.toLowerCase().split("@")[1];
  //       return userDomain === loggedinUserEmailDomain;
  //     }
  //     return false;
  //   })
  //   .map((user) => ({
  //     ...user,
  //     username: user.username.toLowerCase(),
  //   }));

  // // Filter out MSP users from the full users list for Assessment Owner dropdown
  // const nonMspUsers = users?.filter((user) => {
  //   if (user?.username) {
  //     const userDomain = user.username.toLowerCase().split("@")[1];
  //     return userDomain !== loggedinUserEmailDomain;
  //   }
  //   return false;
  // });

  const [formData, setFormData] = useState({
    assessmentName: "",
    assessmentDescription: "",
    accountNumber: "",
    assessmentAwsRegions: [],
    primaryRegion: null,
    assessmentApprover: loggedinUserEmail || "",
    assessmentOwner: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!formData.assessmentApprover && loggedinUserEmail) {
      setFormData((prev) => ({
        ...prev,
        assessmentApprover: loggedinUserEmail,
      }));
    }
  }, [loggedinUserEmail]);

  const validateField = (field, value, currentFormData) => {
    switch (field) {
      case "assessmentName":
        if (!value.trim()) return "Assessment name is required";
        if (value.trim().length < 3)
          return "Assessment name must be at least 3 characters";
        return null;

      case "assessmentDescription":
        if (!value.trim()) return "Description is required";
        if (value.trim().length < 3)
          return "Description must be at least 3 characters";
        return null;

      case "accountNumber":
        if (!value) return "Account number is required";
        return null;

      case "assessmentAwsRegions":
        if (value.length === 0) return "At least one region must be selected";
        if (!currentFormData.primaryRegion)
          return "Please select a primary region by clicking the star icon";
        return null;

      case "assessmentApprover":
        if (!value) return "Approver is required";
        return null;

      case "assessmentOwner":
        if (!value) return "Owner is required";
        return null;

      default:
        return null;
    }
  };

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Validate the field immediately
    const fieldError = validateField(field, value, formData);
    setErrors((prev) => ({
      ...prev,
      [field]: fieldError,
    }));
  };

  const handlePrimaryRegionSelect = (regionCode, event) => {
    event.stopPropagation();

    setFormData((prev) => {
      const newData = {
        ...prev,
        primaryRegion: prev.primaryRegion === regionCode ? null : regionCode,
      };

      // Validate with the new state immediately
      const fieldError = validateField(
        "assessmentAwsRegions",
        newData.assessmentAwsRegions,
        newData
      );
      setErrors((prev) => ({
        ...prev,
        assessmentAwsRegions: fieldError,
      }));

      return newData;
    });
  };

  const handleRegionChange = (event) => {
    const value = event.target.value;

    setFormData((prev) => {
      const newData = {
        ...prev,
        assessmentAwsRegions: value,
        // Clear primary region if it's deselected
        primaryRegion: value.includes(prev.primaryRegion)
          ? prev.primaryRegion
          : null,
      };

      // Validate with the new state immediately
      const fieldError = validateField("assessmentAwsRegions", value, newData);
      setErrors((prev) => ({
        ...prev,
        assessmentAwsRegions: fieldError,
      }));

      return newData;
    });
  };

  const handleCreateAssessment = () => {
    if (validateForm()) {
      const createAssessmentPayload = {
        orgCode,
        accountCode: accounts?.find(
          (account) => account.accountNumber === formData.accountNumber
        )?.accountCode,
        accountNumber: formData.accountNumber,
        workloadName: formData.assessmentName,
        description: formData.assessmentDescription,
        primaryRegion: formData.primaryRegion,
        regions: formData.assessmentAwsRegions,
        assessmentApprover: formData.assessmentApprover,
        assessmentOwner: formData.assessmentOwner,
      };
      dispatch(postWafrAssessment(createAssessmentPayload));
    }
  };

  const validateForm = () => {
    const newErrors = {
      assessmentName: validateField(
        "assessmentName",
        formData.assessmentName,
        formData
      ),
      assessmentDescription: validateField(
        "assessmentDescription",
        formData.assessmentDescription,
        formData
      ),
      accountNumber: validateField(
        "accountNumber",
        formData.accountNumber,
        formData
      ),
      assessmentAwsRegions: validateField(
        "assessmentAwsRegions",
        formData.assessmentAwsRegions,
        formData
      ),
      assessmentApprover: validateField(
        "assessmentApprover",
        formData.assessmentApprover,
        formData
      ),
      assessmentOwner: validateField(
        "assessmentOwner",
        formData.assessmentOwner,
        formData
      ),
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error !== null);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-assessment-modal"
      aria-describedby="modal-for-creating-assessment"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          maxHeight: "90vh",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 0,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F5F5F5",
            padding: "16px 24px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Create Workload
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        {/* Form */}
        <Box
          component="form"
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Assessment Name"
            required
            value={formData.assessmentName}
            onChange={handleChange("assessmentName")}
            error={!!errors.assessmentName}
            helperText={errors.assessmentName}
          />

          <TextField
            label="Assessment Description"
            required
            multiline
            minRows={3}
            maxRows={5}
            value={formData.assessmentDescription}
            onChange={handleChange("assessmentDescription")}
            error={!!errors.assessmentDescription}
            helperText={errors.assessmentDescription}
          />

          <FormControl fullWidth error={!!errors.accountNumber}>
            <InputLabel required>Account Number</InputLabel>
            <Select
              value={formData.accountNumber}
              onChange={handleChange("accountNumber")}
              label="Account Number *"
            >
              {accounts?.map((account) => (
                <MenuItem
                  key={account?.accountNumber}
                  value={account?.accountNumber}
                >
                  {account?.accountNumber} - {account?.accountName}
                </MenuItem>
              ))}
            </Select>
            {errors.accountNumber && (
              <Typography color="error" variant="caption">
                {errors.accountNumber}
              </Typography>
            )}
          </FormControl>

          <FormControl fullWidth error={!!errors.assessmentAwsRegions}>
            <InputLabel required>AWS Region</InputLabel>
            <Select
              multiple
              value={formData.assessmentAwsRegions}
              onChange={handleRegionChange}
              label="AWS Region *"
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((region) => (
                    <Chip
                      key={region}
                      label={region}
                      size="small"
                      sx={
                        formData.primaryRegion === region
                          ? {
                              bgcolor: "#E3F2FD",
                              borderColor: "#1976D2",
                              "& .MuiChip-label": {
                                color: "#1976D2",
                                fontWeight: 500,
                              },
                            }
                          : {}
                      }
                    />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
              {awsRegions.list().map((region) => {
                const isPrimary = formData.primaryRegion === region.code;
                const isSelected = formData.assessmentAwsRegions.includes(
                  region.code
                );

                return (
                  <MenuItem
                    key={region.code}
                    value={region.code}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      ...(isPrimary && {
                        bgcolor: "#E3F2FD",
                      }),
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox checked={isSelected} />
                      <Typography variant="body2">{region.name}</Typography>
                    </Box>

                    {isSelected && (
                      <Tooltip
                        title={
                          isPrimary
                            ? "Remove as primary"
                            : "Set as primary region"
                        }
                      >
                        <IconButton
                          size="small"
                          onClick={(e) =>
                            handlePrimaryRegionSelect(region.code, e)
                          }
                          sx={{
                            color: isPrimary ? "#1976D2" : "text.secondary",
                            "&:hover": {
                              color: "#1976D2",
                            },
                          }}
                        >
                          {isPrimary ? (
                            <StarIcon fontSize="small" />
                          ) : (
                            <StarBorderIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                color: "text.secondary",
                fontSize: "11px",
                fontStyle: "italic",
              }}
            >
              Please activate Discovery for your AWS account from the AWS
              Well-Architected Tool settings in your primary region. Select
              regions and mark one as primary by clicking the star icon.
            </Typography>
            <FormHelperText error={!!errors.assessmentAwsRegions}>
              {errors.assessmentAwsRegions}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth error={!!errors.assessmentApprover}>
            <InputLabel required>Assessment Approver</InputLabel>
            <Select
              value={formData.assessmentApprover}
              onChange={handleChange("assessmentApprover")}
              label="Assessment Approver *"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {users?.map((user) => (
                <MenuItem key={user?.username} value={user?.username}>
                  {user?.username}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                color: "text.secondary",
                fontSize: "11px",
                fontStyle: "italic",
              }}
            >
              Person responsible for reviewing the WAF assessment and ensuring
              compliance with WAFR requirements
            </Typography>
            {errors.assessmentApprover && (
              <Typography color="error" variant="caption">
                {errors.assessmentApprover}
              </Typography>
            )}
          </FormControl>

          <FormControl fullWidth error={!!errors.assessmentOwner}>
            <InputLabel required>Assessment Owner</InputLabel>
            <Select
              value={formData.assessmentOwner}
              onChange={handleChange("assessmentOwner")}
              label="Assessment Owner *"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {users?.map((user) => (
                <MenuItem key={user?.username} value={user?.username}>
                  {user?.username}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                color: "text.secondary",
                fontSize: "11px",
                fontStyle: "italic",
              }}
            >
              Person who owns the workload/application and responsible to
              remediate the findings
            </Typography>
            {errors.assessmentOwner && (
              <Typography color="error" variant="caption">
                {errors.assessmentOwner}
              </Typography>
            )}
          </FormControl>

          {/* Action Buttons */}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isPostingWafrAssessment}
              onClick={handleCreateAssessment}
            >
              {isPostingWafrAssessment ? "Saving..." : "Save"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateAssessmentModal;
