import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
  Badge
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import AttachmentUploader from "./AttachmentUploader";
import { 
  listWafrAttachments, 
  clearAllWafrQuestionnaireMessages,
  downloadWafrAttachment 
} from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";

// Helper function to get the original filename with UUID
const getOriginalFileName = (filePath) => {
  if (!filePath) return '';
  const parts = filePath.split('/');
  return parts[parts.length - 1];
};

// Helper function to get simplified filename
const getSimplifiedFileName = (filePath) => {
  if (!filePath) return '';
  const parts = filePath.split('/');
  const fullFileName = parts[parts.length - 1];
  // Remove UUID from filename (assuming UUID is between last underscore and extension)
  const nameWithoutUUID = fullFileName.replace(/_[0-9a-f-]+(\.[^.]+)$/, '$1');
  return nameWithoutUUID;
};

const AttachmentsBox = ({ 
  disabled = false,
  accountCode,
  workloadId,
  questionId
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [downloadingFile, setDownloadingFile] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 6000,
  });

  const {
    isListingWafrAttachments,
    listWafrAttachmentsMessage,
    listWafrAttachmentsError,
    wafrAttachmentsList,
    isDownloadingWafrAttachment,
    downloadWafrAttachmentMessage,
    downloadWafrAttachmentError,
    wafrAttachmentDownloadUrl
  } = useSelector((state) => state.wafrQuestionnaire);

  // Fetch attachments when component mounts or when dependencies change
  useEffect(() => {
    if (accountCode && workloadId && questionId) {
      dispatch(listWafrAttachments({
        accountCode,
        workloadId,
        questionId
      }));
    }
  }, [accountCode, workloadId, questionId, dispatch]);

  // Handle API messages
  useEffect(() => {
    if (listWafrAttachmentsMessage) {
      console.log("listWafrAttachmentsMessage:: ", listWafrAttachmentsMessage)
      // setSnackbar({
      //   open: true,
      //   message: listWafrAttachmentsMessage,
      //   severity: "success",
      //   autoHideDuration: 6000,
      // });
    }
    if (listWafrAttachmentsError) {
      setSnackbar({
        open: true,
        message: listWafrAttachmentsError,
        severity: "error",
        autoHideDuration: 6000,
      });
    }

    if (listWafrAttachmentsMessage || listWafrAttachmentsError) {
      dispatch(clearAllWafrQuestionnaireMessages());
    }
  }, [listWafrAttachmentsMessage, listWafrAttachmentsError, dispatch]);

  // Handle download messages and URL
  useEffect(() => {
    if (downloadWafrAttachmentError) {
      setSnackbar({
        open: true,
        message: downloadWafrAttachmentError,
        severity: "error",
        autoHideDuration: 6000,
      });
      setDownloadingFile(null);
    }

    if (wafrAttachmentDownloadUrl?.downloadUrl && downloadingFile) {
      // Open in new tab to trigger download
      window.open(wafrAttachmentDownloadUrl.downloadUrl, '_blank', 'noopener,noreferrer');
      
      setSnackbar({
        open: true,
        message: "File download started",
        severity: "success",
        autoHideDuration: 6000,
      });
      setDownloadingFile(null);
    }

    if (downloadWafrAttachmentMessage || downloadWafrAttachmentError) {
      dispatch(clearAllWafrQuestionnaireMessages());
    }
  }, [downloadWafrAttachmentMessage, downloadWafrAttachmentError, wafrAttachmentDownloadUrl, downloadingFile, dispatch]);

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleDownload = (attachment) => {
    setDownloadingFile(attachment.fullPath);
    dispatch(downloadWafrAttachment({
      accountCode,
      workloadId,
      questionId,
      fileName: getOriginalFileName(attachment.fullPath) // Send only the filename
    }));
  };

  // Transform the files array into the expected format
  const attachments = Array.isArray(wafrAttachmentsList?.files) 
    ? wafrAttachmentsList.files.map(filePath => ({
        id: filePath,
        name: getSimplifiedFileName(filePath),
        fullPath: filePath,
        date: '-'  // Add actual date if available
      }))
    : [];

  const filteredAttachments = attachments.filter((attachment) =>
    attachment.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
      sx={{
        padding: "0.5rem",
        borderRadius: "0.25rem",
        border: "1px solid #ccc",
        backgroundColor: "#F9F9F9",
        marginBottom: "0.5rem",
        marginRight: "0.5rem",
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              pr: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                Attachments
              </Typography>
              <Tooltip title="Select a single file to upload">
                <span>
                  <Button
                    component="label"
                    startIcon={<AttachFileIcon />}
                    size="small"
                    sx={{ ml: 2 }}
                    disabled={disabled}
                    htmlFor="file-upload-input"
                  >
                    Upload
                  </Button>
                </span>
              </Tooltip>
              <AttachmentUploader
                accountCode={accountCode}
                workloadId={workloadId}
                questionId={questionId}
                disabled={disabled}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isListingWafrAttachments ? (
                <CircularProgress size={16} sx={{ mr: 1 }} />
              ) : (
                <Badge 
                  badgeContent={attachments.length}
                  color="primary"
                  sx={{
                    '& .MuiBadge-badge': {
                      fontSize: '0.8rem',
                      height: '20px',
                      minWidth: '20px',
                      borderRadius: '10px',
                    }
                  }}
                />
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search attachments..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1, color: "gray" }} />,
                sx: { fontSize: "0.8rem" },
              }}
            />
          </Box>
          <TableContainer>
            {isListingWafrAttachments ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>Name</TableCell>
                    {/* <TableCell sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>Date</TableCell> */}
                    <TableCell sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAttachments.map((attachment) => (
                    <TableRow key={attachment.id}>
                      <TableCell sx={{ fontSize: "0.8rem" }}>{attachment.name}</TableCell>
                      {/* <TableCell sx={{ fontSize: "0.8rem" }}>{attachment.date}</TableCell> */}
                      <TableCell>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Tooltip title="Download file">
                            <IconButton
                              size="small"
                              onClick={() => handleDownload(attachment)}
                              disabled={disabled || isDownloadingWafrAttachment || downloadingFile === attachment.fullPath}
                            >
                              {isDownloadingWafrAttachment && downloadingFile === attachment.fullPath ? (
                                <CircularProgress size={20} />
                              ) : (
                                <DownloadIcon fontSize="small" />
                              )}
                            </IconButton>
                          </Tooltip>
                          
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {(!Array.isArray(wafrAttachmentsList?.files) || wafrAttachmentsList.files.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={4} align="center" sx={{ fontSize: "0.8rem" }}>
                        No attachments found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Snackbar for messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.autoHideDuration}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AttachmentsBox; 