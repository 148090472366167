import React, { useState } from "react";
import { useCreateKibanaDashboardURL } from "../CustomHooks";
import AnimatedLogo from "../CloudCatcherAnimatedLogo/CloudCatcherAnimatedLogo";

const GetKibanaDashboard = ({ dashboardKey, params }) => {
  const [isIframeReady, setIsIframeReady] = useState(false);
  const { dashboardURL, isLoading, errorMessage } = useCreateKibanaDashboardURL(
    dashboardKey,
    params
  );

  const handleIframeLoad = () => {
    setIsIframeReady(true);
  };

  return (
    <div className="box" id="tableauDiv" style={{ marginTop: "0px" }}>
      <div className="iframe-container">
        {isLoading ? (
          // Loading State
          <div className="kibana-loading-container" id="kibanaDashboardLoading">
            <AnimatedLogo />
          </div>
        ) : errorMessage ? (
          // Error State
          <div className="kibana-loading-container" id="kibanaDashboardLoading">
            <div>Error: {errorMessage}</div>
          </div>
        ) : (
          // Iframe State (Success)
          <div>
            <iframe
              src={dashboardURL}
              className="iframe-second-row"
              id="elasticsearchid"
              onLoad={handleIframeLoad}
              style={{ display: isIframeReady ? "block" : "none" }}
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetKibanaDashboard;
