import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Link,
} from "@react-pdf/renderer";
import PoweredByLogo from "../../../assets/images/Expanded_Colored_PoweredBy.png";
import parse from 'html-react-parser';
import { getFormattedQuestion, getImprovementPlanUrl } from "../../WorkloadAssessment/components/questionService";

// Register fonts for better typography
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: 300,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      fontWeight: 400,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 700,
    },
  ],
});

// Add Font Awesome font registration
Font.register({
  family: "FontAwesome",
  src: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/webfonts/fa-solid-900.ttf",
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    position: "relative",
    fontFamily: "Roboto",
  },
  backgroundWatermark: {
    position: "absolute",
    width: "50%", // Control size of watermark
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: 0.03, // Very subtle watermark
    zIndex: -1,
  },
  coverPage: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
    padding: 40,
  },
  headerLogo: {
    width: 250,
    marginBottom: 60,
    alignSelf: "center",
  },
  title: {
    fontSize: 28,
    marginBottom: 60,
    color: "#2c3e50",
    fontWeight: "bold",
    textAlign: "center",
    borderBottom: "2px solid #3498db",
    paddingBottom: 20,
  },
  detailsContainer: {
    marginTop: 40,
    backgroundColor: "#f8f9fa",
    padding: 30,
    borderRadius: 8,
    border: "1px solid #e0e0e0",
  },
  detailRow: {
    flexDirection: "row",
    marginBottom: 25,
    alignItems: "center",
    flexWrap: "wrap",
  },
  label: {
    width: 150,
    fontSize: 14,
    color: "#34495e",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  value: {
    flex: 1,
    fontSize: 12, // Reduce font size if needed
    color: "#2c3e50",
    paddingLeft: 10,
    borderLeft: "3px solid #3498db",
    wordBreak: "break-word", // Breaks long words
    wrap: true, // Ensures text wraps properly
    maxWidth: "100%", // Prevents text overflow
    whiteSpace: "pre-wrap", // Ensures proper text formatting
    textAlign: "left", // Aligns text to left
    lineHeight: 1.5, // Adds spacing for better readability
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 8,
    color: "#333",
    fontWeight: 500,
  },
  section: {
    marginBottom: 25,
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 10,
    color: "#1976d2",
    fontWeight: 500,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
    borderBottomStyle: "solid",
  },
  table: {
    width: "100%",
    marginVertical: 10,
    border: "1 solid #e0e0e0",
    backgroundColor: "white",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1 solid #e0e0e0",
    minHeight: 30,
    alignItems: "center",
  },
  tableHeader: {
    backgroundColor: "#f5f5f5",
    borderBottom: "2 solid #e0e0e0",
  },
  tableCell: {
    flex: 1,
    padding: 6,
    fontSize: 8,
    textAlign: 'center',
    borderRight: "1 solid #e0e0e0",
  },
  tableCellHeader: {
    fontWeight: 'bold',
    color: '#2c3e50',
    fontSize: 8,
  },
  pillarCell: {
    flex: 2,
    textAlign: 'left',
    paddingLeft: 10,
    fontSize: 8,
  },
  totalRow: {
    backgroundColor: '#f8f9fa',
    borderTop: "1 solid #e0e0e0",
  },
  pillarSection: {
    marginVertical: 10,
  },
  question: {
    marginVertical: 5,
  },
  questionTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#333',
    flexWrap: 'wrap'
  },
  riskChip: {
    padding: "3 8",
    borderRadius: 12,
    fontSize: 10,
    color: "white",
    alignSelf: "flex-start",
    marginTop: 3,
    textAlign: 'center'
  },
  resourceItem: {
    marginVertical: 2,
    fontSize: 10,
  },
  framework: {
    fontSize: 12,
    color: "#666",
    marginBottom: 15,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    right: 30,
    color: "#666",
  },
  questionDetails: {
    marginLeft: 15,
    marginTop: 5,
    fontSize: 10,
  },
  questionInfo: {
    flexDirection: "row",
    marginTop: 3,
    gap: 20,
  },
  infoLabel: {
    color: "#666",
    fontWeight: 500,
  },
  bestPractice: {
    marginTop: 5,
    fontSize: 10,
    color: "#333",
  },
  riskText: {
    fontSize: 10,
    color: "#666",
    marginRight: 10,
  },
  statusText: {
    fontSize: 10,
    color: "#666",
  },
  bestPracticeList: {
    marginLeft: 15,
    marginTop: 8,
  },
  bestPracticeItem: {
    fontSize: 10,
    marginBottom: 4,
    color: "#333",
  },
  resourceDetails: {
    marginTop: 10,
    marginBottom: 20,
  },
  resourceQuestion: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 8,
    color: "#333",
  },
  reportDate: {
    marginTop: 25,
    fontSize: 10,
    color: "#666",
    fontWeight: 500,
  },
  pillarHeader: {
    backgroundColor: '#f8f9fa',
    padding: 10,
    marginBottom: 5,
    borderLeft: 4,
    borderLeftColor: '#1976d2',
    borderRadius: 4,
  },
  pillarTitle: {
    color: '#1976d2',
    fontSize: 16,
    fontWeight: 'bold',
  },
  questionPaper: {
    marginBottom: 10,
    padding: 10,
    backgroundColor: '#ffffff',
    border: "1px solid #e0e0e0",
    borderColor: '#e0e0e0',
    borderRadius: 4,
  },
  questionHeader: {
    marginBottom: 10,
    padding: 8,
    backgroundColor: '#f5f5f5',
    borderLeft: 4,
    borderLeftColor: '#666666',
    borderRadius: 2,
  },
  riskRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  riskLabel: {
    fontSize: 10,
    marginRight: 5,
  },
  statusChip: {
    padding: '2 4',
    borderRadius: 8,
    fontSize: 9,
    color: 'white',
    textAlign: 'center',
    minWidth: 60,
  },
  // Resource Summary specific styles
  resourceTable: {
    marginBottom: 15,
    border: "1px solid #e0e0e0",
    borderColor: '#e0e0e0',
    borderRadius: 4,
    width: '100%',
  },
  resourceRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    padding: 8,
    minHeight: 30,
    pageBreakInside: 'avoid',
  },
  resourceCell: {
    fontSize: 8,
    padding: 4,
  },
  resourceHeaderCell: {
    fontSize: 8,
    fontWeight: 'bold',
    color: '#666',
    padding: 4,
    flexWrap: 'wrap',
  },
  detailsTable: {
    width: '100%',
    marginTop: 6,
    marginBottom: 10,
  },
  detailsTableHeader: {
    backgroundColor: '#f5f5f5',
    flexDirection: 'row',
    padding: 3,
  },
  detailsTableRow: {
    flexDirection: 'row',
    // IMPORTANT: Set flexWrap to 'nowrap' so that the entire row stays on one line.
    flexWrap: 'nowrap',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    padding: 3,
    minHeight: 16,
  },
  detailsTableCell: {
    fontSize: 7,
    padding: 3,
    wordBreak: 'break-word',
    // We assign dynamic width in the component via flexBasis.
  },
  resourceCount: {
    fontSize: 8,
    color: '#666',
    marginBottom: 4,
  },
  statusIcon: {
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  footnote: {
    fontSize: 6,
    color: '#666',
    marginTop: 1,
    textAlign: 'center',
  },
  questionSection: {
    marginBottom: 20,
    borderRadius: 4,
    padding: 10,
    backgroundColor: '#fff',
  },
  choiceTable: {
    width: '100%',
    marginTop: 10,
  },
  choiceRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    minHeight: 30,
    padding: 8,
  },
  choiceTitle: {
    flex: 3,
    fontSize: 9,
  },
  choiceStatus: {
    flex: 1,
    fontSize: 9,
    textAlign: 'center',
  },
  choiceNature: {
    flex: 1,
    fontSize: 9,
    textAlign: 'center',
  },
  resourceSection: {
    marginTop: 15,
    padding: 10,
    backgroundColor: '#f9f9f9',
    borderRadius: 4,
  },
  resourceTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  resourceDescription: {
    fontSize: 9,
    marginBottom: 10,
    color: '#666',
  },
  resourceTableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    padding: 6,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  resourceTableCell: {
    flex: 1,
    fontSize: 8,
    padding: 4,
  },
  // Add/update resource section styles
  resourcePageHeader: {
    fontSize: 18,
    marginBottom: 15,
    color: '#333',
    fontWeight: 500,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    paddingBottom: 5,
  },
  resourceContainer: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  resourceHeader: {
    marginBottom: 20,
    borderBottom: 1,
    borderBottomColor: '#e0e0e0',
    paddingBottom: 10,
  },
  resourceTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 8,
  },
  resourceDescription: {
    fontSize: 10,
    color: '#666',
    lineHeight: 1.4,
    marginBottom: 15,
  },
  resourceDetailsContainer: {
    marginTop: 15,
  },
  resourceTableHeader: {
    backgroundColor: '#f5f5f5',
    padding: 8,
    flexDirection: 'row',
    borderBottom: 1,
    borderBottomColor: '#e0e0e0',
  },
  resourceTableCell: {
    flex: 1,
    padding: 6,
    fontSize: 8,
    textAlign: 'left',
  },
  resourceTableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    minHeight: 25,
    padding: 4,
  },
  paginationInfo: {
    fontSize: 9,
    color: '#666',
    textAlign: 'right',
    marginBottom: 8,
  },
  statusIndicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
  improvementTable: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: '#e0e0e0',
  },
  improvementHeader: {
    backgroundColor: '#f5f5f5',
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  improvementHeaderText: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#666',
  },
  improvementRow: {
    flexDirection: 'row',
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  improvementTitle: {
    flex: 1,
    fontSize: 9,
    color: '#1976d2',
    textDecoration: 'underline',
  }
});

const getRiskColor = (risk) => {
  switch (risk?.toUpperCase()) {
    case "HIGH":
      return "#FF4444";
    case "MEDIUM":
      return "#FFA500";
    case "LOW":
      return "#4CAF50";
    case "NONE":
      return "#2196F3";
    default:
      return "#757575";
  }
};

const getStatusColor = (status) => {
  switch (status?.toUpperCase()) {
    case "GREEN":
      return "#4CAF50";
    case "YELLOW":
      return "#FFA500";
    case "RED":
      return "#FF4444";
    case "UNSELECTED":
      return "#757575";
    default:
      return "#757575";
  }
};

const getFormattedDate = () => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };
  return new Date().toLocaleDateString("en-US", options);
};

// Add this helper function at the top
const filterHighRiskDetails = (details) => {
  if (!Array.isArray(details)) return [];
  return details
    .filter(detail => 
      ['YELLOW', 'RED'].includes(detail.Status?.toUpperCase())
    )
};

const renderResourceDetailsTable = (resource) => {
  if (!resource?.resourceDetails?.length) {
    return (
      <View>
        <Text style={{ fontSize: 10, fontWeight: "bold" }}>
          No resource details available
        </Text>
      </View>
    );
  }
  
  // Compute dynamic columns from the first row.
  const columns = Object.keys(resource.resourceDetails[0]);
  // Calculate each cell's width as an equal fraction of 100%.
  const cellWidth = columns.length > 0 ? `${(100 / columns.length).toFixed(2)}%` : "100%";

  return (
    <View style={styles.detailsTable}>
      {/* Table Header */}
      <View style={styles.detailsTableHeader} minPresenceAhead={30}>
        {columns.map((col) => (
          <Text
            key={col}
            style={[
              styles.detailsTableCell,
              {
                fontSize: 7,
                fontWeight: "bold",
                flexBasis: cellWidth,
                flexGrow: 0,
                flexShrink: 0,
                textAlign: "center",
                wordBreak: "break-word",
                whiteSpace: "pre-wrap",
              },
            ]}
          >
            {col}
          </Text>
        ))}
      </View>
      {/* Table Rows */}
      {filterHighRiskDetails(resource.resourceDetails).map((detail, idx) => (
        <View key={idx} style={styles.detailsTableRow} minPresenceAhead={15}>
          {columns.map((col) => (
            <Text
              key={col}
              style={[
                styles.detailsTableCell,
                {
                  fontSize: 7,
                  flexBasis: cellWidth,
                  flexGrow: 0,
                  flexShrink: 0,
                  textAlign: "center",
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                },
              ]}
            >
              {detail[col] || "-"}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

const renderFormattedText = (htmlContent) => {
  if (!htmlContent) return null;

  // Pre-process the HTML to handle <br> tags properly
  const processedHtml = htmlContent
    .replace(/<br\s*\/?>/gi, '{{LINEBREAK}}') // Replace <br> with marker
    .replace(/\n/g, '{{LINEBREAK}}'); // Also handle \n

  const options = {
    replace: (domNode) => {
      if (domNode.type === 'tag') {
        switch (domNode.name) {
          case 'h4':
            return (
              <Text style={[styles.resourceDescription, { 
                fontSize: 8,
                fontWeight: 'bold',
                marginTop: 8,
                marginBottom: 4
              }]}>
                {domNode.children[0].data}
                {'\n'}
              </Text>
            );
          case 'a':
            return (
              <Link
                src={domNode.attribs.href}
                style={[styles.resourceDescription, { 
                  color: '#0066cc'
                }]}
              >
                {domNode.children[0].data}
              </Link>
            );
          case 'b':
          case 'strong':
            return (
              <Text style={[styles.resourceDescription, { fontWeight: 'bold' }]}>
                {domNode.children[0].data}
              </Text>
            );
          default:
            return null;
        }
      }
    }
  };

  // Parse the HTML and split by line breaks
  const parsedContent = parse(processedHtml, options);
  const textContent = React.Children.map(parsedContent, child => {
    if (typeof child === 'string') {
      return child.split('{{LINEBREAK}}').map((text, i, arr) => (
        <React.Fragment key={i}>
          <Text>{text}</Text>
          {i < arr.length - 1 && <Text>{'\n'}</Text>}
        </React.Fragment>
      ));
    }
    return child;
  });

  return (
    <View style={{ marginVertical: 2 }}>
      <Text style={styles.resourceDescription}>
        {textContent}
      </Text>
    </View>
  );
};

const PDFTemplate = ({ data }) => {
  const {
    assessmentName,
    accNumber,
    frameworkName,
    wafrSummary,
    wafrQuestionnaire,
    wafrReport,
    getWafrReportError,
    workloadArn,
    ownerName,
    approverName,
  } = data;
  console.log("🚀 ~ PDFTemplate ~ frameworkName:", frameworkName);

  const renderSummarySection = () => {
    // Calculate totals from pillar summaries
    const totals = wafrSummary?.pillarSummaries?.reduce(
      (acc, pillar) => ({
        total: (acc.total || 0) + (pillar.riskCounts?.total || 0),
        unanswered:
          (acc.unanswered || 0) + (pillar.riskCounts?.unanswered || 0),
        high: (acc.high || 0) + (pillar.riskCounts?.high || 0),
        medium: (acc.medium || 0) + (pillar.riskCounts?.medium || 0),
        none: (acc.none || 0) + (pillar.riskCounts?.none || 0),
        notApplicable:
          (acc.notApplicable || 0) + (pillar.riskCounts?.not_APPLICABLE || 0),
      }),
      {}
    );

    return (
      <View style={styles.section}>
        <View style={styles.table}>
          {/* Header Row */}
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text
              style={[
                styles.tableCell,
                styles.tableCellHeader,
                styles.pillarCell,
              ]}
            >
              Pillar
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              Total Questions
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              Unanswered
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              High Risk
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              Medium Risk
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>None</Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              Not Applicable
            </Text>
          </View>

          {/* Data Rows */}
          {wafrSummary?.pillarSummaries?.map((pillar, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.pillarCell]}>
                {pillar.pillarName}
              </Text>
              <Text style={styles.tableCell}>
                {pillar.riskCounts?.total || "0"}
              </Text>
              <Text style={styles.tableCell}>
                {pillar.riskCounts?.unanswered || "0"}
              </Text>
              <Text style={styles.tableCell}>
                {pillar.riskCounts?.high || "0"}
              </Text>
              <Text style={styles.tableCell}>
                {pillar.riskCounts?.medium || "0"}
              </Text>
              <Text style={styles.tableCell}>
                {pillar.riskCounts?.none || "0"}
              </Text>
              <Text style={styles.tableCell}>
                {pillar.riskCounts?.not_APPLICABLE || "0"}
              </Text>
            </View>
          ))}

          {/* Total Row */}
          <View style={[styles.tableRow, styles.totalRow]}>
            <Text
              style={[
                styles.tableCell,
                styles.tableCellHeader,
                styles.pillarCell,
              ]}
            >
              Total (includes all pillars)
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              {totals?.total || "0"}
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              {totals?.unanswered || "0"} ({((totals?.unanswered / totals?.total) * 100).toFixed(2)}%)
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              {totals?.high || "0"} ({((totals?.high / totals?.total) * 100).toFixed(2)}%)
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              {totals?.medium || "0"} ({((totals?.medium / totals?.total) * 100).toFixed(2)}%)
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              {totals?.none || "0"} ({((totals?.none / totals?.total) * 100).toFixed(2)}%)
            </Text>
            <Text style={[styles.tableCell, styles.tableCellHeader]}>
              {totals?.notApplicable || "0"} ({((totals?.notApplicable / totals?.total) * 100).toFixed(2)}%)
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const renderQuestionsSection = () => (
    <>
      {Object.entries(wafrQuestionnaire || {}).map(([pillar, questions]) => (
        questions.map((question, qIndex) => (
          <Page key={`${pillar}-${qIndex}`} size="A4" style={styles.page}>
            <View style={styles.pillarHeader}>
              <Text style={styles.pillarTitle}>{pillar}</Text>
            </View>
            
            <View style={styles.questionSection} wrap={false}>
              <View style={{ flexDirection: 'column' }}>
                <Text style={styles.questionTitle}>
                  {getFormattedQuestion(question.questionTitle)}
                  {/* Question {qIndex + 1}: {question.questionTitle} */}
                </Text>
                <View style={{ alignSelf: 'flex-start', marginTop: 4 }}>
                  <View style={[styles.riskChip, { backgroundColor: getRiskColor(question.risk) }]}>
                    <Text style={{ color: 'white', fontSize: 8 }}>
                      Risk: {question.risk || 'Unanswered'}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.choiceTable}>
                <View style={[styles.choiceRow, { backgroundColor: '#f5f5f5' }]}>
                  <Text style={[styles.choiceTitle, { fontWeight: 'bold' }]}>Best Practice</Text>
                  <Text style={[styles.choiceStatus, { fontWeight: 'bold' }]}>Status</Text>
                  <Text style={[styles.choiceNature, { fontWeight: 'bold' }]}>Nature</Text>
                </View>
                
                {question.choices.map((choice) => (
                  <View key={choice.choiceId} style={styles.choiceRow}>
                    <Text style={styles.choiceTitle}>{choice.title}</Text>
                    <Text style={styles.choiceStatus}>{choice.status?.toUpperCase() === "SELECTED" ? "Answered" : "Unanswered"}</Text>
                    <Text style={styles.choiceNature}>{choice.automated ? 'Automated' : 'Manual'}</Text>
                  </View>
                ))}
              </View>

              {question.choices.some(choice => 
                choice.status?.toUpperCase() !== "SELECTED" && getImprovementPlanUrl(choice.title)
              ) && (
                <View style={styles.improvementTable} break>
                  <View style={styles.improvementHeader}>
                    <Text style={styles.improvementHeaderText}>Improvement Plans</Text>
                  </View>
                  {question.choices.map(choice => (
                    choice.status?.toUpperCase() !== "SELECTED" && getImprovementPlanUrl(choice.title) && (
                      <View key={choice.choiceId} style={styles.improvementRow}>
                        <Link 
                          src={getImprovementPlanUrl(choice.title)}
                          style={styles.improvementTitle}
                        >
                          {choice.title}
                        </Link>
                      </View>
                    )
                  ))}
                </View>
              )}
            </View>
            
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
          </Page>
        ))
      ))}
    </>
  );

  const renderResourcesSection = () =>
    Object.entries(wafrReport || {}).map(([pillarName, questions]) =>
      questions.map((question) => (
        <Page key={`${pillarName}-${question.questionId}`} size="A4" style={styles.page}>
          <Text style={styles.subtitle}>Resources Summary</Text>
          <View style={styles.resourceTable}>
            <View style={styles.pillarHeader}>
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Text style={styles.pillarTitle}>{pillarName}</Text>
                <View style={[styles.riskChip, { backgroundColor: getRiskColor(question.risk) }]}>
                  <Text style={{ color: "white", fontSize: 8 }}>
                    Risk: {question.risk || "NONE"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.resourceRow}>
              <Text style={styles.resourceHeaderCell}>Question</Text>
              <Text style={styles.resourceCell}>{question.questionTitle}</Text>
            </View>
            {question.choices.map((choice, index) => (
              <View key={choice.choiceId}>
                <View style={styles.resourceRow}>
                  <Text style={styles.resourceHeaderCell}>Best Practice {index + 1}</Text>
                  <Text style={styles.resourceCell}>{choice.title}</Text>
                  <View style={[styles.statusChip, { backgroundColor: getStatusColor(choice.status) }]}>
                    <Text style={{ color: "white", fontSize: 9 }}>
                      Status: {choice.status?.toUpperCase() === "SELECTED" ? "Answered" : "Unanswered"}
                    </Text>
                  </View>
                </View>
                <View style={styles.resourceRow}>
                  <Text style={styles.resourceHeaderCell}>Choice Description </Text>
                  <Text style={styles.resourceCell}>{choice.description || '-'}</Text>
                </View>
                {choice.resources?.map((resource) => (
                  <View key={resource.checkID} style={styles.resourceRow}>
                    <View style={{ padding: 8 }}>
                      <Text style={styles.resourceTitle}>{resource.name}</Text>
                      {/* Wrap description and table in separate Views.
                          The table View uses the "break" prop so that if it doesn't fit, it will start on a new page */}
                      <View>
                        {renderFormattedText(resource.description)}
                      </View>
                      <View break>
                        {resource.resourceDetails &&
                          resource.resourceDetails.length > 0 &&
                          renderResourceDetailsTable(resource)}
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            ))}
            <Text style={styles.footnote}>
            Note: Resource details table shows up to 100 resources only.
          </Text>
          </View>
          
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
        </Page>
      ))
    );

  return (
    <Document>
      {/* Cover Page */}
      <Page size="A4" style={styles.page}>
        {/* Watermark for all pages */}
        <Image src={PoweredByLogo} style={styles.backgroundWatermark} fixed />

        <View style={styles.coverPage}>
          {/* Logo at top */}
          <Image src={PoweredByLogo} style={styles.headerLogo} />

          {/* Title */}
          <Text style={styles.title}>Workload Assessment Report</Text>

          {/* Details */}
          <View style={styles.detailsContainer}>
            <View style={styles.detailRow}>
              <Text style={styles.label}>Assessment Name:</Text>
              <Text style={styles.value}>{assessmentName || "N/A"}</Text>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Workload Arn:</Text>
              <Text style={styles.value}>{workloadArn || "N/A"}</Text>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>AWS Account:</Text>
              <Text style={styles.value}>{accNumber || "N/A"}</Text>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Organization:</Text>
              <Text style={styles.value}>
                {JSON.parse(localStorage.getItem("selectedOrganization"))
                  ?.orgName || "N/A"}
              </Text>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Framework:</Text>
              <Text style={styles.value}>{frameworkName || "N/A"}</Text>
            </View>

            <View style={styles.detailRow}>
              <Text style={styles.label}>Report Date:</Text>
              <Text style={styles.value}>{getFormattedDate()} (UTC)</Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>

      {/* Summary Section */}
      <Page size="A4" style={styles.page}>
        <Text style={styles.subtitle}>Summary</Text>
        {renderSummarySection()}
      </Page>

      {/* Questions Section - Now handled with multiple pages */}
      {renderQuestionsSection()}

      {/* Resources Section - Now handled with multiple pages */}
      {renderResourcesSection()}
    </Document>
  );
};

export default PDFTemplate;
