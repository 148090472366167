import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, Button, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SectionTabs from "./SectionTabs";
import sectionDescriptions from "../sectionDescriptions.json";
import SectionDetails from "./SectionDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CreateMilestoneModal from "./CreateMilestoneModal";
import { enqueueSnackbar } from "notistack";
import { clearAllWafrQuestionnaireMessages } from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import { isAuditor } from "../../../helpers/jwt-token-access/isAuditor";
import TimelineIcon from "@mui/icons-material/Timeline";

const WorkloadAssessment = ({
  wlaData,
  orgCode,
  accCode,
  accNumber,
  assessmentName,
  frameworkName,
  ownerName,
  approverName,
  isReadOnly = false,
  workloadId,
  handleFilterChange,
  status,
  initialData,
}) => {
  console.log("🚀 ~ workloadId:", workloadId);
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState("Operational Excellence");
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [openMilestoneModal, setOpenMilestoneModal] = useState(false);

  const { createMilestoneMessage, createMilestoneError } = useSelector(
    (state) => state.wafrQuestionnaire
  );
  console.log("🚀 ~ createMilestoneError:", createMilestoneError);
  console.log("🚀 ~ createMilestoneMessage:", createMilestoneMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (createMilestoneMessage) {
      enqueueSnackbar(createMilestoneMessage, { variant: "success" });
      setOpenMilestoneModal(false);
    }
    if (createMilestoneError) {
      enqueueSnackbar(createMilestoneError, { variant: "error" });
    }

    if (createMilestoneMessage || createMilestoneError) {
      dispatch(clearAllWafrQuestionnaireMessages());
    }
  }, [createMilestoneMessage, createMilestoneError, dispatch]);

  if (!wlaData) {
    return null;
  }

  const tabSections = Object.keys(wlaData);
  const sectionInfo = sectionDescriptions[selectedTab];
  const sectionQuestionDetailList = wlaData[selectedTab];
  const sectionQuestionList = sectionQuestionDetailList?.map((que) => ({
    questionTitle: que.questionTitle,
    risk: que.risk,
    reason: que.reason,
  }));
  const sectionQuestionLabel = sectionInfo?.label;

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedQuestion(0);
  };

  const handleMilestonesClick = () => {
    history.push(`/workload-assessment/milestones`, {
      workloadId,
      orgCode,
      accCode,
      accNumber,
      assessmentName,
      frameworkName,
      ownerName,
      approverName,
      status,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Header Section */}
      <Paper
        elevation={2}
        sx={{
          padding: "0.8rem 1.2rem",
          borderRadius: "8px",
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#f9fafe",
        }}
      >
        {/* Left Section: All Details in One Line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1.5rem",
            fontSize: "0.875rem",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "600",
              color: "#2a3c50",
              whiteSpace: "nowrap",
            }}
          >
            {assessmentName || "Workload Name N/A"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              color: "#6b7280",
            }}
          >
            | Framework Name: {frameworkName || "N/A"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              color: "#6b7280",
            }}
          >
            | Assessment Owner: {ownerName || "N/A"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              color: "#6b7280",
            }}
          >
            | Approver Name: {approverName || "N/A"}
          </Typography>
        </Box>

        {/* Right Section: Back Button and Create Milestone */}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            onClick={() => history.goBack()}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#1976d2",
              color: "#1976d2",
              "&:hover": {
                borderColor: "#1565c0",
                backgroundColor: "#e3f2fd",
              },
            }}
          >
            Back
          </Button>

          {/* Milestones Button */}
          <Button
            variant="outlined"
            onClick={handleMilestonesClick}
            startIcon={<TimelineIcon sx={{ color: "#0288d1" }} />}
            sx={{
              textTransform: "none",
              color: "#0288d1",
              fontSize: "14px",
              borderColor: "#0288d1",
              "&:hover": {
                borderColor: "#0288d1",
                backgroundColor: "rgba(2, 136, 209, 0.04)",
              },
            }}
          >
            Milestones
          </Button>

          {/* Create Milestone Button */}
          {!isReadOnly && (
            <Tooltip
              title={
                !isAuditor()
                  ? "Only Auditors have access to this feature"
                  : status === "COMPLETED"
                  ? "Cannot create milestone for completed assessment"
                  : ""
              }
              arrow
            >
              <span>
                <Button
                  variant="contained"
                  onClick={() => setOpenMilestoneModal(true)}
                  sx={{
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                  disabled={!isAuditor() || status === "COMPLETED"}
                >
                  Create Milestone
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
      </Paper>

      {/* Main Content */}
      <Paper
        sx={{
          backgroundColor: "white",
          borderRadius: "0.5rem",
          padding: "0.5rem",
        }}
      >
        <SectionTabs
          sections={tabSections}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          questionnaires={initialData}
          handleFilterChange={handleFilterChange}
        />
      </Paper>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <SectionDetails
          assessmentName={assessmentName}
          sectionQuestionLabel={sectionQuestionLabel}
          sectionQuestionList={sectionQuestionList}
          sectionQuestionDetailList={sectionQuestionDetailList}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          orgCode={orgCode}
          accCode={accCode}
          accNumber={accNumber}
          isReadOnly={isReadOnly}
        />
      </Box>

      {/* Add Modal */}
      <CreateMilestoneModal
        open={openMilestoneModal}
        onClose={() => setOpenMilestoneModal(false)}
        workloadId={workloadId}
        accCode={accCode}
        orgCode={orgCode}
      />
    </Box>
  );
};

export default WorkloadAssessment;
