import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
  Popover,
  Chip,
  Tooltip,
} from "@mui/material";
import RiskReasonDisplay from "./RiskReasonDisplay";
import ApplicabilityOption from "./ApplicabilityOption";
import NotesBox from "./NotesBox";
import { updateWafrAnswer, getWafrStakeholders, putWafrStakeholders, clearAllWafrQuestionnaireMessages } from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import IssuesModal from "./IssuesModal";
import { isAuditor } from "../../../helpers/jwt-token-access/isAuditor";
import { getFormattedQuestion, getImprovementPlanUrl } from "./questionService";
import LaunchIcon from "@mui/icons-material/Launch";
import AttachmentsBox from "./AttachmentsBox";
import QuestionAssignee from "./QuestionAssignee";

const SectionQuestionsDetailsPanel = ({
  assessmentName,
  sectionQuestionDetailList,
  selectedQuestion,
  sectionQuestionLabel,
  orgCode,
  accCode,
  accNumber,
  isReadOnly = false,
}) => {
  const dispatch = useDispatch();
  const {
    updateWafrAnswerMessage,
    isUpdatingWafrAnswer,
    updateWafrAnswerError,
    wafrStakeholders,
    isGettingWafrStakeholders,
    getWafrStakeholdersError,
    putWafrStakeholdersMessage,
    putWafrStakeholdersError,
  } = useSelector((state) => state.wafrQuestionnaire);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDescription, setCurrentDescription] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [currentChoice, setCurrentChoice] = useState({
    choiceId: null,
    choiceTitle: "",
  });

  const handleOpenModal = (choiceId, choiceTitle) => {
    setCurrentChoice({ choiceId, choiceTitle });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setCurrentChoice({ choiceId: null, choiceTitle: "" });
    setOpenModal(false);
  };

  const selectedQuestionData = sectionQuestionDetailList[selectedQuestion];

  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (selectedQuestionData?.choices?.length > 0) {
      return selectedQuestionData.choices
        .filter((choice) => choice.status === "SELECTED")
        .map((choice) => choice.choiceId);
    }
    return [];
  });
  console.log("selectedOptions : ", selectedOptions);
  const [notApplicable, setNotApplicable] = useState(
    !selectedQuestionData.applicable
  );

  const [notes, setNotes] = useState(selectedQuestionData?.comments?.[0] || "");

  const [reason, setReason] = useState(
    selectedQuestionData.reason ? selectedQuestionData.reason : "NONE"
  );

  // State for Applicability and Notes per choice
  const [choiceStates, setChoiceStates] = useState(() =>
    (selectedQuestionData?.choices || []).reduce((acc, choice) => {
      acc[choice.choiceId] = {
        notApplicable: choice.status === "NOT_APPLICABLE" ? true : false,
        reason: choice.reason,
        notes: choice.comments?.[0] || "",
        comments: choice.comments || [],
      };
      return acc;
    }, {})
  );
  // console.log("ChoiceStates: " , choiceStates);

  const handleInfoClick = (event, description) => {
    setAnchorEl(event.currentTarget);
    setCurrentDescription(description);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
    setCurrentDescription("");
  };

  const handleOptionChange = (choiceId) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(choiceId)
        ? prevSelected.filter((id) => id !== choiceId)
        : [...prevSelected, choiceId]
    );
  };

  const removeSelectedOption = (choiceId) => {
    setSelectedOptions((prevSelectedChoices) =>
      prevSelectedChoices.filter(
        (prevSelectedChoice) => prevSelectedChoice !== choiceId
      )
    );
  };

  const getOriginalStatus = () => {
    const originalSelectedChoices = [];
    const originalUpdateChoiceDtos = {};
    selectedQuestionData.choices.forEach((choice) => {
      if (choice.status === "SELECTED") {
        // Add choiceId to selectedChoices
        originalSelectedChoices.push(choice.choiceId);
      } else {
        // Add choiceId as key to updateChoiceDtos
        originalUpdateChoiceDtos[choice.choiceId] = {
          status: choice.status,
          reason: choice.reason,
          notes: "",
        };
      }
    });
    return [originalSelectedChoices, originalUpdateChoiceDtos];
  };

  const getUpdatedSelectedOptions = () => {
    const updatedSelectedOptions = [];

    const choiceKeys = Object.keys(choiceStates);
    choiceKeys.forEach((choiceKey) => {
      const isChoiceNotApplicable = choiceStates[choiceKey]?.notApplicable;
      if (!isChoiceNotApplicable) {
        if (
          selectedOptions.some((selectedOption) => selectedOption === choiceKey)
        ) {
          updatedSelectedOptions.push(choiceKey);
        }
      }
    });
    return updatedSelectedOptions;
  };

  const getUpdatedFilteredChoiceStates = (updatedSelectedOptions) => {
    const filteredChoiceStates = Object.keys(choiceStates)
      .filter((key) => {
        const existingChoice = selectedQuestionData?.choices.find(
          (choice) => choice.choiceId === key
        );
        const wasNotApplicable =
          existingChoice?.status === "NOT_APPLICABLE" &&
          !choiceStates[key].notApplicable;

        // Keep choices:
        // 1. Previously NOT_APPLICABLE but now selected (wasNotApplicable).
        // 2. Choices that need to update their status from previously selected to something else.
        return (
          wasNotApplicable ||
          !(
            updatedSelectedOptions.includes(key) &&
            existingChoice?.status === "SELECTED"
          )
        );
      })
      .reduce((acc, key) => {
        acc[key] = choiceStates[key]; // Add the key-value pair to the new object
        return acc;
      }, {});

    const updatedFilteredChoiceStates = Object.fromEntries(
      Object.entries(filteredChoiceStates)
        .map(([key, value]) => {
          const existingChoice = selectedQuestionData?.choices.find(
            (choice) => choice.choiceId === key
          );
          const wasNotApplicable =
            existingChoice?.status === "NOT_APPLICABLE" &&
            updatedSelectedOptions.includes(key);

          return [
            key,
            {
              status: value.notApplicable
                ? "NOT_APPLICABLE"
                : wasNotApplicable
                ? "UNSELECTED" // Mark it as UNSELECTED for the update.
                : updatedSelectedOptions.includes(key)
                ? "SELECTED"
                : "UNSELECTED",
              reason:
                value.reason && value.reason.trim() !== ""
                  ? value.reason
                  : "NONE",
              notes: value.notes,
            },
          ];
        })
        // Filter out choices with status: SELECTED
        .filter(([_, value]) => value.status !== "SELECTED")
    );
    return updatedFilteredChoiceStates;
  };

  const handleSave = () => {
    const updatedSelectedOptions = getUpdatedSelectedOptions();
    const updatedFilteredChoiceStates = getUpdatedFilteredChoiceStates(
      updatedSelectedOptions
    );

    console.log("updatedFilteredChoiceStates:", updatedFilteredChoiceStates);

    const [originalSelectedChoices, originalUpdateChoiceDtos] =
      getOriginalStatus();

    const finalChoiceUpdateDtos = !notApplicable
      ? updatedFilteredChoiceStates
      : originalUpdateChoiceDtos;

    const updateWafrAnswerPayload = {
      orgCode: orgCode,
      accountCode: accCode,
      accountNumber: accNumber,
      workloadId: selectedQuestionData?.workloadId,
      lensArn: selectedQuestionData?.lensArn,
      answers: [
        {
          questionId: selectedQuestionData.questionId,
          choiceUpdateDtos: Object.keys(finalChoiceUpdateDtos).reduce(
            (acc, choiceId) => {
              acc[choiceId] = {
                ...finalChoiceUpdateDtos[choiceId],
                notes: choiceStates[choiceId]?.notes || "", // Include notes from choiceStates
              };
              return acc;
            },
            {}
          ),
          applicable: notApplicable ? false : true,
          notes: notes ? notes : "",
          reason: reason && reason.trim() !== "" ? reason : "NONE",
          selectedChoices: notApplicable
            ? originalSelectedChoices
            : [...updatedSelectedOptions],
        },
      ],
    };

    console.log("updateWafrAnswerPayload = ", updateWafrAnswerPayload);
    dispatch(updateWafrAnswer(updateWafrAnswerPayload));
  };

  const isAnswerUpdated = () => {
    const originalNotApplicable = !selectedQuestionData.applicable;
    // console.log("notApplicable = ", notApplicable);
    // console.log("selectedQuestionDetails.applicable = ", selectedQuestionDetails.applicable);

    if (notApplicable !== originalNotApplicable) {
      console.log("Returning true");
      return true;
    }

    const updatedSelectedOptions = getUpdatedSelectedOptions();
    const updatedFilteredChoiceStates = getUpdatedFilteredChoiceStates(
      updatedSelectedOptions
    );
    // console.log("updatedSelectedOptions : ", updatedSelectedOptions);
    // console.log("updatedFilteredChoiceStates : ", updatedFilteredChoiceStates);

    const [originalSelectedChoices, originalUpdateChoiceDtos] =
      getOriginalStatus();
    // console.log("originalSelectedChoices : ", originalSelectedChoices);
    // console.log("originalUpdateChoiceDtos : ", originalUpdateChoiceDtos);

    const isSelectedOptionsUpdated = () => {
      if (updatedSelectedOptions.length !== originalSelectedChoices.length) {
        return true;
      }

      // Check if any value is different
      const originalSet = new Set(originalSelectedChoices);
      return updatedSelectedOptions.some((option) => !originalSet.has(option));
    };

    // Compare objects for updatedFilteredChoiceStates and originalUpdateChoiceDtos
    const isFilteredChoiceStatesUpdated = () => {
      const choiceUpdateDtos = {};
      selectedQuestionData.choices.forEach((choice) => {
        const choiceState = choiceStates[choice.choiceId];
        if (choiceState) {
          choiceUpdateDtos[choice.choiceId] = {
            status: choiceState.notApplicable
              ? "NOT_APPLICABLE"
              : selectedOptions.includes(choice.choiceId)
              ? "SELECTED"
              : "UNSELECTED",
            reason: choiceState.reason || "NONE",
            notes: choiceState.notes || "", // Make sure notes are included in the payload
          };
        }
      });

      // Log the payload being prepared
      console.log("Choice update DTOs:", choiceUpdateDtos);

      // Compare with original state
      return (
        JSON.stringify(choiceUpdateDtos) !==
        JSON.stringify(originalUpdateChoiceDtos)
      );
    };

    // Return true if any of the checks indicate changes
    // console.log("isSelectedOptionsUpdated() = ", isSelectedOptionsUpdated());
    // console.log("isFilteredChoiceStatesUpdated() = ", isFilteredChoiceStatesUpdated());
    return (
      isSelectedOptionsUpdated() ||
      isFilteredChoiceStatesUpdated() ||
      notApplicable !== originalNotApplicable
    );
  };

  const updateChoiceState = (choiceId, field, value) => {
    console.log("Updating choice state:", { choiceId, field, value });

    setChoiceStates((prev) => {
      const updated = {
        ...prev,
        [choiceId]: {
          ...prev[choiceId],
          [field]: value,
          notes: field === "notes" ? value : prev[choiceId]?.notes, // Store the actual note value
          comments:
            field === "notes" ? [value] : prev[choiceId]?.comments || [], // Keep comments array in sync
        },
      };
      console.log("Updated choice state:", updated[choiceId]);
      return updated;
    });
  };

  const isPopoverOpen = Boolean(anchorEl);

  // Find the "None of these" choice
  const noneChoice = selectedQuestionData?.choices?.find((choice) =>
    choice.title.includes("None of these")
  );

  // Check if "None of these" is selected
  const isNoneSelected = selectedOptions.includes(noneChoice?.choiceId);

  // Add effect to fetch stakeholders when question changes
  useEffect(() => {
    if (selectedQuestionData?.workloadId && selectedQuestionData?.questionId) {
      dispatch(getWafrStakeholders({
        workloadId: selectedQuestionData.workloadId,
        questionId: selectedQuestionData.questionId,
        accountCode: accCode,
      }));
    }
  }, [selectedQuestionData?.workloadId, selectedQuestionData?.questionId, dispatch]);

  // Add effect to clear messages when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearAllWafrQuestionnaireMessages());
    };
  }, [dispatch]);

  const handleAssigneeSave = async (questionId, assignee) => {
    if (selectedQuestionData?.workloadId) {
      dispatch(putWafrStakeholders({
        workloadId: selectedQuestionData.workloadId,
        assessmentName: assessmentName,
        questionId: questionId,
        stakeHolder: assignee,
        accountCode: accCode,
        pillarId: selectedQuestionData.pillarId,
        questionTitle: selectedQuestionData.questionTitle
      }));
    }
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
      }}
    >
      {/* Question Title with Assignee */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 1
      }}>
        <Typography sx={{ fontSize: "0.8rem" }} fontWeight="bold">
          {getFormattedQuestion(selectedQuestionData.questionTitle)}
        </Typography>
        <QuestionAssignee
          questionId={selectedQuestionData.questionId}
          onSave={handleAssigneeSave}
          disabled={isReadOnly || !isAuditor()}
          stakeholders={wafrStakeholders}
          isLoading={isGettingWafrStakeholders}
          error={getWafrStakeholdersError}
          saveMessage={putWafrStakeholdersMessage}
          saveError={putWafrStakeholdersError}
        />
      </Box>

      {/* Question Details */}
      <Typography sx={{ fontSize: "0.6rem", marginTop: "0.5rem" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Risk and Reason */}
          <RiskReasonDisplay
            label="Risk"
            value={selectedQuestionData.risk}
            type="risk"
          />
          {selectedQuestionData.risk && selectedQuestionData.reason ? (
            <span style={{ margin: "0 0.5rem" }}>|</span>
          ) : null}
          <RiskReasonDisplay
            label="Reason"
            value={selectedQuestionData.reason}
            type="reason"
          />
        </Box>
        {/* Popover for showing the description */}
        <Popover
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleInfoClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "0.5rem", maxWidth: "300px" }}>
            <Typography sx={{ fontSize: "0.7rem" }}>
              {currentDescription}
            </Typography>
          </Box>
        </Popover>
      </Typography>

      {/* Save Button */}
      {!isReadOnly && (
        <Tooltip
          title={
            !isAuditor() ? "Only Auditors have access to this feature" : ""
          }
          arrow
        >
          <span>
            <Button
              variant="contained"
              size="small"
              color="primary"
              // sx={{backgroundColor:"#F98125"}}
              disabled={
                !isAuditor() ||
                !isAnswerUpdated() ||
                isUpdatingWafrAnswer ||
                (notApplicable
                  ? !reason
                  : Object.values(choiceStates)?.some(
                      (choice) => choice.notApplicable && !choice.reason
                    ))
              }
              onClick={handleSave}
            >
              {isUpdatingWafrAnswer ? "Saving" : "Save"}
            </Button>
          </span>
        </Tooltip>
      )}

      {/* Question Level Applicability Option */}
      <ApplicabilityOption
        questionId={selectedQuestionData.questionId}
        notApplicable={notApplicable}
        setNotApplicable={setNotApplicable}
        reason={reason}
        setReason={setReason}
        disabled={isReadOnly || !isAuditor()}
      >
        <NotesBox
          notes={notes}
          setNotes={setNotes}
          disabled={isReadOnly || !isAuditor()}
        />
      </ApplicabilityOption>

      <Box
        sx={{
          padding: "0.5rem",
          borderRadius: "0.25rem",
          border: "1px solid #ccc",
          marginRight: "0.5rem",
        }}
      >
        <Box
          sx={{
            marginTop: "0.25rem",
            marginRight: "0.5rem",
            padding: "0.5rem",
            borderRadius: "0.25rem",
            backgroundColor: "#F0F0F0",
          }}
        >
          Best Practices
        </Box>

        <Box sx={{ marginTop: "0.25rem", marginRight: "0.5rem" }}>
          {selectedQuestionData?.choices?.map((choice, index) => (
            <Box key={choice.choiceId || index}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  opacity:
                    isNoneSelected && choice.choiceId !== noneChoice?.choiceId
                      ? 0.5
                      : 1,
                  transition: "opacity 0.2s",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={selectedOptions.includes(choice.choiceId)}
                      onChange={() => handleOptionChange(choice.choiceId)}
                      disabled={
                        choice.automated ||
                        notApplicable ||
                        choiceStates[choice.choiceId]?.notApplicable ||
                        isReadOnly ||
                        !isAuditor() ||
                        (isNoneSelected &&
                          choice.choiceId !== noneChoice?.choiceId)
                      }
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {/* {sectionQuestionLabel} {selectedQuestion + 1}.{index + 1}{" "} */}
                      {choice.title}{" "}
                      <Button
                        sx={{
                          fontSize: "0.7rem",
                          textTransform: "none",
                          marginLeft: "0.5rem",
                        }}
                        onClick={(event) =>
                          handleInfoClick(event, choice.description)
                        }
                      >
                        Info
                      </Button>
                    </Typography>
                  }
                />
                <Box display="flex" gap={1} alignItems="center">
                  {choice.automated &&
                    !choiceStates[choice.choiceId]?.notApplicable && (
                      <>
                        {selectedOptions.includes(choice.choiceId) ? (
                          <Chip
                            label="✓ Verified"
                            color="success"
                            sx={{ fontSize: "0.7rem" }}
                            onClick={() =>
                              handleOpenModal(choice.choiceId, choice.title)
                            }
                          />
                        ) : (
                          <Chip
                            label="⚠ Issues"
                            color="warning"
                            sx={{ fontSize: "0.7rem", cursor: "pointer" }}
                            onClick={() =>
                              handleOpenModal(choice.choiceId, choice.title)
                            }
                          />
                        )}
                      </>
                    )}

                  {choice.automated ? (
                    <Chip
                      label={`Nature: Automated`}
                      sx={{ fontSize: "0.7rem" }}
                    />
                  ) : (
                    <Chip
                      label={`Nature: Manual`}
                      sx={{ fontSize: "0.7rem" }}
                    />
                  )}

                  {!selectedOptions.includes(choice.choiceId) &&
                    getImprovementPlanUrl(choice.title) && (
                      <Chip
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5,
                            }}
                          >
                            Improvement Plan
                            <LaunchIcon sx={{ fontSize: 16 }} />
                          </Box>
                        }
                        size="small"
                        color="info"
                        component="a"
                        href={getImprovementPlanUrl(choice.title)}
                        target="_blank"
                        rel="noopener noreferrer"
                        clickable
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                      />
                    )}
                </Box>
              </Box>
              <Box sx={{ paddingLeft: "2rem" }}>
                {/* Choice Level Applicability Option */}
                <ApplicabilityOption
                  questionId={choice.choiceId}
                  notApplicable={choiceStates[choice.choiceId]?.notApplicable}
                  setNotApplicable={(value) => {
                    updateChoiceState(choice.choiceId, "notApplicable", value);
                    if (value) {
                      removeSelectedOption(choice.choiceId);
                    }
                  }}
                  reason={choiceStates[choice.choiceId]?.reason}
                  setReason={(value) =>
                    updateChoiceState(choice.choiceId, "reason", value)
                  }
                  parentNotApplicable={notApplicable}
                  disabled={isReadOnly || !isAuditor()}
                >
                  {/* Notes Box */}
                  <NotesBox
                    notes={choiceStates[choice.choiceId]?.notes}
                    setNotes={(value) => {
                      console.log("Setting choice notes:", {
                        choiceId: choice.choiceId,
                        value,
                      });
                      updateChoiceState(choice.choiceId, "notes", value);
                    }}
                    noRows={2}
                    disabled={isReadOnly || !isAuditor()}
                  />
                </ApplicabilityOption>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Notes Box */}
      <Box sx={{ marginTop: "0.5rem" }}>
        <NotesBox notes={notes} setNotes={setNotes} noRows={4} />
      </Box>

      {/* Attachments Box */}
      {!isReadOnly && (
        <Box sx={{ marginTop: "0.5rem" }}>
          {selectedQuestionData?.workloadId &&
            selectedQuestionData?.questionId && (
              <AttachmentsBox
                disabled={isReadOnly || !isAuditor()}
                accountCode={accCode}
                workloadId={selectedQuestionData.workloadId}
                questionId={selectedQuestionData.questionId}
              />
            )}
        </Box>
      )}

      {/* Divider */}
      <Divider sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />

      <IssuesModal
        open={openModal}
        onClose={handleCloseModal}
        choiceId={currentChoice.choiceId}
        choiceTitle={currentChoice.choiceTitle}
        accNumber={accNumber}
      />
    </Box>
  );
};

export default SectionQuestionsDetailsPanel;
